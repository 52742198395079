import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { Handlebars } from './../../utils/HandlebarsHelpers';
import { render } from './../../utils/dml/dml';

import {
  getDefaultMantraBlocks,
  getDefaultOxygenBlocks,
  getDefaultLifestyleBlocks,
  getDefaultNeopolitanBlocks,
} from './../../utils/DefaultBlockLibrary';

const ShadowWrapper = styled.div`
  position: relative;
  display: block;
  overflow: hidden;
  transform-origin: 0 0;
  pointer-events: none;
  width: ${(props) =>
    props.draggingOver ? props.width + 'px' : props.containerWidth + 'px'};
  height: ${(props) => props.containerWidth / 2 + 'px'};
  transform: ${(props) => 'scale(' + props.thumbSize / props.containerWidth + ')'};
  transition: ${(props) => (props.draggingOver ? '0.5s' : '0s')};
`;

const getDefaultDatasetForThumbnails = (block) => {
  let allBlocks;
  switch (block.type) {
    case 'Oxygen':
      allBlocks = getDefaultOxygenBlocks();
      break;
    case 'Neopolitan':
      allBlocks = getDefaultNeopolitanBlocks();
      break;
    case 'Mantra':
      allBlocks = getDefaultMantraBlocks();
      break;
    case 'Blockify':
      allBlocks = [block];
      break;
    case 'Lifestyle':
    default:
      allBlocks = getDefaultLifestyleBlocks();
      break;
  }

  const blockDefaultDataSet = allBlocks.map((item) => item).filter((blockItem) => {
    return blockItem.contentId === block.contentId;
  });

  return blockDefaultDataSet[0].default.dataset;
};

const ThumbnailRenderer = React.memo((props) => {
  const { block, theme, containerWidth } = props;

  const shadowHolder = useRef();
  const blockHTML = render(block.dml, theme);

  const dataset = getDefaultDatasetForThumbnails(block);

  useEffect(() => {
    if (shadowHolder) {
      const shadowContent = document.createRange().createContextualFragment(`
        <div style='
          width: 100%; 
          height: ${containerWidth / 2}px;
          display: flex; 
          align-items: center; 
          justify-content: center;
        '>
          <div style='
            width: ${containerWidth}px;
          '>
            ${Handlebars.compile(blockHTML)(dataset)}
          </div>
        </div>`);

      const shadow = shadowHolder.current.attachShadow({ mode: 'open' });
      shadow.appendChild(shadowContent);
    }
  }, []);

  return (
    <ShadowWrapper
      ref={shadowHolder}
      draggingOver={props.draggingOver}
      containerWidth={props.containerWidth}
      thumbSize={props.thumbSize}
      width={props.width}
    />
  );
});

export default ThumbnailRenderer;
