let next = 1
let untitledCount = 1

function getNext(): number {
  return next++
}

export function newId(prefix: string): string {
  return `${prefix}_${getNext()}`
}

export function newName(): string {
  return `Block #${untitledCount++}`
}

export function resetCount(){
  next = 1;
  untitledCount = 1;
}