const CalloutAndTitle = `
<dys-block>
  <dys-row>
    <dys-column>
      <dys-text align='center' font-family='Open Sans, Arial, sans-serif' font-size='18px' line-height='20px' font-weight='bold' color='#feae39' text-transform='uppercase' editable='orange-precursor-title'>
        {{{ mantra-callout-title-text-group1 }}}
      </dys-text>

      <dys-text padding-top='20px' align='center' font-family='Courier New, Arial, sans-serif' font-size='50px' line-height='50px' color='#feae39' text-transform='uppercase' editable='orange-callout-text'>
        {{{ mantra-callout-subtitle-text-group1 }}}
      </dys-text>
    </dys-column>
  </dys-row>
</dys-block>
`;

export default CalloutAndTitle;
