import styled, { keyframes } from 'styled-components';

export const CenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 564px;
  min-height: 80vh;
  max-height: calc(99vh - 150px);
`;

export const Progress = styled.div`
  color: rgb(120, 120, 120);
  display: grid;
  height: 16px;
  grid-auto-flow: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  gap: calc(8px) calc(8px);
`;

const UpAndDown = keyframes`
  0%, 30% {
    transform: translateY(0px);
  },
  100% {
    transform: translateY(-15px);
  }
`;

export const ProgressCircle = styled.div`
  position: relative;
  display: block;
  border-radius: 50%;
  background-color: #787878;
  height: 16px;
  width: 16px;
  animation: ${UpAndDown} 0.8s ease-in-out infinite alternate;
  transform: translateY(-15px);
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:last-child {
    animation-delay: 0.4s;
  }
`;
