import NeopolitanConfirmation from './Confirmation';
import NeopolitanFooter from './Footer';
import NeopolitanHeader from './Header';
import NeopolitanHero from './Hero';
import NeopolitanHeroSecondary from './HeroSecondary';
import NeopolitanHeroWithSubtitle from './HeroWithSubtitle';
import NeopolitanHeroWithTitle from './HeroWithTitle';
import NeopolitanReceipt from './Receipt';
import NeopolitanSurvey from './Survey';
import NeopolitanUserInvite from './UserInvite';

import { getHandlebarsFriendlyTemplates } from './../../utils/MakeDmlHandlebars';

const AllNeopolitanBlocks = [
  {
    contentId: 'header',
    name: 'Header',
    dml: getHandlebarsFriendlyTemplates(NeopolitanHeader, 'neopolitan'),
    type: 'Neopolitan',
    default: {
      dataset: getHandlebarsFriendlyTemplates(NeopolitanHeader, 'neopolitan', true),
    },
  },
  {
    contentId: 'hero',
    name: 'Hero',
    dml: getHandlebarsFriendlyTemplates(NeopolitanHero, 'neopolitan'),
    type: 'Neopolitan',
    default: {
      dataset: getHandlebarsFriendlyTemplates(NeopolitanHero, 'neopolitan', true),
    },
  },
  {
    contentId: 'hero-secondary',
    name: 'Hero Secondary',
    dml: getHandlebarsFriendlyTemplates(NeopolitanHeroSecondary, 'neopolitan'),
    type: 'Neopolitan',
    default: {
      dataset: getHandlebarsFriendlyTemplates(
        NeopolitanHeroSecondary,
        'neopolitan',
        true
      ),
    },
  },
  {
    contentId: 'hero-with-subtitle',
    name: 'Hero with Subtitle',
    dml: getHandlebarsFriendlyTemplates(NeopolitanHeroWithSubtitle, 'neopolitan'),
    type: 'Neopolitan',
    default: {
      dataset: getHandlebarsFriendlyTemplates(
        NeopolitanHeroWithSubtitle,
        'neopolitan',
        true
      ),
    },
  },
  {
    contentId: 'hero-with-title',
    name: 'Hero with Title',
    dml: getHandlebarsFriendlyTemplates(NeopolitanHeroWithTitle, 'neopolitan'),
    type: 'Neopolitan',
    default: {
      dataset: getHandlebarsFriendlyTemplates(
        NeopolitanHeroWithTitle,
        'neopolitan',
        true
      ),
    },
  },
  {
    contentId: 'receipt',
    name: 'Receipt',
    dml: getHandlebarsFriendlyTemplates(NeopolitanReceipt, 'neopolitan'),
    type: 'Neopolitan',
    default: {
      dataset: getHandlebarsFriendlyTemplates(NeopolitanReceipt, 'neopolitan', true),
    },
  },
  {
    contentId: 'survey',
    name: 'Survey',
    dml: getHandlebarsFriendlyTemplates(NeopolitanSurvey, 'neopolitan'),
    type: 'Neopolitan',
    default: {
      dataset: getHandlebarsFriendlyTemplates(NeopolitanSurvey, 'neopolitan', true),
    },
  },
  {
    contentId: 'invite',
    name: 'User Invite',
    dml: getHandlebarsFriendlyTemplates(NeopolitanUserInvite, 'neopolitan'),
    type: 'Neopolitan',
    default: {
      dataset: getHandlebarsFriendlyTemplates(
        NeopolitanUserInvite,
        'neopolitan',
        true
      ),
    },
  },
  {
    contentId: 'confirmation',
    name: 'Confirmation',
    dml: getHandlebarsFriendlyTemplates(NeopolitanConfirmation, 'neopolitan'),
    type: 'Neopolitan',
    default: {
      dataset: getHandlebarsFriendlyTemplates(
        NeopolitanConfirmation,
        'neopolitan',
        true
      ),
    },
  },
  {
    contentId: 'footer',
    name: 'Footer',
    dml: getHandlebarsFriendlyTemplates(NeopolitanFooter, 'neopolitan'),
    type: 'Neopolitan',
    default: {
      dataset: getHandlebarsFriendlyTemplates(NeopolitanFooter, 'neopolitan', true),
    },
  },
];

export default AllNeopolitanBlocks;
