const Info = `
<dys-block>
  <dys-row full-width='full-width' background-color='#f7f7f7'>
    <dys-column padding='15px' width='45%' background-color='#ffffff' background-color='#ffffff' border='1px solid #e5e5e5'>
      <dys-text padding='0px ' color='#4d4d4d' line-height='25px' font-weight='700' font-size='18px' align='left' font-family='Oxygen, Helvetica neue, sans-serif' editable='title1'>
        Shipping Text
      </dys-text> 
      <dys-text padding='0px' color='#777777' line-height='23px' font-size='14px' align='left' font-family='Oxygen, Helvetica neue, sans-serif' editable='address'>
        J Oxygen <br /> 123 Street <br />Victoria, BC <br />Canada
      </dys-text>    
    </dys-column>
    
    <!-- empty column to create gap -->
    <dys-column padding='0' background-color='#FFFFFF' width='5%'></dys-column>
    
   <dys-column padding='15px' width='45%' background-color='#ffffff' background-color='#ffffff' border='1px solid #e5e5e5'>
      <dys-text padding='0px ' color='#4d4d4d' line-height='25px' font-weight='700' font-size='18px' align='left' font-family='Oxygen, Helvetica neue, sans-serif' editable='title2'>
        Date Ordered

      </dys-text> 
      <dys-text padding='0px' color='#777777' line-height='22px' font-size='14px' align='left' font-family='Oxygen, Helvetica neue, sans-serif' editable='date'>
        January 12, 2019 <br /> <br />
      </dys-text>  
      <dys-text padding='0px ' color='#4d4d4d' line-height='25px' font-weight='700' font-size='18px' align='left' font-family='Oxygen, Helvetica neue, sans-serif' editable='title3'>
        Order
      </dys-text> 
      <dys-text padding='0px' color='#777777' line-height='22px' font-size='14px' align='left' font-family='Oxygen, Helvetica neue, sans-serif' editable='thing'>
        #1233445 <br />
      </dys-text>       
    </dys-column>
  </dys-row>
</dys-block>
`;

export default Info;
