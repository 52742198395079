export const getHandlebarsFriendlyTemplates = (dml, theme, variables) => {
  // Create HTML block to modify things
  let el = document.createElement('html');

  // Modifications
  // Handle table elements
  const updatedBlock = dml.replace(/\bdys-table\b/g, 'table');

  // Assign html to block
  el.innerHTML = updatedBlock;

  // Find all buttons
  const buttons = el.getElementsByTagName('dys-button');
  const texts = el.getElementsByTagName('dys-text');
  const images = el.getElementsByTagName('dys-img');
  const spans = el.getElementsByTagName('span');
  const paragraphs = el.getElementsByTagName('p');

  // Convert buttons to be able to map over each element
  const buttonArray = [...buttons];
  const textArray = [...texts];
  const imageArray = [...images];
  const spanArray = [...spans];
  const paragraphArray = [...paragraphs];

  const outputVariableDict = {};

  const replaceButtonElements = (array, theme) => {
    array.map((item) => {
      // Search for editable value
      const editableValue = item.getAttribute('editable');
      if (editableValue) {
        if (variables) {
          const textSource = item.innerText.trim().replace(/\s+/g, ' ');
          const hrefSource = item.getAttribute('href');

          outputVariableDict[
            `${theme}-${editableValue}-buttontext-group2`
          ] = `${textSource}`;
          outputVariableDict[
            `${theme}-${editableValue}-buttonurl-group2`
          ] = `${hrefSource || '#'}`;
        }

        // Replace content if editable exists
        item.innerText = `{{{ ${theme}-${editableValue}-buttontext-group2 }}}`;
        item.setAttribute(
          'href',
          `{{{ ${theme}-${editableValue}-buttonurl-group2 }}}`
        );
      }
      return item;
    });
  };

  const replaceTextElements = (array, theme) => {
    array.map((item) => {
      // Search for editable value
      const editableValue = item.getAttribute('editable');
      if (editableValue) {
        if (variables) {
          const textSource = item.innerText.trim().replace(/\s+/g, ' ');
          outputVariableDict[
            `${theme}-${editableValue}-text-group1`
          ] = `${textSource}`;
        }

        // Replace content if editable exists
        item.innerText = `{{{ ${theme}-${editableValue}-text-group1 }}}`;
      }
      return item;
    });
  };

  const replaceImageElements = (array, theme) => {
    array.map((item) => {
      // Search for editable value
      const editableValue = item.getAttribute('editable');
      if (editableValue) {
        // Replace content if editable exists
        if (variables) {
          const imageSource = item.getAttribute('src');
          const altSource = item.getAttribute('alt');

          outputVariableDict[
            `${theme}-${editableValue}-imgurl-group3`
          ] = `${imageSource}`;
          outputVariableDict[
            `${theme}-${editableValue}-alt-group3`
          ] = `${altSource || 'Descriptive Alt Text'}`;
        }
        item.setAttribute('src', `{{{ ${theme}-${editableValue}-imgurl-group3 }}}`);
        item.setAttribute('alt', `{{{ ${theme}-${editableValue}-alt-group3 }}}`);
      }
      return item;
    });
  };

  replaceButtonElements(buttonArray, theme);
  replaceTextElements(textArray, theme);
  replaceTextElements(spanArray, theme);
  replaceTextElements(paragraphArray, theme);
  replaceImageElements(imageArray, theme);

  // Get dys-block element. There should only ever be one.
  const block = el.getElementsByTagName('dys-block')[0].outerHTML;

  // Return modified block
  if (variables) {
    return outputVariableDict;
  }

  // Modifications
  const finalBlock = block
    .replace(/<(\/?)table/g, '<$1dys-table') // reconvert tables
    .replace(/(<dys-img[^>]+)/g, '$1 /') // add trailing slash
    .replace(/<\/dys-img>/g, ''); // remove closing image element

  return finalBlock;
};
