import styled from 'styled-components';

export const ExportContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  resize: none;
  height: 80%;
  .goBack {
    align-self: flex-end;
  }
`;

export const SubmitEmailContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: normal;
  align-content: normal;
  justify-content: normal;
  align-self: auto;
  flex-wrap: nowrap;
  flex: 0 0 auto;
  padding: 0px 24px 0px 0px;
  margin: 0px;
`;

export const ButtonText = styled.a`
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  min-width: 96px;
  cursor: pointer;
  font-weight: 400;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.4px;
  color: rgb(255, 255, 255);
  opacity: 1;
  background-color: #1F76D3;
  height: 28px;
  font-size: 16px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  text-decoration: none;
  transition: background 0.2s ease 0s;
  border-color: #1F76D3;
  padding: 4px 24px;
  :hover {
    background-color: #1B63B1;
    color: rgb(255, 255, 255);
    border-color: #1B63B1;
    text-decoration: none;
  }
`;

export const ExportMessage = styled.p`
  font-weight: 400;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.4px;
`;

export const ExportErrorMessage = styled(ExportMessage)`
  color: red;
  padding-bottom: 10px;
  text-align: center;
`;

export const EscapeIconContainer = styled.div`
  color: rgb(120, 120, 120);
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
`;

export const CopyTextArea = styled.textarea`
  margin-bottom: 16px;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  -webkit-user-modify: read-write-plaintext-only;
  height: 70%;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: rgb(120, 120, 120);
  z-index: 5;
  overflow: auto;
  padding: 8px;
  resize: none;
  width: 90%;
  outline: none;
`;

export const EmailContainer = styled.div`
  width: 560px;
  height: 100%;
  overflow: auto;
  padding: 48px 32px 0px 32px;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  &:focus {
    outline: -webkit-focus-ring-color auto 5px;
  }
`;

export const ExportMessageContainer = styled.div`
  font-family: Roboto, sans-serif;
  width: 560px;
  padding: 0 42px;
  box-sizing: border-box;
`;

export const ExportTitle = styled.h1`
  text-align: center;
  padding-bottom: 15px;
  font-weight: normal;
`;

export const ExportText = styled.p`
  font-family: Roboto, sans-serif;
  text-align: center;
`;
