import LifestyleWelcome from './../../../assets/images/Lifestyle/LifestyleWelcome.png';
import LifestyleOrderShipped from './../../../assets/images/Lifestyle/LifestyleOrderShipped.png';
import LifestyleInvite from './../../../assets/images/Lifestyle/LifestyleInvite.png';

const LifestyleTemplates = [
  {
    alt: 'Welcome Template',
    name: 'Welcome',
    src: LifestyleWelcome,
  },
  {
    alt: 'Order Shipped Template',
    name: 'Order Shipped',
    src: LifestyleOrderShipped,
  },
  {
    alt: 'Invite Template',
    name: 'Invite',
    src: LifestyleInvite,
  },
];

export default LifestyleTemplates;
