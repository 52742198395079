const Coupon = `
<dys-block>
  <dys-row padding='20px' full-width='full-width' background-color='#f7f7f7'>
    <dys-column padding='50px 15px' width='90%' background-color='#fff' border='1px solid #ccc'>
      <dys-text color='#777777' line-height='21px' font-size='14px' align='center' font-family='Oxygen, Helvetica neue, sans-serif'>
        <span editable='before'>{{{ oxygen-coupon-one-text-group1 }}} </span><span style='font-weight:700; color: #ff6f6f; font-size: 18px;' editable='highlight text'>{{{ oxygen-coupon-styled-text-group1 }}}</span><span editable='after'>{{{ oxygen-coupon-two-text-group1 }}}</span>
      </dys-text>      
      <dys-text color='#777777' line-height='21px' font-size='14px' align='center' font-family='Oxygen, Helvetica neue, sans-serif'>
        <p style='padding: 10px 0; border: 1px solid #cccccc; color: #4d4d4d; font-weight: bold; font-size: 18px; text-align: center;' editable='box'>{{{ oxygen-coupon-code-text-group1 }}}</p>
      </dys-text>
      <dys-button href='{{ oxygen-coupon-button-buttonurl-group2 }}' line-height='21px' font-family='Oxygen, Helvetica neue, sans-serif' font-weight='400' border-radius='5px' font-size='14px' background-color='#ff6f6f' editable='button'>
        {{{ oxygen-coupon-button-buttontext-group2 }}}
      </dys-button>        
    </dys-column>
  </dys-row>
</dys-block>
`;

export default Coupon;
