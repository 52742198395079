import React from 'react';

const TrashCanIcon = (props) => {
  return (
    <svg
      aria-label="Remove"
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="16"
      viewBox="0 0 13 16"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.416.875v1.792H0V.875h3.093L4.011 0h4.416l.917.875h3.072zM.875 14.208V3.541H11.54v10.667c0 .484-.177.903-.533 1.259A1.721 1.721 0 0 1 9.749 16H2.667c-.484 0-.903-.178-1.259-.533a1.721 1.721 0 0 1-.533-1.259z"
      />
    </svg>
  );
};

export default TrashCanIcon;
