import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import AppDataStore from './../../store/StateStore';
import { Provider, Subscribe } from 'unstated';
import { capitalize } from 'lodash';

import MantraWelcome from './../../assets/images/Mantra/MantraWelcome.png';
import OxygenOrderShipped from './../../assets/images/Oxygen/OxygenOrderShipped.png';
import LifestyleWelcome from './../../assets/images/Lifestyle/LifestyleWelcome.png';
import NeopolitanOrderShipped from './../../assets/images/Neopolitan/NeopolitanOrderShipped.png';
import CustomTemplate from './../../assets/images/CustomTemplate/CustomTemplate.png';

import {
  TemplateName,
  TemplatesSectionContainer,
  Template,
  TemplateImage,
  TemplatesContainer,
} from './../../styles/TemplateSelection/TemplateSelectionStyles';
import { GetStartedButton } from './..//../styles/Buttons/ButtonStyles';

const getTemplateDisplay = (appData, theme) => {
  const themeName = capitalize(theme);
  let imageName;

  switch (theme) {
    case 'oxygen':
      imageName = OxygenOrderShipped;
      break;
    case 'mantra':
      imageName = MantraWelcome;
      break;
    case 'neopolitan':
      imageName = NeopolitanOrderShipped;
      break;
    case 'blockify':
      imageName = CustomTemplate;
      break;
    case 'lifestyle':
    default:
      imageName = LifestyleWelcome;
      break;
  }

  return (
    <TemplatesContainer>
      <TemplateName>
        {theme === 'blockify' ? 'Build Your Own' : themeName + ' Templates'}
      </TemplateName>
      <Link
        to={`/${theme}`}
        onClick={() => {
          if (theme !== 'blockify') {
            appData.onClickSelectedOset(themeName);
          }
        }}
        style={{ textDecoration: 'none' }}
      >
        <Template>
          {theme === 'blockify' ? (
            <div align="center" style={{ padding: '10px' }}>
              <TemplateImage
                src={CustomTemplate}
                alt="Welcome Template"
                style={{
                  objectFit: 'contain',
                }}
                width="125px"
                height="125px"
              />
              <p
                text-align="center"
                style={{ width: '260px', margin: '16px 0px', color: 'black' }}
              >
                Drop an Existing HTML email into our builder, and we'll create
                reusable blocks that you can drag-and-drop.
              </p>
              <GetStartedButton>
                Get Started
              </GetStartedButton>
            </div>
          ) : (
            <TemplateImage
              src={imageName}
              alt="Welcome Template"
              style={{
                objectFit: 'contain',
              }}
              width="280px"
              height="291px"
            />
          )}
        </Template>
      </Link>
    </TemplatesContainer>
  );
};

// This is the screen where you view available themes "/:oset"
const TemplateSelectionScreen = (props) => {
  // We expect a prop back from the Link react router as "location.state" which passes a state function.
  // If it's passed through, we have navigated away from the editor. This means we should reset the state.
  // This assigns the method as a function reference in order to check that it exists and well as executing it.
  const handleTemplateSelectionClick =
    props.location.state !== undefined &&
    props.location.state.handleTemplateSelectionClick !== undefined
      ? props.location.state.handleTemplateSelectionClick
      : '';

  useEffect(() => {
    if (handleTemplateSelectionClick) {
      handleTemplateSelectionClick();
    }
  }, []);

  const templateSelectionContainerStyle = { padding: '50px 20px 150px 20px' };
  return (
    <Provider>
      <Subscribe to={[AppDataStore]}>
        {(appData) => (
          <TemplatesSectionContainer style={templateSelectionContainerStyle}>
            {getTemplateDisplay(appData, 'blockify')}
            {getTemplateDisplay(appData, 'lifestyle')}
            {getTemplateDisplay(appData, 'oxygen')}
            {getTemplateDisplay(appData, 'mantra')}
            {getTemplateDisplay(appData, 'neopolitan')}
          </TemplatesSectionContainer>
        )}
      </Subscribe>
    </Provider>
  );
};

export default TemplateSelectionScreen;
