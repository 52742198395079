import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { Droppable } from 'react-beautiful-dnd';

import BlockLibraryEditable from './BlockLibrary/Editable/EditOverlay';
import BlockLibrary from './BlockLibrary/BlockLibraryDisplay';
import VisualEditor from './VisualEditor/VisualEditorDisplay';

const ColumnContainer = styled.div`
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 564px;
`;

const BlockLibraryContainer = styled.div`
  box-shadow: rgba(18, 18, 18, 0.1) 6px 0px 4px -2px;
  z-index: 10;
  height: 100vh;
`;

const VisualEditorContainer = styled.div``;

export default class Column extends PureComponent {
  render() {
    return (
      <ColumnContainer
        style={{
          maxWidth:
            this.props.area === 'block-library' || this.props.area === 'edit-mode'
              ? `564px`
              : `100%`,
        }}
      >
        <Droppable
          droppableId={this.props.column.id}
          isDropDisabled={this.props.area === 'block-library' ? true : false}
        >
          {(provided, snapshot) =>
            this.props.area === 'block-library' ? (
              <BlockLibraryContainer>
                <BlockLibraryEditable />
                <BlockLibrary
                  blocks={this.props.blocks}
                  area={this.props.area}
                  provided={provided}
                  snapshot={snapshot}
                />
              </BlockLibraryContainer>
            ) : (
              <VisualEditorContainer>
                <VisualEditor
                  blocks={this.props.blocks}
                  area={this.props.area}
                  provided={provided}
                  snapshot={snapshot}
                />
              </VisualEditorContainer>
            )
          }
        </Droppable>
      </ColumnContainer>
    );
  }
}
