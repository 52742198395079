import { Container } from 'unstated';
import debounce from 'lodash.debounce';

import {
  getDefaultMantraBlocks,
  getDefaultOxygenBlocks,
  getDefaultLifestyleBlocks,
  getDefaultNeopolitanBlocks,
} from '../utils/DefaultBlockLibrary';
import { getInitialData } from '../utils/InitialData';
import { reorder, copy, move } from '../utils/ReactDnd';
import { Handlebars } from './../utils/HandlebarsHelpers';

import { renderGroup } from './../utils/dml/dml';

// TODO: CNAME endpoint
const endpoint =
  process.env.REACT_APP_LAMBDA_URL ||
  'https://2icv2gqcvh.execute-api.us-west-2.amazonaws.com/api/';

// Used to manage the state of the app.
class AppDataStore extends Container {
  constructor(props) {
    super(props);
    this.state = {
      initialData: getInitialData(),
      editing: false,
      isMobile: false,
      isSelecting: true,
      isBlockify: false,
      blockifyCount: 0,
      exportAvailable: false,
      exportData: null,
      isExporting: false,
      exportErrorMessage: '',
      selectedTemplateName: 'Templates',
    };

    this.handleBlockChange = debounce(this.handleBlockChange, 300);
  }

  // ########################
  // ###### Blockify #######
  // ########################

  /// gives appData a reference to the Blockify function and set the appropriate states for it
  loadBlockify = (blockify) => {
    this.setState({
      blockify: blockify,
      isSelecting: false,
      isExporting: false,
      isBlockify: true,
      editing: false,
      selectedTemplateName: 'Custom',
      initialData: {
        columnOrder: ['block-library', 'visual-editor', 'edit-mode'],
        columns: {
          'block-library': {
            blockIds: [],
            id: 'block-library',
            title: 'Block Library',
          },
          'visual-editor': {
            blockIds: [],
            id: 'visual-editor',
            title: 'Visual Editor',
          },
        },
      },
    });
  };

  // updates count of blocks in Blockify. Used to activate and deactivate Header button
  updateBlockCount = (newCount) => {
    this.setState({ blockifyCount: newCount });
  };
  // gets the blocks and adds them to appData to be later manipulated by visual-editor
  generateBlocks = (new_data) => {
    this.setState({ initialData: new_data });
  };

  blockifyToVisualEditor = () => {
    this.setState({
      isSelecting: false,
      isExporting: false,
      isBlockify: false,
      editing: false,
    });
  };

  // ########################
  // ###### React DND #######
  // ########################

  onDragEnd = (result) => {
    const { source, destination } = result;

    // Dropped outside the list
    if (!destination) {
      return;
    }

    // Dropped in same location
    if (
      destination.draggableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    const start = this.state.initialData.columns[source.droppableId];
    const finish = this.state.initialData.columns[destination.droppableId];

    switch (source.droppableId) {
      case destination.droppableId:
        const newColumn = {
          ...start,
          blockIds: reorder(
            start.blockIds, // list
            source.index, // startIndex
            destination.index, // endIndex
          ),
        };

        const reorderState = {
          ...this.state.initialData,
          columns: {
            ...this.state.initialData.columns,
            [newColumn.id]: newColumn,
          },
        };

        this.setState({ initialData: reorderState });
        break;
      // If source equals block library, do this.
      // i.e. moving block from the block library
      case 'block-library':
        const copyResult = copy(start, finish, source, destination);

        const copyState = {
          ...this.state.initialData,
          columns: {
            ...this.state.initialData.columns,
            ...copyResult,
          },
        };

        this.setState({ initialData: copyState });
        break;

      default:
        const moveResult = move(start, finish, source, destination);

        const newState = {
          ...this.state.initialData,
          columns: {
            ...this.state.initialData.columns,
            ...moveResult,
          },
        };

        this.setState({ initialData: newState });
        break;
    }
  };

  // #############################
  // ###### Editing Blocks #######
  // #############################

  getOrCreateDataSet = (block) => {
    if (this.state[block.id]) {
      // if block exists, return dataset from that block
      return this.state[block.id].dataset;
    } else {
      // else use the default dataset for that particular block type
      // Explicitly make a copy of all blocks, instead of using this.state
      let allBlocks;
      switch (this.state.selectedTemplateName) {
        case 'oxygen':
          allBlocks = getDefaultOxygenBlocks();
          break;
        case 'neopolitan':
          allBlocks = getDefaultNeopolitanBlocks();
          break;
        case 'mantra':
          allBlocks = getDefaultMantraBlocks();
          break;
        case 'Custom':
          allBlocks = [JSON.parse(JSON.stringify(block))];
          break;
        case 'lifestyle':
        default:
          allBlocks = getDefaultLifestyleBlocks();
          break;
      }
      const blockDefaultDataSet = allBlocks
        .map((item) => item)
        .filter((blockItem) => {
          return (
            blockItem.contentId === block.contentId || 'styled' === block.contentId // need to find a better way to handle styled block
          );
        });

      const stateData = {
        blockId: block.id,
        name: block.name,
        [block.id]: blockDefaultDataSet[0].default,
      };

      // save dataset
      this.setState(stateData);
      return stateData[block.id].dataset;
    }
  };

  handleBlockChange = (value, key, blockId) => {
    // Make a copy of the state in order to only change the targeted values.
    // This makes it so when we save, it saves all the rest of the things.
    const stateCopy = Object.assign({}, this.state);

    if (this.state.blockId === blockId) {
      stateCopy[blockId].dataset[key] = value;
      this.setState(stateCopy);
    }
  };

  handleBlockClick = (e, block) => {
    if (e.target !== e.currentTarget) {
      // Set state to false to reset editable content
      this.setState({ editing: false });
      if (this.state[block.id]) {
        // if block if exists, set the state to the current block ID
        this.setState({ blockId: block.id, name: block.name, editing: true });
      } else {
        // Set block editing to true as a safe default
        this.setState({ editing: true });
      }
    }
    e.preventDefault();
    e.stopPropagation();
  };

  stoppedEditing = () => {
    this.setState({ editing: false });
  };

  deleteBlock = (blockId) => {
    const allBlocks = this.state.initialData.columns['visual-editor'].blockIds;
    const getIndexOfMatchingBlock = allBlocks.findIndex(
      (block) => blockId === block.id,
    );

    allBlocks.splice(getIndexOfMatchingBlock, 1);

    this.setState({
      editing: false,
      ...this.state.initialData.columns['visual-editor'],
      blockIds: allBlocks,
    });
  };

  // ####################################
  // ###### Desktop/Mobile Toggle #######
  // ####################################

  handleButtonToggle = () => {
    this.setState((prevState) => ({
      isMobile: !prevState.isMobile,
    }));
  };

  // ####################################
  // ######### Handle Exporting #########
  // ####################################

  startExporting = () => {
    this.setState({ isExporting: true });
  };

  stoppedExporting = () => {
    this.setState({
      isExporting: false,
      exportErrorMessage: '',
      editing: false,
    });
  };

  handleExportClick = (email, first_name, last_name, optin) => {
    // Don't add to Pardot unless the user has opted in
    fetch(endpoint, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        first_name: first_name,
        last_name: last_name,
        optin: optin,
      }),
    });

    // display html on the screen
    const allBlocks = this.state.initialData.columns['visual-editor'].blockIds;

    const blockData = allBlocks
      .filter((blockItem) => {
        return blockItem.contentId !== 'styled';
      })
      .map((item) => {
        const dataset = this.state[item.id].dataset;
        return Handlebars.compile(item.dml)(dataset);
      });

    if (blockData.length === 0) {
      this.setState({
        exportErrorMessage: "You haven't added any blocks!",
      });
      // prevent continuing
      return;
    }

    var exportHTML = renderGroup(blockData);

    this.setState({ exportAvailable: true, exportData: exportHTML });
  };

  handleExportResetClick = () => {
    this.setState({ exportAvailable: false });
  };

  // ####################################
  // #### Handle Template Selection #####
  // ####################################

  handleTemplateSelectionClick = () => {
    // Reset state
    this.setState({
      isSelecting: true,
      isBlockify: false,
      isExporting: false,
      selectedTemplateName: 'Templates',
      initialData: getInitialData(),
    });
  };

  onClickSelectedOset = (theme) => {
    this.setState({
      selectedTemplateName: theme,
    });
  };

  onClickTemplateSelection = (template) => {
    return this.setState({
      isSelecting: false,
      isExporting: false,
      isBlockify: false,
      editing: false,
      selectedTemplateName: template,
      initialData: getInitialData(template),
    });
  };

  // ##############################################
  // #### Handle Visual Editor Start Up State #####
  // ##############################################

  startRoute = (theme, template) => {
    return this.setState({
      isSelecting: false,
      isExporting: false,
      isBlockify: false,
      editing: false,
      selectedTemplateName: theme,
      initialData: getInitialData(theme, template),
    });
  };
}

export default AppDataStore;
