const Hero = `
<dys-block>
  <dys-row background-color="#4DBFBF">
    <dys-column>
      <dys-img
        height="189px"
        width="216px"
        src="https://assets.opensourceemails.com/imgs/neopolitan/robot-happy.png"
        editable="img"
        align="center"
      />
      <dys-text
        color="#FFFFFF"
        font-size="36px"
        editable="h1"
        font-family="'Droid Sans', 'Helvetica Neue', Arial, sans-serif"
        align="center"
      >
        Welcome!
      </dys-text>
      <dys-text
        color="#187272"
        font-size="16px"
        editable="content"
        font-family="'Droid Sans', 'Helvetica Neue', Arial, sans-serif"
        align="center"
        line-height="20px"
      >
        To the awesomest place on Earth! <br />
        We're sure you will feel right at home with Awesome Co.
      </dys-text>
      <dys-button
        background-color="#178F8F"
        href="https://example.com"
        font-family="'Droid Sans', 'Helvetica Neue', Arial, sans-serif"
        font-weight="bold"
        font-size="16px"
        line-height="30px"
        width="200px"
        border-radius="4px"
        editable="button"
        align="center"
      >
        Activate!
      </dys-button>
    </dys-column>
  </dys-row>
</dys-block>
`;

export default Hero;
