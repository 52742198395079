import MantraInvite from './../../../assets/images/Mantra/MantraInvite.png';
import MantraRating from './../../../assets/images/Mantra/MantraRating.png';
import MantraWelcome from './../../../assets/images/Mantra/MantraWelcome.png';

const MantraTemplates = [
  {
    alt: 'Welcome Template',
    name: 'Welcome',
    src: MantraWelcome,
  },
  {
    alt: 'Rating Template',
    name: 'Rating',
    src: MantraRating,
  },
  {
    alt: 'Invite Template',
    name: 'Invite',
    src: MantraInvite,
  },
];

export default MantraTemplates;
