import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Switch, Route } from 'react-router-dom';

// Analytics
import { loadWasm } from './utils/dml/dml';
import { Hotjar } from './utils/analytics';

// Data store
import { Provider, Subscribe } from 'unstated';
import AppDataStore from './store/StateStore';

// Components
import MenuBar from './components/MenuBar';
import TemplateGroup from './components/TemplateSelection/TemplateGroup';
import TemplateSelectionScreen from './components/TemplateSelection/TemplateSelection';
import VisualEditor from './components/VisualEditor/VisualEditor';
import Banner from './components/Banner';
import {Blockify} from "./components/Blockify/Blockify"


const Routes = () => {
  useEffect(() => {
    // Start loading the wasm lib right away
    loadWasm();
    // Load hotjar script for tracking movement
    Hotjar();
  }, []);

  return (
    <HashRouter basename="/">
      <Provider>
        <Subscribe to={[AppDataStore]}>
          {(appData) => (
            <div>
              <MenuBar />
              <Switch>
              <Route
                  path="/blockify/visual-editor"
                  render={(props) => {
                    return (
                      <div>
                        <VisualEditor {...props} appData={appData} />
                        <Banner />
                      </div>
                    );
                  }}
                />
                <Route
                  path="/blockify/"
                  render={() => <Blockify appData={appData}/>}
                />
                
                <Route
                  path="/:oset/:template/"
                  render={(props) => {
                    return (
                      <div>
                        <VisualEditor {...props} appData={appData} />
                        <Banner />
                      </div>
                    );
                  }}
                />
                <Route 
                  path="/:oset/" 
                  render={() => {
                    return (
                      <div>
                        <TemplateGroup />
                        <Banner />
                      </div>
                    );
                  }}
                />
                <Route 
                  path="/" 
                  render={(props) => {
                    return (
                      <div>
                        <TemplateSelectionScreen {...props} />
                        <Banner />
                      </div>
                    );
                  }}
                />
              </Switch>
            </div>
          )}
        </Subscribe>
      </Provider>
    </HashRouter>
  );
};
ReactDOM.render(<Routes />, document.getElementById('root'));
