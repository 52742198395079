const Footer = `
<dys-block>
  <dys-row background-color="#414141" padding-bottom="0px">
    <dys-column>
      <dys-table align="center" width="40%">
        <tr align="center">
          <td align="center">
            <a href="https://linkedin.com">
              <img
                width="50px"
                height="50px"
                src="https://swu-cs-assets.s3.amazonaws.com/OSET/neopolitan/linkedin.png"
                alt="linkedin"
              />
            </a>
          </td>
          <td align="center">
            <a href="https://facebook.com">
              <img
                width="50px"
                height="50px"
                src="https://swu-cs-assets.s3.amazonaws.com/OSET/neopolitan/facebook.png"
                alt="facebook"
              />
            </a>
          </td>
          <td align="center">
            <a href="https://twitter.com">
              <img
                width="50px"
                height="50px"
                src="https://swu-cs-assets.s3.amazonaws.com/OSET/neopolitan/twitter.png"
                alt="twitter"
              />
            </a>
          </td>
        </tr>
      </dys-table>
    </dys-column>
  </dys-row>
  <dys-row background-color="#414141" padding-top="0px">
    <dys-column>
      <dys-text
        color="#BBBBBB"
        font-size="12px"
        font-family="'Droid Sans', 'Helvetica Neue', Arial, sans-serif"
        align="center"
        editable="neopolitan-footer"
      >
        View in Browser | Unsubscribe | Contact
        <br /><br />
        &copy; 2019 All Rights Reserved
      </dys-text>
    </dys-column>
  </dys-row>
</dys-block>
`;

export default Footer;
