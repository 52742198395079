import React, { PureComponent } from 'react';
import styled from 'styled-components';

import BlockLibraryBlockList from './../BlockLibrary/BlockList';
import BlockDrag from './../../assets/images/BlockDrag';

const VisualEditorList = styled.div`
  transition: background-color 0.2s ease;
  background-color: ${(props) => (props.isDraggingOver ? 'lightgrey' : 'white')};
  height: calc(93vh - 56px);
  overflow: auto;
  padding: 2px;
`;

const DropBlocksHereContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: black;
  min-height: calc(88vh - 56px);
`;

const DropBlocksHereText = styled.h3`
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: 30px;
  margin-top: 8px;
  color: rgb(70, 70, 70);
  text-align: center;
  font-family: Roboto, sans-serif;
`;

export default class VisualEditorDisplay extends PureComponent {
  render() {
    return (
      <div>
        <VisualEditorList
          ref={this.props.provided.innerRef}
          isDraggingOver={this.props.snapshot.isDraggingOver}
        >
          <BlockLibraryBlockList blocks={this.props.blocks} area={this.props.area} />
          {this.props.blocks.length > 0 // If no blocks in Visual Editor Preview, show zero state.
            ? ''
            : (
                <DropBlocksHereContainer>
                  <BlockDrag />
                  <DropBlocksHereText>
                    Drop a block onto this surface to get started!
                  </DropBlocksHereText>
                </DropBlocksHereContainer>
              )}
          {this.props.provided.placeholder}
        </VisualEditorList>
      </div>
    );
  }
}
