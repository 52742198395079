import uuid from 'uuid/v4';

// ########################
// ### Handle React DND ###
// ########################

// Reorders blocks from the visual editor
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// Copies a block from the block library to the visual editor
export const copy = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source.blockIds);
  const destClone = Array.from(destination.blockIds);
  const item = sourceClone[droppableSource.index];

  destClone.splice(droppableDestination.index, 0, { ...item, id: uuid() });

  const newStart = {
    ...source,
    blockIds: sourceClone,
  };

  const newFinish = {
    ...destination,
    blockIds: destClone,
  };
  const result = {};

  result[newStart.id] = newStart;
  result[newFinish.id] = newFinish;

  return result;
};

// Moves blocks from list to list. Used as a default
export const move = (start, finish, source, destination) => {
  const startBlockIds = Array.from(start.blockIds);
  const [removed] = startBlockIds.splice(source.index, 1);

  const finishBlockIds = Array.from(finish.blockIds);
  finishBlockIds.splice(destination.index, 0, removed);

  const newStart = {
    ...start,
    blockIds: startBlockIds,
  };

  const newFinish = {
    ...finish,
    blockIds: finishBlockIds,
  };

  const result = {};

  result[newStart.id] = newStart;
  result[newFinish.id] = newFinish;
  return result;
};
