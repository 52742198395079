import React, { useRef, useState, useEffect } from 'react';
import AppDataStore from './../../store/StateStore';
import { useInterval } from './../../utils/hooks';
import { Controlled as CodeMirror } from 'react-codemirror2';
import pretty from 'pretty';
import { newId, newName, resetCount } from './../../utils/igden';
import styled from 'styled-components';
import { simpleParser } from 'mailparser';
import parse from 'parse-color';
import { parse as htmlParse } from 'node-html-parser';
import './../../styles/SplitterStyle/splitterStyle.css';
import './../../styles/CodeMirror/codemirror.css';
import './../../styles/CodeMirror/codemirrorStyle.css';
import 'codemirror/mode/xml/xml.js';
import 'codemirror/addon/fold/xml-fold.js';
import 'codemirror/addon/edit/closetag.js';
import 'codemirror/addon/lint/lint.css';
import 'codemirror/addon/lint/json-lint.js';
import 'codemirror/addon/lint/lint.js';

import 'codemirror/mode/xml/xml.js';
import { InstructionsContainer, InstructionsCard } from './InstructionPopover';

const PreviewScroller = styled.div`
  margin: 0;
  padding: 0;
  height: calc(100vh - 120px);
  overflow-y: scroll;
`;

const FrameWrapper = styled.div<{ height: number }>`
  background-color: #fff;
  display: grid;
  height: ${(props) => props.height}px;
  overflow: hidden;
  align-content: stretch;
  align-items: stretch;
  justify-content: stretch;
  justify-items: stretch;
`;

const Frame = styled.iframe`
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;
  width: 100%;
  border: none;
  overflow: hidden;
`;

const FrameOverlay = styled.div`
  width: 100%;
  z-index: 1000;
  display: grid;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;
  grid-row-end: 1;
  overflow: hidden;
`;

enum ChopLinePosition {
  Above = 1,
  Below,
}

interface ChopLine {
  y: number;
  nearestEl: HTMLElement;
  position: ChopLinePosition;
}

interface Rect {
  x: number;
  y: number;
  w: number;
  h: number;
  el: HTMLElement;
}

interface RectViewProps {
  rect: Rect;
  color: string;
}

const RectView = styled.div<RectViewProps>`
  border: 2px solid ${(props) => props.color};
  position: relative;
  left: ${(props) => props.rect.x}px;
  top: ${(props) => props.rect.y}px;
  width: ${(props) => props.rect.w}px;
  height: ${(props) => props.rect.h}px;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;
`;

interface LineViewProps {
  y: number;
  border: string;
}

const LineView = styled.hr<LineViewProps>`
  border: none;
  border-top: ${(props) => props.border};
  top: ${(props) => props.y + 1}px;
  height: 1px;
  width: 100%;
  display: flex;
  position: relative;
  margin: 0;
  padding: 0;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;
`;

const DarkInstructions = styled.div`
  width: 100%;
  padding: 10px 12px;
  margin: 0px;

  background-color: #272822;
  color: #1EC2BD;
  letter-spacing: 0.4px;
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
`;

const LightInstructions = styled.div`
  width: 100%;
  vertical-align: top;
  margin:0px;
  padding: 10px 12px;
  border-bottom: 1px solid #ddd
  background-color: white;
  color: #272822;
  letter-spacing: 0.4px;
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
`;

const GreyBackground = styled.div`
  display: flex;
  width: 100%;
  padding: 0;
  background-color: #f7f7f7;
  height: calc(100vh - 120px);
  vertical-align: top;
  margin: 0px;
`;

interface Block {
  id: string;
  name: string;
  dml: string;
  contentId: string;
  default: Default;
  type: string;
}

interface Default {
  dataset: { [k: string]: any };
}

interface Props {
  appData: AppDataStore;
}

/// The purpose of this file is to simplify the DevEnv/ChopWizard/ChoppingBoard structure for a version that can be ported to Smaug
export function Blockify(props: Props) {
  const [showInstructions, setShowInstructions] = useState(true);

  const frameRef = useRef<HTMLIFrameElement>(null);
  const [contentRects, setContentRects] = useState([] as Rect[]);
  const [bgRects, setBgRects] = useState([] as Rect[]);
  const [showContentBoxes, setShowContentBoxes] = useState(false);
  const [showBgBoxes, setShowBgBoxes] = useState(false);
  const [possibleChopLines, setPossibleChopLines] = useState([] as ChopLine[]);
  const [showPossibleChopLines, setShowPossibleChopLines] = useState(false);
  const [mouseY, setMouseY] = useState<number | null>(null);
  const [selectedChopLine, setSelectedChopLine] = useState<ChopLine | null>(null);
  const [pendingLines, setPendingLines] = useState([] as ChopLine[]);
  const [source, setSource] = useState('');
  const [previewSource, setPreviewSource] = useState('');
  const [docHeight, setDocHeight] = useState(900);

  useEffect(() => {
    props.appData.loadBlockify(blockify);
    testSplit();
  }, []);

  // used to update number of
  useEffect(
    () => {
      props.appData.updateBlockCount(pendingLines.length);
      blockify();
    },
    [pendingLines]
  );

  useEffect(
    () => {
      if (!frameRef.current) {
        return;
      }
      setContentRects([]);
      setBgRects([]);
      setPossibleChopLines([]);
      setSelectedChopLine(null);

      if (showContentBoxes) {
        const newRects = getContentRects(frameRef.current);
        setContentRects(newRects);
      }

      if (showBgBoxes) {
        const newRects = getBgRects(frameRef.current);
        setBgRects(newRects);
      }

      if (showPossibleChopLines) {
        const newLines = getSortedChopLines(frameRef.current);
        setPossibleChopLines(newLines);
      }

      if (mouseY !== null) {
        const closest = closestChopLine(
          mouseY,
          getSortedChopLines(frameRef.current)
        );
        if (closest !== null) {
          setSelectedChopLine(closest);
        }
      }
    },
    [frameRef, showContentBoxes, showBgBoxes, showPossibleChopLines, mouseY]
  );

  useInterval(() => {
    updateFrameHeight();
  }, 1000);

  useEffect(() => {
    setTimeout(updateFrameHeight, 100);
  }, []);

  function updateFrameHeight() {
    if (frameRef.current) {
      setDocHeight(getDocumentHeight(frameRef.current));
    }
  }

  function togglePendingLine() {
    if (selectedChopLine === null) {
      return;
    }
    const newLines = pendingLines.filter((l) => l.y !== selectedChopLine.y);
    if (newLines.length !== pendingLines.length) {
      setPendingLines(newLines);
    } else {
      setPendingLines(newLines.concat([selectedChopLine]));
    }
  }

  function updateMouseY(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (frameRef.current) {
      const scroller = frameRef.current.parentElement!.parentElement!;
      const relativeY = e.clientY - frameRef.current.offsetTop + scroller.scrollTop;
      setMouseY(relativeY);
    }
  }

  function blockify() {
    if (!frameRef.current) {
      return;
    }
    resetCount();
    const parts = chop(frameRef.current, pendingLines);
    createBlocks(parts);
  }

  function createBlocks(parts: Document[]) {
    const newBlocks = parts.map(docToBlock);
    let new_data = {
      columnOrder: ['block-library', 'visual-editor', 'edit-mode'],
      columns: {
        'block-library': {
          blockIds: newBlocks,
          id: 'block-library',
          title: 'Block Library',
        },
        'visual-editor': {
          blockIds: [],
          id: 'visual-editor',
          title: 'Visual Editor',
        },
      },
    };
    props.appData.generateBlocks(new_data);
  }

  function docToBlock(doc: Document): Block {
    const innerHtml = pretty(doc.body ? doc.body.innerHTML : '');
    const new_id = newId('block');
    const result = getBlockifyDataset(new_id, innerHtml);
    const newHtml = result[0];
    const dataset = result[1];
    return {
      id: new_id,
      name: newName(),
      dml: `<dys-block>\n  <dys-html>\n    ${newHtml}\n  </dys-html>\n</dys-block>`,
      contentId: new_id,
      default: {
        dataset: dataset,
      },
      type: 'Blockify',
    };
  }

  function getBlockifyDataset(
    id: string,
    innerHtml: string
  ): [string, { [k: string]: any }] {
    let dataset: { [k: string]: any } = new Object();
    const root = htmlParse(innerHtml);
    // Scrape images
    let imgs = root.querySelectorAll('img');
    for (var i = 0; i < imgs.length; i++) {
      // get src info
      var src_link = imgs[i].getAttribute('src');
      var url_alias = 'blockify-' + id + '-' + i + '-imgurl-group3';
      dataset[url_alias] = src_link;
      imgs[i].setAttribute('src', '{{' + url_alias + '}}');
      // get alt info
      var alt_text = imgs[i].getAttribute('alt');
      var alt_alias = 'blockify-' + id + '-' + i + '-alt-group3';
      dataset[alt_alias] = alt_text;
      imgs[i].setAttribute('alt', '{{' + alt_alias + '}}');

      imgs[i].setAttribute('editable', id + '-img' + i);
    }
    // Scrape Text
    const text_tags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span', 'li', 'td'];
    for (var i = 0; i < text_tags.length; i++) {
      var texts = root.querySelectorAll(text_tags[i]);

      for (var j = 0; j < texts.length; j++) {
        // get content
        var hasChildren = false;
        for (var idx in texts[j].childNodes) {
          var child = texts[j].childNodes[idx];
          if (child !== undefined && child.hasOwnProperty('tagName')) {
            hasChildren = true;
            break;
          }
        }
        if (!hasChildren) {
          const tag_content = texts[j].text;
          if (tag_content.length === 0 || tag_content == '&nbsp;') continue;
          const content_alias = 'blockify-' + id + '-' + j + '-text-group1';
          dataset[content_alias] = tag_content;
          texts[j].set_content('{{{' + content_alias + '}}}');

          texts[j].setAttribute('editable', id + '-text' + j);
        }
      }
    }
    // Scrape links
    let anchors = root.querySelectorAll('a');
    for (var i = 0; i < anchors.length; i++) {
      // get anchor tag's content
      var hasChildren = false;
      for (var idx in anchors[i].childNodes) {
        var child = anchors[i].childNodes[idx];
        if (child !== undefined && child.hasOwnProperty('tagName')) {
          hasChildren = true;
          break;
        }
      }
      if (!hasChildren) {
        var anchor_content = anchors[i].text;
        var content_alias = 'blockify-' + id + '-' + i + '-text-group1';
        dataset[content_alias] = anchor_content;
        anchors[i].set_content('{{{' + content_alias + '}}}');
      }
      // get anchor tag's link
      var href_link = anchors[i].getAttribute('href');
      var url_alias = 'blockify-' + id + '-' + i + '-url-group1';
      dataset[url_alias] = href_link;
      anchors[i].setAttribute('href', '{{' + url_alias + '}}');

      anchors[i].setAttribute('editable', id + '-link' + i);
    }

    return [root.toString(), dataset];
  }

  function sourceChanged(src: string) {
    setSource(src);
    getHtml(src).then((html) => setPreviewSource(html), (err) => console.error(err));
  }

  const closeInstructions = () => {
    setShowInstructions(false);
  };
  type closeInstructions = () => void;
  return (
    <div style={{ padding: '0px' }}>
      {showInstructions && (
        <InstructionsContainer>
          <InstructionsCard closeInstructions={closeInstructions} />
        </InstructionsContainer>
      )}

      <div
        id="left"
        style={{
          display: 'inline-block',
          verticalAlign: 'top',
          width: '50%',
          margin: '0px',
        }}
      >
        <DarkInstructions>
          Step 1: Paste your HTML email code below.
        </DarkInstructions>
        <CodeMirror
          value={source}
          options={{
            mode: 'xml',
            theme: 'lesser-dark',
            lineNumbers: true,
            resizeTo: '100%',
            lineWrapping: true,
            autoRefresh: true,
            readOnly: false,
            autoCloseTags: true,
            gutters: ['CodeMirror-lint-markers'],
          }}
          onBeforeChange={(editor, data, value) => {
            setSource(value);
          }}
          onChange={(editor, data, value) => {
            editor.options.value = source;
            sourceChanged(source);
          }}
        />
      </div>
      <div
        id="right"
        style={{
          display: 'inline-block',
          verticalAlign: 'top',
          width: '50%',
          margin: '0px',
          overflow: 'hidden',
        }}
      >
        <LightInstructions>
          Step 2: Click along each email section to add divider lines. When you're
          ready, click on the top right "Blockify Now" button to see the reusable
          blocks in our Visual Editor!
        </LightInstructions>
        {source == '' ? (
          <GreyBackground>
            <div
              style={{
                backgroundColor: '#FFFFFF',
                width: '600px',
                height: '90%',
                margin: 'auto',
                flex: '0 1 auto',
              }}
            />
          </GreyBackground>
        ) : (
          <PreviewScroller>
            <FrameWrapper height={docHeight}>
              <FrameOverlay
                onMouseLeave={() => setMouseY(null)}
                onMouseMove={updateMouseY}
                onMouseEnter={updateMouseY}
                onMouseOver={updateMouseY}
                onClick={togglePendingLine}
              >
                {contentRects.map((r, i) => (
                  <RectView rect={r} key={i} color="pink" />
                ))}
                {bgRects.map((r, i) => (
                  <RectView rect={r} key={i} color="red" />
                ))}
                {possibleChopLines.map((chopLine, i) => (
                  <LineView y={chopLine.y} border="1px solid #CC3535" key={i} />
                ))}
                {pendingLines.map((chopLine, i) => (
                  <LineView y={chopLine.y} border="2px solid #CC3535" key={i} />
                ))}
                {selectedChopLine && (
                  <LineView y={selectedChopLine.y} border="2px dashed #CC3535" />
                )}
              </FrameOverlay>
              <Frame title="your email" ref={frameRef} srcDoc={previewSource} />
            </FrameWrapper>
          </PreviewScroller>
        )}
      </div>
    </div>
  );
}

function getDocumentHeight(iframe: HTMLIFrameElement): number {
  if (!iframe.contentDocument) {
    return 0;
  }
  const body = iframe.contentDocument.body;
  if (!body) {
    return 0;
  }
  return Math.max(body.clientHeight, body.scrollHeight, body.offsetHeight);
}

function noDocumentEdges(iframe: HTMLIFrameElement, lines: ChopLine[]): ChopLine[] {
  const docHeight = getDocumentHeight(iframe);
  return lines.filter((line) => line.y !== 0 && line.y <= docHeight - 1);
}

function getSortedChopLines(iframe: HTMLIFrameElement): ChopLine[] {
  const candidates = getPossibleChopLineCandidates(iframe);
  const noEdges = noDocumentEdges(iframe, candidates);
  const deDuped = noDuplicates(noEdges);
  const sorted = sortChopLines(deDuped);
  return sorted;
}

function closestChopLine(y: number, lines: ChopLine[]): ChopLine | null {
  for (let i = 0; i < lines.length; i++) {
    const current = lines[i];
    const next = lines[i + 1];
    if (!next) {
      return current;
    }
    if (current.y > y) {
      return current;
    }
    if (next.y >= y) {
      const currentDist = Math.abs(current.y - y);
      const nextDist = Math.abs(next.y - y);
      return currentDist < nextDist ? current : next;
    }
  }
  return null;
}

function sortChopLines(lines: ChopLine[]): ChopLine[] {
  return lines.sort((a, b) => a.y - b.y);
}

function noDuplicates(lines: ChopLine[]): ChopLine[] {
  const used: { [key: number]: boolean } = {};
  const result = [] as ChopLine[];
  for (const line of lines.reverse()) {
    if (!used[line.y]) {
      result.push(line);
      used[line.y] = true;
    }
  }
  return result.reverse();
}

function isDocRoot(node: any): boolean {
  if (!node.parentNode) {
    return true;
  }

  const tagName = node.tagName;
  const parentTagName =
    node.parentNode.tagName && node.parentNode.tagName.toLowerCase();
  if (parentTagName === 'html' || tagName === 'body') {
    return true;
  }

  return false;
}

function getPossibleChopLineCandidates(iframe: HTMLIFrameElement): ChopLine[] {
  const contentRects = getContentRects(iframe);
  const bgRects = getBgRects(iframe);

  return contentRects
    .map((r) => ({ y: r.y, nearestEl: r.el, position: ChopLinePosition.Above }))
    .concat(
      contentRects
        .map((r) => ({
          y: r.y + r.h,
          nearestEl: r.el,
          position: ChopLinePosition.Below,
        }))
        .concat(
          bgRects.map((r) => ({
            y: r.y,
            nearestEl: r.el,
            position: ChopLinePosition.Above,
          }))
        )
        .concat(
          bgRects.map((r) => ({
            y: r.y + r.h,
            nearestEl: r.el,
            position: ChopLinePosition.Below,
          }))
        )
    );
}

function getBgRects(iframe: HTMLIFrameElement): Rect[] {
  if (!iframe.contentDocument) {
    return [];
  }

  const body = iframe.contentDocument.body;
  const elements = findBgElements(body);
  const rects = elements.map(rectFromElement).filter(hasMass);
  return rects;
}

function getContentRects(iframe: HTMLIFrameElement): Rect[] {
  if (!iframe.contentDocument) {
    return [];
  }

  const body = iframe.contentDocument.body;
  const contentElements = findContentElements(body);
  const rects = contentElements.map(rectFromElement).filter(hasMass);
  return rects;
}

function rectFromElement(el: HTMLElement): Rect {
  const domRect = el.getBoundingClientRect();
  return domRectToGoodRect(el, domRect);
}

function domRectToGoodRect(el: HTMLElement, rect: DOMRect | ClientRect): Rect {
  return {
    x: rect.left,
    y: rect.top,
    w: rect.width,
    h: rect.height,
    el,
  };
}

function isVisible(el: HTMLElement): boolean {
  return !!(
    el.offsetWidth ||
    el.offsetHeight ||
    (el.getClientRects && el.getClientRects().length)
  );
}

function hasMass(r: Rect): boolean {
  return r.w > 0 && r.h > 0;
}

function findBgElements(el: HTMLElement): HTMLElement[] {
  if (!isVisible(el)) {
    return [];
  }

  const result = (isBgElement(el) ? [el] : []) as HTMLElement[];
  const children = childrenElements(el);
  return result.concat(
    children
      .map((ch) => findBgElements(ch))
      .reduce((l, r) => l.concat(r), [] as HTMLElement[])
  );
}

function findContentElements(el: HTMLElement): HTMLElement[] {
  if (!isVisible(el)) {
    return [];
  }

  if (isContentElement(el)) {
    return [el];
  }
  const children = childrenElements(el);
  return children
    .map((ch) => findContentElements(ch))
    .reduce((l, r) => l.concat(r), [] as HTMLElement[]);
}

function hasTextChildren(el: HTMLElement): boolean {
  const children = childrenNodes(el);
  return children.some(
    (ch) => ch.nodeType === Node.TEXT_NODE && !allWhitespace(ch.nodeValue || '')
  );
}

function allWhitespace(text: string): boolean {
  return !/\S/.test(text);
}

// Checks that the color is actually valid and that it is not transparent.
function isLegitColor(color: string): boolean {
  if (!color) {
    return false;
  }
  try {
    const parsed = parse(color);
    return parsed && parsed.rgba[3] !== 0;
  } catch (e) {
    return false;
  }
}

// Checks that the background image is actually something meaningful and not
// empty string, or 'none'.
function isLegitBgImage(img: string): boolean {
  return !!img && img !== 'none';
}

function isBgElement(el: HTMLElement): boolean {
  const style = getComputedStyle(el);
  const backgroundColor = style.getPropertyValue('background-color');
  const bgColor = style.getPropertyValue('bgcolor');
  const bgImage = style.getPropertyValue('background-image');

  return (
    isLegitColor(backgroundColor) || isLegitColor(bgColor) || isLegitBgImage(bgImage)
  );
}

function isContentElement(el: HTMLElement): boolean {
  if (hasTextChildren(el)) {
    return true;
  }

  if (probablyATrackingPixel(el)) {
    return false;
  }

  return !!~['p', 'span', 'img', 'article', 'section'].indexOf(
    el.tagName.toLowerCase()
  );
}

function probablyATrackingPixel(el: HTMLElement): boolean {
  return (
    el.tagName.toLowerCase() === 'img' &&
    el.getAttribute('width') === '1' &&
    el.getAttribute('height') === '1'
  );
}

// Same as childrenNodes but only includes nodes that are elements.
function childrenElements(n: Node): HTMLElement[] {
  return childrenNodes(n)
    .filter(elementish)
    .map((n) => n as HTMLElement);
}

// HTMLElement.childNodes gives you a NodeList that is not a proper array and
// that therefore does not have .map() or looping capability. This function
// converts it to a real array so you can do that stuff.
function childrenNodes(n: Node): ChildNode[] {
  const children = [] as ChildNode[];
  for (let i = 0; i < n.childNodes.length; i++) {
    children.push(n.childNodes[i]);
  }
  return children;
}

function chop(iframe: HTMLIFrameElement, chopLines: ChopLine[]): Document[] {
  if (!iframe.contentDocument) {
    return [];
  }

  markSplitPoints(chopLines);
  const blockDocs = [] as Document[];

  let lastDoc: Document | null = iframe.contentDocument;
  for (const line of chopLines) {
    const split = splitDoc(lastDoc!);
    //console.log('SPLITTING ON', line, split)
    blockDocs.push(split.doc1);
    lastDoc = split.doc2!;
    if (!split.doc2) {
      break;
    }
  }
  if (lastDoc) {
    blockDocs.push(lastDoc);
  }

  return blockDocs;
}

function markSplitPoints(chopLines: ChopLine[]) {
  let index = 0;
  for (const line of chopLines) {
    if (line.position === ChopLinePosition.Above) {
      line.nearestEl.setAttribute('data-chopabove', `${index}`);
    } else {
      line.nearestEl.setAttribute('data-chopbelow', `${index}`);
    }
    index++;
  }
}

interface SplitDocResult {
  doc1: Document;
  doc2: Document | null;
}

// Deep copies the given doc, split at the FIRST chop line found based on the
// data-chop* attribute. Example:
//  input doc: <body><div><h1>Title</h1 data-chopafter="0"><p>Hello world</p></div></body>
//  output doc1: <body><div><h1>Title</h1></div></body>
//  output doc2: <body><div><p>Hello world</p></div></body>
function splitDoc(doc: Document): SplitDocResult {
  // Recursively visits elements from the given DOM node. The content of oldEl
  // gets copied into the content of newEl until an element with a data-chop*
  // attribute is found. When a chop point is found it stops copying/visiting
  // and returns the "tail" document, which is a document that contains all
  // nodes that were not copied yet.
  function visitElement(oldEl: HTMLElement, newEl: HTMLElement): Document | null {
    for (const oldChild of childrenNodes(oldEl)) {
      // If splitting above then stop before adding this child element
      if (elementish(oldChild)) {
        const chopAboveLineIndex = (oldChild as HTMLElement).getAttribute(
          'data-chopabove'
        );
        if (chopAboveLineIndex !== null) {
          (oldChild as HTMLElement).removeAttribute('data-chopabove');
          return resumeDoc(oldChild as HTMLElement, false);
        }
      }

      const newChild = oldChild.cloneNode(false);
      newEl.appendChild(newChild);

      if (elementish(oldChild)) {
        // Not splitting so continue visiting children until finished or a chop
        // point is found
        const oldChildEl = oldChild as HTMLElement;
        const tail = visitElement(oldChildEl, newChild as HTMLElement);
        if (tail !== null) {
          return tail;
        }

        // If splitting below then stop after adding this child element
        const chopBelowLineIndex = oldChildEl.getAttribute('data-chopbelow');
        if (chopBelowLineIndex !== null) {
          oldChildEl.removeAttribute('data-chopbelow');
          return resumeDoc(oldChildEl, true);
        }
      }
    }

    return null;
  }

  // Make a new document instead of editing existing one
  const blockDoc = document.implementation.createHTMLDocument('block');
  copyAttrs(doc.body, blockDoc.body);
  const tail = visitElement(doc.body, blockDoc.body);
  if (tail) {
    copyAttrs(doc.body, tail.body);
  }

  return {
    doc1: blockDoc,
    doc2: tail,
  };
}

function resumeDoc(from: HTMLElement, below: boolean): Document {
  const parent = from.parentNode as HTMLElement | null;
  if (!parent) {
    throw new Error('Chop target should always have a parent element!');
  }

  // Build the tree of parent nodes that need to exist in this context. This is
  // like re-opening all the tags that were forced closed by the chop.
  let root: any = parent;
  const parentClone = parent.cloneNode(false);
  let rootClone: any = parentClone;
  while (true) {
    if (isDocRoot(root)) {
      break;
    }
    const nextParent = root.parentNode;
    const nextParentClone = nextParent.cloneNode(false);
    nextParentClone.appendChild(rootClone);

    // add siblings that appear AFTER this node
    let found = false;
    for (const sibling of childrenNodes(nextParent)) {
      if (sibling === root) {
        found = true;
        continue;
      }
      if (found) {
        nextParentClone.appendChild(sibling.cloneNode(true));
      }
    }

    root = nextParent;
    rootClone = nextParentClone;
  }

  // Add children but only the ones after the chop point.
  let foundChopPoint = false;
  for (const child of childrenNodes(parent)) {
    if (child === from) {
      foundChopPoint = true;
      if (below) {
        // chopping below so don't include this one
        continue;
      }
    }

    if (foundChopPoint) {
      parentClone.appendChild(child.cloneNode(true));
    }
  }

  return createDoc(rootClone as HTMLElement, 'tail');
}

function createDoc(body: HTMLElement, title: string): Document {
  const doc = document.implementation.createHTMLDocument(title);
  for (const child of childrenNodes(body)) {
    doc.body.appendChild(child);
  }
  return doc;
}

function elementish(node: Node): boolean {
  if (node.nodeType === Node.ELEMENT_NODE) {
    return true;
  }

  const canHaveChildren = !!(node as any).childNodes;
  return canHaveChildren && (node as any).childNodes.length;
}

function copyAttrs(from: HTMLElement, to: HTMLElement) {
  for (let i = 0; i < from.attributes.length; i++) {
    const attr = from.attributes[i];
    to.setAttribute(attr.name, attr.value);
  }
}

function getLanguage(src: string): string {
  if (isHtml(src)) {
    return 'html';
  }
  return 'text';
}

function isHtml(src: string): boolean {
  return src.trim()[0] === '<';
}

async function getHtml(src: string): Promise<string> {
  // It's already HTML!
  if (isHtml(src)) {
    return src;
  }

  // It's some multi-part email garbage!
  const parsed = await simpleParser(src);
  if (parsed.html) {
    return parsed.html as string;
  } else if (parsed.text) {
    return parsed.text as string;
  } else {
    return '';
  }
}

function htmlToElement(html: string) {
  const template = document.createElement('template');
  html = html.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = html;
  return template.content.firstChild!;
}

// Run this on startup to get a console error if something is broken
export function testSplit() {
  const testDoc = document.implementation.createHTMLDocument('test');
  testDoc.body.appendChild(
    htmlToElement(`<div><div data-chopbelow="1">a</div><div>b</div></div>`)
  );
  const res = splitDoc(testDoc);

  const errors = [] as string[];

  if (res.doc1.body.innerHTML !== '<div><div data-chopbelow="1">a</div></div>') {
    errors.push('Bad first block: ' + res.doc1.body.innerHTML);
  }

  if (res.doc1.body.innerHTML !== '<div><div data-chopbelow="1">a</div></div>') {
    errors.push('Bad second block: ' + res.doc2!.body.innerHTML);
  }

  if (errors.length) {
    console.error('UNIT TESTS FAILED', errors);
  } else {
    // console.log('Good news! Unit tests all passed :)');
  }
}
