import React from 'react';
import { Provider, Subscribe } from 'unstated';
import styled from 'styled-components';

import AppDataStore from './../store/StateStore';
import DyspatchLogo from './../assets/images/DyspatchLogo';
import { ReactComponent as WebIcon } from './../assets/icons/svg/web.svg';

import { BlueButtonWhiteText } from './../styles/Buttons/ButtonStyles';

import { StyledLink } from './../styles/Base/Common';
import { GoBackButton } from './../styles/Buttons/ButtonStyles';

import BackArrow from './../assets/images/BackArrow';

const EscapeIconContainer = styled.div`
  min-height: 56px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  border-right: 1px solid #e0e0e0;
`;

const MenuBarContainer = styled.div`
  position: sticky;
  top: 0;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  border-bottom: 1px solid rgb(224, 224, 224);
`;

const TemplateSelectContainer = styled.div`
  align-self: stretch;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: row;
  font-size: 18px;
  border-top-width: initial;
  border-bottom-width: initial;
  border-left-width: initial;
  border-top-color: initial;
  border-bottom-color: initial;
  border-left-color: initial;
  letter-spacing: 0.4px;
  white-space: nowrap;
  border-style: none solid none none;
  border-image: initial;
  background: transparent;
  padding: 0px 24px;
  border-right: 1px solid rgb(224, 224, 224);
  border-left: 1px solid rgb(224, 224, 224);
  text-decoration: none;
  :hover {
    cursor: default;
  }
`;

const TemplateSelectContentContainer = styled.div`
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
`;

const TemplateSelectAboveText = styled.span`
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 1.2px;
  color: rgb(120, 120, 120);
  display: block;
  position: relative;
  text-transform: uppercase;
  margin-bottom: 0px;
  text-decoration: none;
`;

const TemplateSelectMainText = styled.div`
  font-size: 18px;
  letter-spacing: 0.4px;
  color: rgb(70, 70, 70);
  display: block;
  position: relative;
  max-width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: normal;
  align-content: normal;
  justify-content: normal;
  align-self: auto;
  flex-wrap: nowrap;
  flex: 0 0 auto;
  padding: 0px 24px 0px 0px;
  margin: 0px;
`;

const LogoAndTemplateSelectionContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  background-color: #ffffff;
`;

const MenuBar = (props) => {
  return (
    <Provider>
      <Subscribe to={[AppDataStore]}>
        {(appData) => (
          <MenuBarContainer>
            <LogoAndTemplateSelectionContainer>
              {window.location.hash !== '#/' && (
                <EscapeIconContainer>
                  <GoBackButton>
                    <StyledLink
                      style={{
                        color: '#1F76D3',
                      }}
                      onClick={appData.handleTemplateSelectionClick}
                      to="/"
                    >
                      <BackArrow />
                      Back
                    </StyledLink>
                  </GoBackButton>
                </EscapeIconContainer>
              )}
              <StyledLink
                to={{
                  pathname: '/',
                  state: {
                    redirect: true,
                    handleTemplateSelectionClick:
                      appData.handleTemplateSelectionClick,
                  },
                }}
              >
                <DyspatchLogo />
              </StyledLink>

              <TemplateSelectContainer>
                <TemplateSelectContentContainer>
                  <TemplateSelectAboveText>Template</TemplateSelectAboveText>
                  <TemplateSelectMainText>
                    <WebIcon
                      style={{
                        height: '18px',
                        width: '18px',
                        verticalAlign: 'middle',
                      }}
                    />
                    <span
                      style={{
                        marginLeft: '8px',
                        verticalAlign: 'middle',
                      }}
                    >
                      {appData.state.selectedTemplateName}
                    </span>
                  </TemplateSelectMainText>
                </TemplateSelectContentContainer>
              </TemplateSelectContainer>
            </LogoAndTemplateSelectionContainer>

            <ButtonContainer>
              {appData.state.isExporting === true ? (
                ''
              ) : appData.state.isSelecting === true ? (
                ''
              ) : appData.state.isBlockify === true ? (
                <BlueButtonWhiteText
                  title={
                    appData.state.blockifyCount !== 0
                      ? 'Get HTML template!'
                      : 'Chop the editor to export the HTML'
                  }
                  isAllowed={appData.state.blockifyCount !== 0}
                  onClick={() => {
                    if (appData.state.blockifyCount !== 0) {
                      appData.blockifyToVisualEditor();
                      window.location.href = '#/blockify/visual-editor';
                    }
                  }}
                >
                  Blockify Now
                </BlueButtonWhiteText>
              ) : (
                <BlueButtonWhiteText
                  title={
                    appData.state.initialData.columns['visual-editor'].blockIds
                      .length !== 0
                      ? 'Get HTML template!'
                      : 'Add some blocks the editor to export the HTML'
                  }
                  isAllowed={
                    appData.state.initialData.columns['visual-editor'].blockIds
                      .length !== 0
                  }
                  onClick={
                    appData.state.initialData.columns['visual-editor'].blockIds
                      .length !== 0
                      ? appData.startExporting
                      : ''
                  }
                >
                  Export
                </BlueButtonWhiteText>
              )}
            </ButtonContainer>
          </MenuBarContainer>
        )}
      </Subscribe>
    </Provider>
  );
};

export default MenuBar;
