const Button = `
<dys-block>  
  <dys-row>
    <dys-column>
      <dys-button href='{{ mantra-button-buttonurl-group2 }}' align='middle' align='center' height='25px' width='210px' background-color='#feae39' font-family='Open Sans, Arial, sans-serif' font-size='17px' font-weight='bold' color='#ffffff' text-transform='uppercase' editable='button'>
        {{{ mantra-button-buttontext-group2 }}}
      </dys-button>
    </dys-column>
  </dys-row>
</dys-block>
`;

export default Button;
