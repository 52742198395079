import React, { PureComponent } from 'react';
import { Provider, Subscribe } from 'unstated';

import AppDataStore from './../../store/StateStore';
import XIcon from './../../assets/images/XIcon';
import EmailCapture from './../Capture/Form';

import {
  ExportContainer,
  ButtonText,
  ExportMessageContainer,
  ExportTitle,
  ExportText,
  ExportErrorMessage,
  EscapeIconContainer,
  CopyTextArea,
  EmailContainer,
} from './Styles';

class ExportDataStore extends PureComponent {
  state = {
    copySuccess: 'Copy to Clipboard',
    email: '',
  };

  copyToClipboard = (e) => {
    this.textArea.select();
    document.execCommand('copy');
    e.target.focus();
    this.setState({ copySuccess: 'Copied!' });
  };

  handleChange = (e) => {
    this.setState({ email: e.target.value });
  };

  render() {
    return (
      <Provider>
        <Subscribe to={[AppDataStore]}>
          {(appData) =>
            appData.state.exportAvailable ? (
              <ExportContainer>
                <div className="goBack" style={{ padding: 20 }}>
                  <EscapeIconContainer onClick={appData.handleExportResetClick}>
                    <XIcon />
                  </EscapeIconContainer>
                </div>
                <CopyTextArea
                  readOnly
                  ref={(textarea) => (this.textArea = textarea)}
                  value={appData.state.exportData}
                />
                {document.queryCommandSupported('copy') && (
                  <ButtonText onClick={this.copyToClipboard}>
                    {this.state.copySuccess}
                  </ButtonText>
                )}
              </ExportContainer>
            ) : (
              <ExportContainer>
                <div className="goBack" style={{ padding: 20 }}>
                  <EscapeIconContainer onClick={appData.stoppedExporting}>
                    <XIcon />
                  </EscapeIconContainer>
                </div>
                <ExportMessageContainer>
                  <ExportTitle>Get Your HTML Template</ExportTitle>
                  <ExportText>
                    Enter your information below to download your customized HTML
                    email template for use with any ESP of your choice.
                  </ExportText>
                </ExportMessageContainer>
                <EmailContainer>
                  <EmailCapture first_name="" last_name="" optin={true} email="" />
                  <ExportErrorMessage>
                    {appData.state.exportErrorMessage}
                  </ExportErrorMessage>
                </EmailContainer>
              </ExportContainer>
            )
          }
        </Subscribe>
      </Provider>
    );
  }
}

export default ExportDataStore;
