import MantraButton from './Button';
import MantraCalloutAndTitle from './CalloutAndTitle';
import MantraFooter from './Footer';
import MantraHeaderBar from './HeaderBar';
import MantraImage from './Image';
import MantraGreyParagraph from './GreyParagraph';
// import MantraInvoiceInfo from './InvoiceInfo';
// import MantraInvoiceItems from './InvoiceItems';
import MantraLogo from './Logo';
import MantraNavigation from './Navigation';
import MantraOpeningText from './OpeningText';
// import MantraProgress from './Progress';
import MantraRating from './Rating';
import MantraSignupInverse from './SignupInverse';
import MantraTitleAndSubtitle from './TitleAndSubtitle';
// import MantraUpcomingProducts from './UpcomingProducts';
// import MantraUpsellBold from './UpsellBold';

const AllMantraBlocks = [
  {
    contentId: 'header-bar',
    name: 'Header Bar',
    dml: MantraHeaderBar,
    type: 'Mantra',
    default: {
      dataset: {
        'mantra-header-text-group1': 'Snippet text here lorem ipsum is dummy text',
        'mantra-view-online-buttontext-group2': 'View Online',
        'mantra-view-online-buttonurl-group2': '#',
      },
    },
  },
  {
    contentId: 'image',
    name: 'Image',
    dml: MantraImage,
    type: 'Mantra',
    default: {
      dataset: {
        'mantra-image-imgurl-group3':
          'https://www.sendwithus.com/assets/img/emailmonks/images/banner.jpg',
        'mantra-image-alt-group3': 'Welcome!',
      },
    },
  },
  {
    contentId: 'callout-and-title',
    name: 'Callout and Title',
    dml: MantraCalloutAndTitle,
    type: 'Mantra',
    default: {
      dataset: {
        'mantra-callout-title-text-group1': 'let us know',
        'mantra-callout-subtitle-text-group1': 'what you think!',
      },
    },
  },
  {
    contentId: 'button',
    name: 'Button',
    dml: MantraButton,
    type: 'Mantra',
    default: {
      dataset: {
        'mantra-button-buttonurl-group2': '#',
        'mantra-button-buttontext-group2': 'OFF20CODE',
      },
    },
  },
  {
    contentId: 'footer',
    name: 'Footer',
    dml: MantraFooter,
    type: 'Mantra',
    default: {
      dataset: {
        'mantra-social-1-buttonurl-group2': '# Facebook',
        'mantra-social-2-buttonurl-group2': '# Twitter',
        'mantra-social-3-buttonurl-group2': '# Pinterest',
        'mantra-social-4-buttonurl-group2': '# Instagram',
        'mantra-social-5-buttonurl-group2': '# Youtube',
        'mantra-social-1-imgurl-group3':
          'https://www.sendwithus.com/assets/img/emailmonks/images/fb.png',
        'mantra-social-1-alt-group3': 'Facebook',
        'mantra-social-2-imgurl-group3':
          'https://www.sendwithus.com/assets/img/emailmonks/images/tw.png',
        'mantra-social-2-alt-group3': 'Twitter',
        'mantra-social-3-imgurl-group3':
          'https://www.sendwithus.com/assets/img/emailmonks/images/pint.png',
        'mantra-social-3-alt-group3': 'Pinterest',
        'mantra-social-4-imgurl-group3':
          'https://www.sendwithus.com/assets/img/emailmonks/images/insta.png',
        'mantra-social-4-alt-group3': 'Instagram',
        'mantra-social-5-imgurl-group3':
          'https://www.sendwithus.com/assets/img/emailmonks/images/yt.png',
        'mantra-social-5-alt-group3': 'Youtube',
        'mantra-privacy-buttonurl-group2': '# Privacy',
        'mantra-privacy-buttontext-group2': 'PRIVACY STATEMENT',
        'mantra-tos-buttonurl-group2': '# TOS',
        'mantra-tos-buttontext-group2': 'TERMS OF SERVICE',
        'mantra-returns-buttonurl-group2': '# Returns',
        'mantra-returns-buttontext-group2': 'RETURNS',
        'mantra-reserved-text-group1': '©2‌019 company name. All Rights Reserved.',
        'mantra-unsub-1-text-group1':
          'If you do not wish to receive any further emails from us, please',
        'mantra-unsub-2-buttontext-group2': 'unsubscribe',
        'mantra-unsub-buttonurl-group2': '#',
      },
    },
  },
  {
    contentId: 'grey-paragraph',
    name: 'Grey Paragraph',
    dml: MantraGreyParagraph,
    type: 'Mantra',
    default: {
      dataset: {
        'mantra-grey-paragraph-text-group1':
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.',
      },
    },
  },
  // {
  //   contentId: 'invoice-info',
  //   name: 'Invoice Info',
  //   dml: MantraInvoiceInfo,
  //   type: 'Mantra',
  //   default: {
  //     dataset: {
  //       'mantra-title-one-text': 'order number',
  //       'mantra-value-one-text': 'A80SD99',
  //       'mantra-title-two-text': 'date shipped',
  //       'mantra-value-two-text': 'AUG 28 2019',
  //       'mantra-title-three-text': 'shipping address',
  //       'mantra-value-three-bold-text': 'FIRST NAME HERE',
  //       'mantra-value-three-text':
  //         '123, Addr&zwnj;ess line 1, Addr&zwnj;ess line 2, Land&zwnj;mark, City, St&zwnj;ate, Coun&zwnj;try &#45; Pin.',
  //     },
  //   },
  // },
  // {
  //   contentId: 'invoice-two',
  //   name: 'Invoice',
  //   dml: MantraInvoiceItems,
  //   type: 'Mantra',
  //   default: {
  //     dataset: {
  //       'mantra-header-one-text': 'Item',
  //       'mantra-header-two-text': 'Qty',
  //       'mantra-header-three-text': 'Total',
  //       'mantra-item-one-text': '@{product.item}',
  //       'mantra-item-two-text': '@{product.qty}',
  //       'mantra-item-three-text': '$@{product.amount}',
  //       'mantra-title-subtotal-text': 'Subtotal :',
  //       'mantra-title-shipping-text': 'Shipping :',
  //       'mantra-title-total-text': 'Total :',
  //       'mantra-value-subtotal-text': '$@{subtotal}',
  //       'mantra-value-shipping-text': '$@{shipping}',
  //       'mantra-value-total-text': '$@{total}',
  //     },
  //   },
  // },
  {
    contentId: 'logo',
    name: 'Logo',
    dml: MantraLogo,
    type: 'Mantra',
    default: {
      dataset: {
        'mantra-logo-imgurl-group3':
          'https://assets.opensourceemails.com/imgs/mantra/logo.png',
        'mantra-logo-alt-group3': 'LoGo Here',
        'mantra-logo-buttonurl-group2': '# Logo Url',
      },
    },
  },
  {
    contentId: 'navigation',
    name: 'Navigation',
    dml: MantraNavigation,
    type: 'Mantra',
    default: {
      dataset: {
        'mantra-nav-title-one-buttontext-group2': 'home',
        'mantra-nav-title-one-buttonurl-group2': '# home',
        'mantra-nav-title-two-buttontext-group2': 'new product',
        'mantra-nav-title-two-buttonurl-group2': '# new product',
        'mantra-nav-title-three-buttontext-group2': 'catalogue',
        'mantra-nav-title-three-buttonurl-group2': '# catalogue',
        'mantra-nav-title-four-buttontext-group2': 'contact us',
        'mantra-nav-title-four-buttonurl-group2': '# contact us',
      },
    },
  },
  {
    contentId: 'opening-text',
    name: 'Opening Text',
    dml: MantraOpeningText,
    type: 'Mantra',
    default: {
      dataset: {
        'mantra-opening-text-group1': 'Welcome @{Firstname}',
      },
    },
  },
  // {
  //   contentId: 'progress',
  //   name: 'Progress',
  //   dml: MantraProgress,
  //   type: 'Mantra',
  //   default: {
  //     dataset: {
  //       'mantra-progress-title-one-text': '1. Create Account',
  //       'mantra-progress-title-two-text': '2. Update Info',
  //       'mantra-progress-title-three-text': '3. Complete',
  //       'mantra-progress-title-one-url': '# 1.',
  //       'mantra-progress-title-two-url': '# 2.',
  //       'mantra-progress-title-three-url': '# 3.',
  //     },
  //   },
  // },
  {
    contentId: 'rating',
    name: 'Rating',
    dml: MantraRating,
    type: 'Mantra',
    default: {
      dataset: {
        'mantra-rating-title-buttontext-group2': 'Click to rate our services',
        'mantra-rating-title-buttonurl-group2': '#',
        'mantra-rating-button-one-buttontext-group2': '1',
        'mantra-rating-button-one-buttonurl-group2': '# 1',
        'mantra-rating-button-two-buttontext-group2': '2',
        'mantra-rating-button-two-buttonurl-group2': '# 2',
        'mantra-rating-button-three-buttontext-group2': '3',
        'mantra-rating-button-three-buttonurl-group2': '# 3',
        'mantra-rating-button-four-buttontext-group2': '4',
        'mantra-rating-button-four-buttonurl-group2': '# 4',
        'mantra-rating-button-five-buttontext-group2': '5',
        'mantra-rating-button-five-buttonurl-group2': '# 5',
      },
    },
  },
  {
    contentId: 'signup-inverse',
    name: 'Signup Inverse',
    dml: MantraSignupInverse,
    type: 'Mantra',
    default: {
      dataset: {
        'mantra-signupin-image-imgurl-group3':
          'https://assets.opensourceemails.com/imgs/mantra/activation.jpg',
        'mantra-signupin-image-alt-group3': 'Avatar',
        'mantra-signupin-title-text-group1': ' @First Name',
        'mantra-signupin-paragraph-text-group1':
          '"Hey Name, here’s your invite! <br /> Come check out my profile page when you have a chance. <br />You\'ll love it!"',
        'mantra-signupin-button-buttontext-group2': 'Sign Up',
        'mantra-signupin-button-buttonurl-group2': '# Sign Up',
      },
    },
  },
  {
    contentId: 'title-and-subtitle',
    name: 'Title And Subtitle',
    dml: MantraTitleAndSubtitle,
    type: 'Mantra',
    default: {
      dataset: {
        'mantra-tas-title-text-group1': 'Bold Title Text',
        'mantra-tas-subtitle-text-group1': 'Subtitle here',
      },
    },
  },
  // {
  //   contentId: 'upcoming-products',
  //   name: 'Upcoming Products',
  //   dml: MantraUpcomingProducts,
  //   type: 'Mantra',
  //   default: {
  //     dataset: {
  //       'mantra-up-title-text': 'Upcoming Products',
  //       'mantra-up-item-one-text': 'Product Name 1',
  //       'mantra-up-item-two-text': 'Product Name 2',
  //       'mantra-up-item-three-text': 'Product Name 3',
  //       'mantra-up-item-four-text': 'Product Name 4',
  //       'mantra-up-item-one-imgurl':
  //         'https://assets.opensourceemails.com/imgs/mantra/img_135X135.jpg',
  //       'mantra-up-item-two-imgurl':
  //         'https://assets.opensourceemails.com/imgs/mantra/img_135X135.jpg',
  //       'mantra-up-item-three-imgurl':
  //         'https://assets.opensourceemails.com/imgs/mantra/img_135X135.jpg',
  //       'mantra-up-item-four-imgurl':
  //         'https://assets.opensourceemails.com/imgs/mantra/img_135X135.jpg',
  //       'mantra-up-item-one-alt': 'Product 1',
  //       'mantra-up-item-two-alt': 'Product 2',
  //       'mantra-up-item-three-alt': 'Product 3',
  //       'mantra-up-item-four-alt': 'Product 4',
  //       'mantra-up-item-one-url': '# 1',
  //       'mantra-up-item-two-url': '# 2',
  //       'mantra-up-item-three-url': '# 3',
  //       'mantra-up-item-four-url': '# 4',
  //     },
  //   },
  // },
  // {
  //   contentId: 'upsell-bold',
  //   name: 'Upsell',
  //   dml: MantraUpsellBold,
  //   type: 'Mantra',
  //   default: {
  //     dataset: {
  //       'mantra-upsell-title-text': 'Enjoy 15% OFF for your birthday celebration!',
  //       'mantra-upsell-impact-text': '15% OFF',
  //     },
  //   },
  // },
];

export default AllMantraBlocks;
