import React from 'react';

const BlockDrag = (props) => {
  return (
    // <?xml version="1.0" encoding="UTF-8"?>
    <svg
      width="320"
      height="320"
      viewBox="0 0 396 396"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          x1="-34.116%"
          y1="128.755%"
          x2="116.542%"
          y2="-12.298%"
          id="a"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-37.899%"
          y1="147.918%"
          x2="830.221%"
          y2="-819.063%"
          id="b"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient x1="7.57%" y1="97.258%" x2="445.764%" y2="-390.837%" id="c">
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-1067.11%"
          y1="1418.544%"
          x2="486.074%"
          y2="-448.915%"
          id="d"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-19.508%"
          y1="2604.41%"
          x2="83.784%"
          y2="-1191.607%"
          id="e"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="2.552%"
          y1="1793.741%"
          x2="105.843%"
          y2="-2002.276%"
          id="f"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-13.997%"
          y1="2401.707%"
          x2="89.298%"
          y2="-1394.31%"
          id="g"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-2.963%"
          y1="1996.302%"
          x2="100.329%"
          y2="-1799.716%"
          id="h"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-8.478%"
          y1="2199.004%"
          x2="94.818%"
          y2="-1597.013%"
          id="i"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-604.059%"
          y1="836.389%"
          x2="949.125%"
          y2="-1031.071%"
          id="j"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-1404.694%"
          y1="176.795%"
          x2="1752.347%"
          y2="-98.388%"
          id="k"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-1560.171%"
          y1="190.345%"
          x2="1596.871%"
          y2="-84.838%"
          id="l"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-837.019%"
          y1="1091.113%"
          x2="716.095%"
          y2="-776.347%"
          id="m"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-24.804%"
          y1="2799.004%"
          x2="78.492%"
          y2="-997.013%"
          id="n"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-13.774%"
          y1="2393.599%"
          x2="89.517%"
          y2="-1402.418%"
          id="o"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-19.289%"
          y1="2596.302%"
          x2="84.007%"
          y2="-1199.716%"
          id="p"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-528.464%"
          y1="769.472%"
          x2="993.827%"
          y2="-1057.151%"
          id="q"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="36.962%"
          y1="497.354%"
          x2="145.298%"
          y2="-3219.359%"
          id="r"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="13.868%"
          y1="1289.415%"
          x2="122.21%"
          y2="-2427.298%"
          id="s"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="31.192%"
          y1="695.404%"
          x2="139.529%"
          y2="-3021.309%"
          id="t"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="19.643%"
          y1="1091.504%"
          x2="127.979%"
          y2="-2625.348%"
          id="u"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="25.418%"
          y1="893.454%"
          x2="133.759%"
          y2="-2823.259%"
          id="v"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-74.897%"
          y1="199.931%"
          x2="1447.325%"
          y2="-1626.691%"
          id="w"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-595.258%"
          y1="68.883%"
          x2="2500.139%"
          y2="-21.716%"
          id="x"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-371.688%"
          y1="62.344%"
          x2="2723.71%"
          y2="-28.258%"
          id="y"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-818.828%"
          y1="75.429%"
          x2="2276.569%"
          y2="-15.17%"
          id="z"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-493.004%"
          y1="676.18%"
          x2="1029.218%"
          y2="-1150.375%"
          id="A"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-16.777%"
          y1="2340.669%"
          x2="91.565%"
          y2="-1376.045%"
          id="B"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-11.002%"
          y1="2142.758%"
          x2="97.334%"
          y2="-1574.095%"
          id="C"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-5.227%"
          y1="1944.708%"
          x2="103.114%"
          y2="-1772.006%"
          id="D"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1=".547%"
          y1="1746.657%"
          x2="108.884%"
          y2="-1970.056%"
          id="E"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="6.317%"
          y1="1548.607%"
          x2="114.658%"
          y2="-2168.106%"
          id="F"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-946.502%"
          y1="1245.72%"
          x2="575.72%"
          y2="-580.834%"
          id="G"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-1808.647%"
          y1="104.401%"
          x2="1286.75%"
          y2="13.803%"
          id="H"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-1361.506%"
          y1="91.314%"
          x2="1733.891%"
          y2=".715%"
          id="I"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-1585.077%"
          y1="97.856%"
          x2="1510.321%"
          y2="7.257%"
          id="J"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="1504.694%"
          y1="176.805%"
          x2="-1652.347%"
          y2="-98.378%"
          id="K"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="1660.028%"
          y1="190.345%"
          x2="-1497.013%"
          y2="-84.849%"
          id="L"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="937.019%"
          y1="1091.113%"
          x2="-616.165%"
          y2="-776.347%"
          id="M"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="113.774%"
          y1="2393.599%"
          x2="10.478%"
          y2="-1402.418%"
          id="N"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="119.289%"
          y1="2596.302%"
          x2="15.993%"
          y2="-1199.716%"
          id="O"
        >
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
        <linearGradient x1="9.807%" y1="145.912%" x2="94.17%" y2="-55.4%" id="P">
          <stop stopColor="#9CCEFA" offset="0%" />
          <stop stopColor="#458FDD" offset="100%" />
        </linearGradient>
      </defs>
      <g transform="translate(74 60)" fillRule="nonzero" fill="none">
        <path fill="url(#a)" opacity=".3" d="M79.78 20.87h177.13v187.69H79.78z" />
        <circle fill="url(#b)" opacity=".3" cx="75.33" cy="276.58" r="15.37" />
        <circle fill="url(#c)" opacity=".3" cx="31.01" cy="239.17" r="30.45" />
        <path
          fill="url(#d)"
          d="M224.22 78.82h14.29l-.01-14.29h-7.02v7.26l-7.26.01v7.02z"
        />
        <path fill="url(#e)" d="M195.25 71.8h21.98v7.03h-21.98z" />
        <path fill="url(#f)" d="M79.36 71.83h21.98v7.03H79.36z" />
        <path fill="url(#g)" d="M166.28 71.81h21.98v7.03h-21.98z" />
        <path fill="url(#h)" d="M108.33 71.83h21.98v7.03h-21.98z" />
        <path fill="url(#i)" d="M137.3 71.82h21.98v7.03H137.3z" />
        <path fill="url(#j)" d="M65.1 64.58h-7.02v14.29h14.29v-7.03h-7.26z" />
        <path fill="url(#k)" d="M58.07 42.36h7.03v16.86h-7.03z" />
        <path fill="url(#l)" d="M58.07 20.13h7.03v16.86h-7.03z" />
        <path fill="url(#m)" d="M65.09 14.76V7.5h7.26L72.34.47 58.06.48v14.28z" />
        <path fill="url(#n)" d="M137.28.45h21.98v7.03h-21.98z" />
        <path fill="url(#o)" d="M79.34.47h21.98V7.5H79.34z" />
        <path fill="url(#p)" d="M108.31.46h21.98v7.03h-21.98z" />
        <path
          fill="url(#q)"
          d="M231.58 224.95h-7.41v7.18h14.58v-14.59h-7.17v7.41z"
        />
        <path fill="url(#r)" d="M79.56 224.95h21.94v7.18H79.56z" />
        <path fill="url(#s)" d="M195.25 224.95h21.94v7.18h-21.94z" />
        <path fill="url(#t)" d="M108.48 224.95h21.94v7.18h-21.94z" />
        <path fill="url(#u)" d="M166.33 224.95h21.94v7.18h-21.94z" />
        <path fill="url(#v)" d="M137.4 224.95h21.94v7.18H137.4z" />
        <path fill="url(#w)" d="M65.17 217.54H58v14.59h14.58v-7.18h-7.41z" />
        <path fill="url(#x)" d="M58 152.33h7.17v24.74H58z" />
        <path fill="url(#y)" d="M58 184.93h7.17v24.74H58z" />
        <path fill="url(#z)" d="M58 119.72h7.17v24.74H58z" />
        <path fill="url(#A)" d="M58 111.85h7.17v-7.41h7.41v-7.18H58z" />
        <path fill="url(#B)" d="M195.25 97.26h21.94v7.18h-21.94z" />
        <path fill="url(#C)" d="M166.33 97.26h21.94v7.18h-21.94z" />
        <path fill="url(#D)" d="M137.4 97.26h21.94v7.18H137.4z" />
        <path fill="url(#E)" d="M108.48 97.26h21.94v7.18h-21.94z" />
        <path fill="url(#F)" d="M79.56 97.26h21.94v7.18H79.56z" />
        <path fill="url(#G)" d="M224.17 104.44h7.41v7.41h7.17V97.26h-14.58v7.18z" />
        <path fill="url(#H)" d="M231.58 119.72h7.17v24.74h-7.17z" />
        <path fill="url(#I)" d="M231.58 184.93h7.17v24.74h-7.17z" />
        <path fill="url(#J)" d="M231.58 152.33h7.17v24.74h-7.17z" />
        <path fill="url(#K)" d="M231.6 42.28h7.03v16.86h-7.03z" />
        <path fill="url(#L)" d="M231.61 20.06h7.03v16.86h-7.03z" />
        <path fill="url(#M)" d="M231.61 14.69V7.43h-7.26l.01-7.03h14.28v14.29z" />
        <path fill="url(#N)" d="M195.38.4h21.98v7.03h-21.98z" />
        <path fill="url(#O)" d="M166.41.39h21.98v7.03h-21.98z" />
        <path fill="url(#P)" d="M210.45 67.58H29.84v141.75h187.65V67.58z" />
        <circle fill="#FFF" opacity=".3" cx="168.58" cy="118.87" r="24.37" />
        <path
          d="M186.9 170.62H69.29a3.3 3.3 0 1 1 0-6.59H186.9a3.3 3.3 0 0 1 0 6.59zm-60.06-26.45H69.29a3.3 3.3 0 1 1 0-6.59h57.55a3.3 3.3 0 1 1 0 6.59zm0-26.25H69.06a3.5 3.5 0 1 1 0-7h57.78a3.5 3.5 0 1 1 0 7z"
          fill="#FFF"
          opacity=".3"
        />
        <path fill="#FFF" d="M119.59 143.61l10.7 51.55 12.01-17.87 21.07-4.5z" />
      </g>
    </svg>
  );
};

export default BlockDrag;
