import styled from 'styled-components';

export const TemplatesSectionContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const TemplatesContainer = styled.div`
  max-width: 400px;
  margin: 5px;
  border: 1px solid #ddd;
  &:hover {
    box-shadow: rgba(120, 120, 120, 0.5) 0px 6px 32px -2px;
    cursor: pointer;
  }
`;

export const TemplateName = styled.p`
  font-weight: 500;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.4px;
  align-text: center;
  text-align: center;
  padding: 20px 5px 5px 5px;
`;

export const TemplateImage = styled.img``;

export const Template = styled.div`
  border-top: 1px solid #ddd;
  margin: 0px;
  user-select: none;
  padding: 0.5rem;
  align-items: flex-start;
  align-content: flex-start;
  line-height: 1.5;
  background: #fff;
  transition: 0.5s;
  overflow: hidden;
`;

export const TemplateSelectionScreenContainer = styled.div``;

export const StartFromScratchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 30px;
  font-family: Roboto, sans-serif;
`;
