const GreyParagraph = `  
<dys-block>   
  <dys-row>
    <dys-column>
      <dys-text align='center' font-family='Open Sans, Arial, sans-serif' font-size='15px' line-height='22px' color='#999999' editable='grey-paragraph'>
        {{{ mantra-grey-paragraph-text-group1 }}}
      </dys-text>
    </dys-column>
  </dys-row>
</dys-block>
`;

export default GreyParagraph;
