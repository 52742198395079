import React from 'react';

const PadLockIcon = (props) => {
  return (
    <svg width="8"
      height="11"
      viewBox="0 0 8 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M7 4H6.5V3C6.5 1.62 5.38 0.500004 4 0.500004C2.62 0.500004 1.5 1.62 1.5 3V4H1C0.45 4 0 4.45 0 5V10C0 10.55 0.45 11 1 11H7C7.55 11 8 10.55 8 10V5C8 4.45 7.55 4 7 4ZM4 8.5C3.45 8.5 3 8.05 3 7.5C3 6.95 3.45 6.5 4 6.5C4.55 6.5 5 6.95 5 7.5C5 8.05 4.55 8.5 4 8.5ZM5.55 4H2.45V3C2.45 2.145 3.145 1.45 4 1.45C4.855 1.45 5.55 2.145 5.55 3V4Z" fill="#464646" />
    </svg>

  );
};

export default PadLockIcon;
