import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Italic } from './../../../assets/icons/svg/Italic.svg';
import { ReactComponent as Underline } from './../../../assets/icons/svg/Underline.svg';
import { ReactComponent as Bold } from './../../../assets/icons/svg/Bold.svg';
import { ReactComponent as Link } from './../../../assets/icons/svg/Link.svg';

import {
  BlueButtonWhiteText,
  WhiteButtonBlueBorderAndText,
} from './../../../styles/Buttons/ButtonStyles';

const ToolbarWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 32px);
  grid-gap: 4px;
  padding: 0 4px;
  align-items: center;
  width: 100%;
  transition: opacity 0.15s linear, transform 0.15s linear, height 0.15s linear;
  opacity: 0;
  overflow: hidden;
  z-index: 5;
  height: 0;

  .RichTextEditor.isOpen ~ &,
  .RichTextEditor:not(.isOpen):focus ~ & {
    opacity: 1;
    transform: translateY(0);
    height: 40px;
  }
`;

const ToolbarBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  opacity: ${(props) => (props.isActive ? '1' : '0.3')};
  cursor: pointer;
  :focus {
    outline: none;
  }
  :hover {
    background: #ffffff;
  }
  > svg {
    width: 16px;
    height: auto;
  }
`;

export const Toolbar = ({ onClickMark, onClickLink, hasMark }) => {
  return (
    <ToolbarWrap>
      <ToolbarBtn
        isActive={hasMark('bold')}
        onMouseDown={(e) => {
          onClickMark(e, 'bold');
        }}
      >
        <Bold />
      </ToolbarBtn>
      <ToolbarBtn
        isActive={hasMark('italic')}
        onMouseDown={(e) => {
          onClickMark(e, 'italic');
        }}
      >
        <Italic />
      </ToolbarBtn>
      <ToolbarBtn
        isActive={hasMark('underline')}
        onMouseDown={(e) => {
          onClickMark(e, 'underline');
        }}
      >
        <Underline />
      </ToolbarBtn>
      <ToolbarBtn
        style={{ marginLeft: 8 }}
        isActive={hasMark('link')}
        onMouseDown={(e) => {
          onClickLink(e);
        }}
      >
        <Link />
      </ToolbarBtn>
    </ToolbarWrap>
  );
};

const LinkWrapper = styled.div`
  position: relative;
  width: 360px;
  padding: 32px;
  padding: 4px 8px;
  margin: 0 auto;
  z-index: 5;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background-color: #fff;
  font-family: Roboto, sans-serif;
`;

const LinkTooltip = styled(LinkWrapper)(({ arrowDir }) => ({
  padding: '32px 16px',
  filter: 'drop-shadow(0 0 4px rgba(0, 0, 0, 0.3))',
  boxShadow: 'none',
  ...(arrowDir !== '' && {
    '&:after': {
      content: '""',
      position: 'absolute',
      ...(arrowDir === 'top'
        ? {
            top: '-16px',
            borderColor: 'transparent transparent #fff transparent',
          }
        : {
            bottom: '-16px',
            borderColor: '#fff transparent transparent transparent',
          }),
      left: '16px',
      borderWidth: '8px',
      borderStyle: 'solid',
    },
  }),
}));

const LinkActions = styled.div`
  display: grid;
  grid-gap: 8px;
  justify-content: space-between;
  grid-auto-flow: column;
`;

const LinkItem = styled.a`
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.4px;
  color: #1F76D3;
  padding-right: 8px;
  text-overflow: ellipsis;
  flex: 1 1 0%;
  overflow: hidden;
`;

const BtnLink = styled.button`
  display: inline;
  padding: 0;
  border: none;
  cursor: pointer;
  background: transparent;
  :hover {
    color: #1F76D3;
  }
  > svg {
    height: 12px;
    width: auto;
  }
`;

const LinkActionBtn = styled(BtnLink)`
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 0.4px;
  color: #1F76D3;
  display: inline;
  cursor: pointer;
  padding: 0px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  background: transparent;
  outline: none;
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const LinkPreview = ({ link, onRemove, onModify }) => {
  return (
    <LinkWrapper>
      <ActionsWrapper
        style={{
          padding: '5px',
        }}
      >
        <LinkItem href={link} target="_blank">
          {link}
        </LinkItem>
        <LinkActions>
          <LinkActionBtn onClick={onRemove}>Remove</LinkActionBtn>
          <LinkActionBtn onClick={onModify}>Modify</LinkActionBtn>
        </LinkActions>
      </ActionsWrapper>
    </LinkWrapper>
  );
};

const LabelWrapper = styled.label`
  position: relative;
  width: 100%;
  display: block;
  padding-top: ${({ hasLabel }) => (hasLabel ? 16 : 0)}px;
  padding-bottom: 4px;
  text-align: left;
  font-family: Roboto, sans-serif;
`;

const convertHexToRgba = (hex, opacity = 1) => {
  const hexNum = hex.replace(/#/i, '');
  const r = parseInt(hexNum.slice(0, 2), 16);
  const g = parseInt(hexNum.slice(2, 4), 16);
  const b = parseInt(hexNum.slice(4, 6), 16);
  return `rgba(${r},${g},${b}, ${opacity})`;
};

const InputBase = styled('input')(({ hasError = false, hasLabel = false }) => ({
  width: '95%',
  display: 'block',
  height: 40,
  border: 'none',
  fontSize: '14px',
  lineHeight: '32px',
  fontWeight: 400,
  color: hasError ? '#ff4242' : '#464646',
  backgroundColor: hasError ? convertHexToRgba('#ff4242', 0.1) : 'transparent',
  transition: 'all 0.2s ease-out',
  padding: '0 8px',
  boxShadow: `0 2px 2px -2px ${'#aeaeae'}, 0 0 0 0 ${convertHexToRgba(
    '#ff4242',
    0.8
  )}`,
  '&::placeholder': {
    opacity: hasLabel ? 0 : 0.4,
    color: '#464646',
  },
  '&:focus': {
    outline: 'none',
    boxShadow: hasError
      ? `0 0 0 ${'#aeaeae'}, 0 0 0 2px ${convertHexToRgba('#ff4242', 0.5)}`
      : undefined,
    '&::placeholder': {
      opacity: 0.4,
    },
  },
  '&[readonly], &[disabled]': {
    background: '#464646',
    color: '#aeaeae',
  },
}));

const LabelContent = styled('div')(({ hasContent, hasError }) => ({
  position: 'absolute',
  height: `${40}px`,
  display: 'block',
  top: '16px',
  width: '100%',
  padding: '1px 8px',
  lineHeight: `${40}px`,
  fontSize: hasContent ? '13px' : '14px',
  textTransform: 'uppercase',
  fontWeight: 500,
  letterSpacing: '0.8px',
  color: hasError ? '#ff4242' : hasContent ? '#787878' : '#aeaeae',
  pointerEvents: 'none',
  transition: 'all 0.2s ease-out',
  transformOrigin: '0 0',
  transform: hasContent
    ? `translateY(-${40 / 2 + 10}px) translateX(-8px)`
    : undefined,
  'input:focus ~ &': {
    transform: `translateY(-${40 / 2 + 10}px) translateX(-8px)`,
    color: '#787878',
    fontSize: '13px',
  },
}));

// show an animated blue line on input focus, needs a sep element for animation
const InputBorder = styled('div')`
  position: absolute;
  top: ${({ hasLabel }) => (hasLabel ? 40 + 16 : 40)}px;
  left: 0;
  width: 100%;
  overflow: hidden;
  height: 3px;

  &:after {
    content: ' ';
    position: absolute;
    display: block;
    bottom: 1px;
    width: 100%;
    left: 0%;
    height: 3px;
    transition: all 0.2s ease-out;
    background: ${({ hasError }) => (hasError ? 'transparent' : '#1F76D3')};

    input:not(:focus) ~ & {
      opacity: 0.3;
      transform: scaleX(0);
    }
    input:focus ~ & {
      opacity: 1;
      transform: scaleX(100%);
    }
  }
`;

const InputWithLabel = ({
  hasError,
  forwardedRef = null,
  inputProps,
  labelProps,
  ...props
}) => {
  const hasContent = !!inputProps().value;
  return (
    <LabelWrapper hasLabel={!!props.label}>
      <InputBase
        hasLabel={!!props.label}
        ref={forwardedRef ? forwardedRef : null}
        hasError={hasError}
        autoComplete={inputProps().autoComplete ? inputProps().autoComplete : 'off'}
        {...inputProps()}
      />
      {props.label && (
        <LabelContent hasContent={hasContent} hasError={hasError} {...labelProps()}>
          {props.label}
        </LabelContent>
      )}
      {!inputProps().noHighlight && <InputBorder hasLabel={!!props.label} />}
    </LabelWrapper>
  );
};

export const LinkEditor = ({
  link,
  text,
  arrowDir,
  onLinkTextChange,
  onLinkHrefChange,
  onApply,
  onCancel,
}) => {
  return (
    <LinkTooltip arrowDir={arrowDir}>
      <InputWithLabel
        label="Text"
        labelProps={() => ({})}
        inputProps={() => ({
          name: 'text',
          maxLength: '100',
          onChange: (e) => {
            onLinkTextChange(e.currentTarget.value);
          },
          value: text,
          disabled: false,
          style: { marginBottom: 16 },
        })}
        hasError={false}
      />
      <InputWithLabel
        label="Link"
        labelProps={() => ({})}
        inputProps={() => ({
          name: 'href',
          maxLength: '100',
          onChange: (e) => {
            onLinkHrefChange(e.currentTarget.value);
          },
          value: link,
          disabled: false,
        })}
        hasError={false}
      />
      <ActionsWrapper
        style={{
          padding: '20px 20px 0 20px',
        }}
      >
        <WhiteButtonBlueBorderAndText isAllowed={true} onClick={onCancel}>
          Cancel
        </WhiteButtonBlueBorderAndText>
        <BlueButtonWhiteText isAllowed={true} onClick={onApply}>
          Apply
        </BlueButtonWhiteText>
      </ActionsWrapper>
    </LinkTooltip>
  );
};
