const Logo = `
<dys-block>  
  <dys-row padding='10px'>
    <dys-column>
      <dys-img href='{{ mantra-logo-buttonurl-group2 }}' src='{{ mantra-logo-imgurl-group3 }}' width='230px' height='80px' border='0' alt='{{ mantra-logo-alt-group3 }}' editable='logo-image' />
    </dys-column>
  </dys-row>
</dys-block>
`;

export default Logo;
