import React from 'react';

export const wrapLink = (editor, href) => {
  editor.wrapInline({
    type: 'link',
    data: { href },
  });

  editor.moveToEnd();
};

export const unwrapLink = (editor) => {
  editor.unwrapInline('link');
};

export const isOnlyLink = (value) => {
  // every inline link node will have one text node, within one block node
  const hasOneLink =
    value.inlines.size === 1 &&
    value.inlines.some((inline) => inline.type === 'link');
  const oneBlockNode = value.blocks.size === 1;
  const oneTextNode = value.texts.size === 1;

  return hasOneLink && oneBlockNode && oneTextNode;
};

export class DetectOutsideClick extends React.PureComponent {
  clickRef;

  constructor(props) {
    super(props);
    this.clickRef = React.createRef();
  }

  UNSAFE_componentWillMount() {
    document.addEventListener('mousedown', this.handle, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handle, false);
  }

  handle = (e) => {
    if (this.clickRef.current.contains(e.target) === false) {
      this.props.onOutsideClick();
    }
  };

  render() {
    // Pull out the event handler prop and only pass on HTML Attribute props to the div
    const { onOutsideClick, ...props } = this.props;
    return (
      <div {...props} ref={this.clickRef}>
        {this.props.children}
      </div>
    );
  }
}
