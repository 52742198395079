const Subhead = `
<dys-block>
  <dys-row padding="10px 20px">
    <dys-column>
      <dys-text font-size="24px" line-height="32px" font-weight="Bolder" editable="lifestyle-subheader">
        Subhead Text
      </dys-text>
    </dys-column>
  </dys-row>
</dys-block>
`;

export default Subhead;
