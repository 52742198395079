const Header = `
<dys-block>
  <dys-row>
    <dys-column>
      <dys-text
        font-size="30px"
        color="#37302D"
        align="center"
        font-family="'Droid Sans', 'Helvetica Neue', Arial, sans-serif"
        editable="header-text"
      >
        Awesome Co
      </dys-text>
    </dys-column>
  </dys-row>
</dys-block>
`;

export default Header;
