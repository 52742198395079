const Footer = `
<dys-block>
  <dys-row full-width='full-width' background-color='#f7f7f7'>
    <dys-column>
      <dys-text color='#777777' line-height='21px' font-weight='700' font-size='14px' align='center' font-family='Oxygen, Helvetica neue, sans-serif' font-style='bold' padding='5px 25px' align='center' editable='footer-company'>
        Awesome Inc
      </dys-text>
      <dys-text color='#777777' font-size='14px' align='center' font-family='Oxygen, Helvetica neue, sans-serif' font-style='bold' padding='5px 25px' align='center' padding='5px 25px' align='center' editable='footer-address'>
      1234 Awesome St 
        </dys-text>
      <dys-text color='#777777' font-size='14px' align='center' font-family='Oxygen, Helvetica neue, sans-serif' font-style='bold' padding='5px 25px' align='center' padding='5px 25px' align='center' editable='footer-title'>
      Wonderland 
      </dys-text>
    </dys-column>
  </dys-row>
</dys-block>
`;

export default Footer;
