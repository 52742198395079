import styled from 'styled-components';

export const BlueButtonWhiteText = styled.a`
  cursor: ${(props) => (props.isAllowed ? 'pointer' : 'default')};
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  min-width: 96px;
  font-weight: 400;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.4px;
  text-decoration: none;
  color: ${(props) => (props.isAllowed ? 'rgb(255, 255, 255)' : '#AEAEAE')} !important;
  background-color: ${(props) => (props.isAllowed ? '#1F76D3' : '#EDEDED')};
  height: 40px;
  font-size: 14px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  transition: background 0.2s ease 0s;
  border-color: ${(props) => (props.isAllowed ? '#1F76D3' : '#EDEDED')};
  padding: 0px 17px;
`;

export const WhiteButtonBlueBorderAndText = styled.a`
  cursor: ${(props) => (props.isAllowed ? 'pointer' : 'not-allowed')};
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  min-width: 96px;
  font-weight: 400;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.4px;
  color: #1F76D3;
  opacity: ${(props) => (props.isAllowed ? '1' : '0.5')};
  background-color: rgb(255, 255, 255);
  height: 31px;
  font-size: 14px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  text-decoration: none;
  transition: background 0.2s ease 0s;
  border-color: #1F76D3;
  padding: 0px 7px;
  :hover {
    background-color: ${(props) => (props.isAllowed ? '#1B63B1' : '')};
    color: ${(props) => (props.isAllowed ? 'rgb(255, 255, 255)' : '')};
    border-color: ${(props) => (props.isAllowed ? '#1B63B1' : '')};
  }
`;

export const GoBackButton = styled.span`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  align-self: stretch;
  min-width: 0px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  padding: 0px 24px;
  color: #1F76D3;
  font-size: 18px;
  letter-spacing: 0.4px;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;
  border-image: initial;
  background: transparent;
  transition: all 0.2s ease 0s;
  :hover {
    color: #1B63B1;
    background-color: rgb(248, 248, 248);
  }
  :focus {
    background-color: rgb(248, 248, 248);
    outline: none;
  }
  svg {
    margin-right: 0.625em;
  }
`;

export const BannerButton = styled.a`
  background-color: #8DEDE8;
  color: #0D355E;
  border-radius: 4px;
  border-color: #0D355E;
  border: #0D355E 1px solid;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 15px;
  line-height: 18px;
  padding: 12px 25px;
  text-transform: uppercase;
  text-decoration: none;
  :hover {
    background-color: #0D355E;
    cursor: pointer;
    text-decoration: none;
    color: #8DEDE8;
  }
`;
 
// Template Selection Page > Build Your Own > Get Started
export const GetStartedButton = styled.span`
background-color: transparent;
color: #1F76D3;
border-radius: 4px;
border-color: #1F76D3;
border: #1F76D3 1px solid;
font-family: Roboto, sans-serif;
font-weight: 400;
font-size: 16px;
line-height: 16px;
padding: 12px 16px;
text-decoration: none;
text-align: auto;
display: inline-block;
:hover {
  background-color: #1F76D3;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
}
`;