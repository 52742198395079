const ImagesFeed = `

<dys-block>
  <dys-row background-color='#FFFFFF' padding-bottom='0px'>
    <dys-column padding='0px'>
      <dys-text padding-bottom='5px' color='#4d4d4d' line-height='30px' font-weight='700' font-size='24px' align='center' font-family='Oxygen, Helvetica neue, sans-serif' editable='Title'>
        Lorem ipsum dolor 
      </dys-text>      
      <dys-text padding='0px' color='#777777' line-height='21px' font-size='14px' align='center' font-family='Oxygen, Helvetica neue, sans-serif' editable='another'>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      </dys-text>
    </dys-column>
  </dys-row>
  <dys-row padding-bottom='0px'>
    <dys-column>
      <dys-img padding='5px' border-radius='5px' width='140px' src='https://assets.opensourceemails.com/imgs/oxygen/u3EYVdcIQo6dZiKefuLu_bracelet.jpg' editable="oxygen-image-feed-one" />
    </dys-column>
    <dys-column>
      <dys-img padding='5px' border-radius='5px' width='140px' src='https://assets.opensourceemails.com/imgs/oxygen/yyN7crRfTTW6l8fO0S0S_hat.jpg' editable="oxygen-image-feed-two" />
    </dys-column>
    <dys-column>
      <dys-img padding='5px' border-radius='5px' width='140px' src='https://assets.opensourceemails.com/imgs/oxygen/9wRy50HQTg2CTyZA5Ozi_item_images_16.jpg' editable="oxygen-image-feed-three" />
    </dys-column>
    <dys-column>
      <dys-img padding='5px' border-radius='5px' width='140px' src='https://assets.opensourceemails.com/imgs/oxygen/RPezUIwPRv8pjatAAH1E_item_images_19.jpg' editable="oxygen-image-feed-four" />
    </dys-column>    
  </dys-row>
  
  <dys-row border-bottom='1px solid #e5e5e5' padding-top='0px'>
    <dys-column>
      <dys-img padding='5px' border-radius='5px' width='140px' src='https://assets.opensourceemails.com/imgs/oxygen/DXWZ4PzwQUGI0wQoABDt_jacket.jpg' editable="oxygen-image-feed-five" />
    </dys-column>
    <dys-column>
      <dys-img padding='5px' border-radius='5px' width='140px' src='https://assets.opensourceemails.com/imgs/oxygen/RPezUIwPRv8pjatAAH1E_item_images_19.jpg' editable="oxygen-image-feed-six" />
    </dys-column>
    <dys-column>
      <dys-img padding='5px' border-radius='5px' width='140px' src='https://assets.opensourceemails.com/imgs/oxygen/DXWZ4PzwQUGI0wQoABDt_jacket.jpg' editable="oxygen-image-feed-seven" />
    </dys-column>
    <dys-column>
      <dys-img padding='5px' border-radius='5px' width='140px' src='https://assets.opensourceemails.com/imgs/oxygen/u3EYVdcIQo6dZiKefuLu_bracelet.jpg' editable="oxygen-image-feed-eight" />
    </dys-column>    
  </dys-row>  
</dys-block>
`;

export default ImagesFeed;
