import OxygenInvite from './../../../assets/images/Oxygen/OxygenInvite.png';
import OxygenOrderShipped from './../../../assets/images/Oxygen/OxygenOrderShipped.png';
import OxygenOrderSummary from './../../../assets/images/Oxygen/OxygenOrderSummary.png';

const OxygenTemplates = [
  {
    alt: 'Order Shipped Template',
    name: 'Order Shipped',
    src: OxygenOrderShipped,
  },
  {
    alt: 'Order Summary Template',
    name: 'Order Summary',
    src: OxygenOrderSummary,
  },
  {
    alt: 'Invite Template',
    name: 'Invite',
    src: OxygenInvite,
  },
];

export default OxygenTemplates;
