const ProductCard = `
<dys-block>
  <dys-row background-color='#ffffff'>
    <dys-column padding='0px' border-radius='5px' width='45%' background-color='#ffffff' background-color='#ffffff' border='1px solid #e5e5e5'>
      <dys-img fluid-on-mobile='true' src='https://assets.opensourceemails.com/imgs/oxygen/9wRy50HQTg2CTyZA5Ozi_item_images_16.jpg' padding='0px' editable="oxygen-productcard-image-one"/>

      <dys-table padding='15px 15px 0px 15px' color='#4d4d4d' line-height='25px' font-weight='700' font-size='18px' align='left' font-family='Oxygen, Helvetica neue, sans-serif'>
        <tr> 
          <td style='color: #4d4d4d; font-family: Oxygen, Helvetica neue, sans-serif; font-size: 18px; font-weight: 700; line-height: 25px; text-align: left;'><span editable='title1'> Running Shoes </span></td> 
          <td align='right' style='    color: #4d4d4d; font-family: Oxygen, Helvetica neue, sans-serif; font-size: 14px; font-weight: 600; line-height: 25px; text-align: right;'><span editable='price1'> $29.99 </span></td>
        </tr> 
      </dys-table> 
      <dys-text padding='0px 15px 10px 15px' color='#777777' line-height='23px' font-size='14px' align='left' font-family='Oxygen, Helvetica neue, sans-serif' editable='description'>
        The best summer sneakers are in!	
      </dys-text>    


      <dys-button line-height='21px' width='100%' font-family='Oxygen, Helvetica neue, sans-serif' font-weight='400' border-radius='5px' font-size='14px' background-color='#ff6f6f' editable='button'>
        View Now!
      </dys-button>       
    </dys-column>

    <dys-column padding='10px' background-color='#FFFFFF' width='5%'></dys-column>

    <dys-column border-radius='1px' width='45%' background-color='#ffffff' background-color='#ffffff' border='1px solid #e5e5e5'>
      <dys-img fluid-on-mobile='true' src='https://assets.opensourceemails.com/imgs/oxygen/RPezUIwPRv8pjatAAH1E_item_images_19.jpg' padding='0px' editable="oxygen-productcard-image-two" />

      <dys-table padding='15px 15px 0px 15px' color='#4d4d4d' line-height='25px' font-weight='700' font-size='18px' align='left' font-family='Oxygen, Helvetica neue, sans-serif'>
        <tr> 
          <td style='color: #4d4d4d; font-family: Oxygen, Helvetica neue, sans-serif; font-size: 18px; font-weight: 700; line-height: 25px; text-align: left;'><span editable='title2'> Golden Earrings </span></td> 
          <td align='right' style='    color: #4d4d4d; font-family: Oxygen, Helvetica neue, sans-serif; font-size: 14px; font-weight: 600; line-height: 25px; text-align: right;'><span editable='price2'> $29.99 </span></td>
        </tr> 
      </dys-table> 
      <dys-text padding='0px 15px 10px 15px' color='#777777' line-height='23px' font-size='14px' align='left' font-family='Oxygen, Helvetica neue, sans-serif' editable='description2'>
        New city looks! <br /> <br />	
      </dys-text>    


      <dys-button line-height='21px' width='100%' font-family='Oxygen, Helvetica neue, sans-serif' font-weight='400' border-radius='5px' font-size='14px' background-color='#ff6f6f' editable='button2'>
        View Now!      
      </dys-button>       
    </dys-column>  
  </dys-row>
</dys-block>
`;

export default ProductCard;
