const Image = `
<dys-block>  
  <dys-row>
    <dys-column>
      <dys-img src='{{ mantra-image-imgurl-group3 }}' width='333px' height='303px' alt='{{ mantra-image-alt-group3 }}' border='0' editable='hero-image' />
    </dys-column>
  </dys-row>
</dys-block>
`;

export default Image;
