const Footer = `
<dys-block>  
  <dys-row background-color='#30373b' padding='2px' full-width='full-width'>
    <dys-column padding='10px'>

      <!-- Social Icons -->
      <dys-table>
        <tr>
          <td valign='top' align='center'>
            <table border='0' cellspacing='0' cellpadding='0' align='center'>
              <tr>
                <td valign='top'><a href='{{ mantra-social-1-buttonurl-group2 }}' target='_blank' style='text-decoration:none;' editable='social-1'><img src='{{ mantra-social-1-imgurl-group3 }}' width='26' height='26' style='display:block;font-family: Arial, sans-serif; font-size:10px; line-height:18px; color:#feae39; ' border='0' alt='{{ mantra-social-1-alt-group3 }}' /></a></td>
                <td width='7'>&nbsp;</td>
                <td valign='top'><a href='{{ mantra-social-2-buttonurl-group2 }}' target='_blank' style='text-decoration:none;' editable='social-2'><img src='{{ mantra-social-2-imgurl-group3 }}' width='26' height='26' style='display:block;font-family: Arial, sans-serif; font-size:10px; line-height:18px; color:#feae39; ' border='0' alt='{{ mantra-social-2-alt-group3 }}' /></a></td>
                <td width='7'>&nbsp;</td>
                <td valign='top'><a href='{{ mantra-social-3-buttonurl-group2 }}' target='_blank' style='text-decoration:none;' editable='social-3'><img src='{{ mantra-social-3-imgurl-group3 }}' width='26' height='26' style='display:block;font-family: Arial, sans-serif; font-size:10px; line-height:18px; color:#feae39; ' border='0' alt='{{ mantra-social-3-alt-group3 }}' /></a></td>
                <td width='7'>&nbsp;</td>
                <td valign='top'><a href='{{ mantra-social-4-buttonurl-group2 }}' target='_blank' style='text-decoration:none;' editable='social-5'><img src='{{ mantra-social-4-imgurl-group3 }}' width='26' height='26' style='display:block;font-family: Arial, sans-serif; font-size:10px; line-height:18px; color:#feae39; ' border='0' alt='{{ mantra-social-4-alt-group3 }}' /></a></td>
                <td width='7'>&nbsp;</td>
                <td valign='top'><a href='{{ mantra-social-5-buttonurl-group2 }}' target='_blank' style='text-decoration:none;' editable='social-6'><img src='{{ mantra-social-5-imgurl-group3 }}' width='26' height='26' style='display:block;font-family: Arial, sans-serif; font-size:10px; line-height:18px; color:#feae39; ' border='0' alt='{{ mantra-social-5-alt-group3 }}' /></a></td>
              </tr>
            </table>
          </td>
        </tr>
      </dys-table>
      <!-- End Social Icons -->

      <!-- Footer Content -->
      <dys-text padding='5px' align='center' font-family='Open Sans, Arial, sans-serif' font-size='12px' line-height='18px' color='#848789' text-transform='uppercase'>
        <a href='{{ mantra-privacy-buttonurl-group2 }}' target='_blank' style='text-decoration:underline; color:#848789;' editable='footer-1'>{{{ mantra-privacy-buttontext-group2 }}}</a> &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href='{{ mantra-tos-buttonurl-group2 }}' target='_blank' style='text-decoration:underline; color:#848789;' editable='footer-2'>{{{ mantra-tos-buttontext-group2 }}}</a> &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href='{{ mantra-returns-buttonurl-group2 }}' target='_blank' style='text-decoration:underline; color:#848789;' editable='footer-3'>{{{ mantra-returns-buttontext-group2 }}}</a> 
      </dys-text>

      <dys-text padding='5px' align='center' font-family='Open Sans, Arial, sans-serif' font-size='12px' line-height='18px' color='#848789' text-transform='uppercase' editable='footer-company'>
        {{{ mantra-reserved-text-group1 }}}  
      </dys-text>

      <dys-text padding='5px' align='center' font-family='Open Sans, Arial, sans-serif' font-size='12px' line-height='18px' color='#848789' text-transform='uppercase' editable='footer-disclaimer'>
        {{{ mantra-unsub-1-text-group1 }}} <span style='text-decoration:underline;'><a href='{{ mantra-unsub-buttonurl-group2 }}' target='_blank' style='text-decoration:underline; color:#848789;'>{{{ mantra-unsub-2-buttontext-group2 }}}</a></span>
      </dys-text>
      <!-- End Footer Content -->

    </dys-column>
  </dys-row>
</dys-block>
`;

export default Footer;
