const HeroWithSubtitle = `
<dys-block>
<dys-row background-color="#F5774E" padding="10px 0">
  <dys-column>
    <dys-text
      color="#FFFFFF"
      padding="0"
      font-size="36px"
      editable="title"
      font-family="'Droid Sans', 'Helvetica Neue', Arial, sans-serif"
      align="center"
    >
      25% off!
    </dys-text>
    <dys-text
      color="#933f24"
      padding-top="10px"
      font-size="18px"
      editable="subtitle"
      font-family="'Droid Sans', 'Helvetica Neue', Arial, sans-serif"
      align="center"
    >
      Premium account upgrade!
    </dys-text>
    <dys-img
      src="https://assets.opensourceemails.com/imgs/neopolitan/present.png"
      align="center"
      width="147px"
      height="121px"
      padding="5px"
      editable="img"
    />
    <dys-text
      color="#933f24"
      editable="content"
      font-family="'Droid Sans', 'Helvetica Neue', Arial, sans-serif"
      align="center"
    >
      Faster download speeds for all users!<br />
      No wait times <br />
      Unlimited downloads <br />
      Premium quality <br />
    </dys-text>
  </dys-column>
</dys-row>
</dys-block>
`;

export default HeroWithSubtitle;
