import NeopolitanOrderShipped from './../../../assets/images/Neopolitan/NeopolitanOrderShipped.png';
import NeopolitanSurvey from './../../../assets/images/Neopolitan/NeopolitanSurvey.png';
import NeopolitanUpsell from './../../../assets/images/Neopolitan/NeopolitanUpsell.png';

const NeopolitanTemplates = [
  {
    alt: 'Order Shipped Template',
    name: 'Order Shipped',
    src: NeopolitanOrderShipped,
  },
  {
    alt: 'Survey Template',
    name: 'Survey',
    src: NeopolitanSurvey,
  },
  {
    alt: 'Upsell Template',
    name: 'Upsell',
    src: NeopolitanUpsell,
  },
];

export default NeopolitanTemplates;
