const SignupInverse = `
<dys-block>
  <dys-row background-color='#feae39' padding='0'>
    <dys-column>
      <dys-img src='{{ mantra-signupin-image-imgurl-group3 }}' width='120px' height='120px' alt='{{ mantra-signupin-image-alt-group3 }}' border='0' editable='signup-inverse-image' />
    </dys-column>
  </dys-row>

  <dys-row background-color='#feae39' padding='0'>
    <dys-column>
      <dys-text align='center' font-family='Open Sans, Arial, sans-serif' font-size='16px' color='#ffffff' line-height='19px' font-weight='bold' editable='signup-inverse-welcome'>
        {{{ mantra-signupin-title-text-group1 }}}
      </dys-text>
    </dys-column>
  </dys-row>

  <dys-row background-color='#feae39' padding='5px'>
    <dys-column>
      <dys-text align='center' font-family='Open Sans, Arial, sans-serif' font-size='16px' color='#ffffff' line-height='24px' font-style='italic' editable='signup-inverse-body'>
        {{{ mantra-signupin-paragraph-text-group1 }}}
      </dys-text>
    </dys-column>
  </dys-row>

  <dys-row background-color='#feae39' padding='5px'>
    <dys-column>
      <dys-button href='{{ mantra-signupin-button-buttonurl-group2 }}' target='_blank' height='25px' width='200px' align='center' font-family='Open Sans, Arial, sans-serif' font-size='17px' color='#feae39' background-color='#ffffff' font-weight='bold' text-transform='uppercase' text-decoration='none' line-height='20px' border-radius='0px'> 
        {{{ mantra-signupin-button-buttontext-group2 }}}
      </dys-button>
    </dys-column>
  </dys-row>
</dys-block>
`;

export default SignupInverse;
