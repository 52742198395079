const Receipt = `
<dys-block>
  <dys-row background-color="#F5774E">
      <dys-column>
        <dys-text
          align="left"
          color="#FFFFFF"
          padding="25px 25px 0 50px"
          editable="white-text"
          font-family="'Droid Sans', 'Helvetica Neue', Arial, sans-serif"
        >
          Bob Erlicious
        </dys-text>
        <dys-text
          align="left"
          color="#933f24"
          padding="0 25px 0 50px"
          editable="address"
          font-family="'Droid Sans', 'Helvetica Neue', Arial, sans-serif"
        >
          123 Flower Drive <br />
          Victoria, BC <br />
          V9P 2E8 <br />
          1(250)222-2232
        </dys-text>
      </dys-column>

      <dys-column>
        <dys-text
          align="right"
          align="right"
          color="#FFFFFF"
          padding="25px 50px 0 25px"
          font-family="'Droid Sans', 'Helvetica Neue', Arial, sans-serif"
          editable="invoice"
        >
          Invoice: 00234
        </dys-text>
        <dys-text
          align="right"
          align="right"
          color="#933f24"
          padding="0 50px 0 25px"
          editable="date"
          font-family="'Droid Sans', 'Helvetica Neue', Arial, sans-serif"
        >
          April 3, 2019
        </dys-text>
      </dys-column>
  </dys-row>
  <dys-row background-color="#F5774E">
    <dys-column>
      <dys-table padding="10px 50px" font-size="16px" cellpadding="10">
        <tr align="center">
          <th align="center" style="background-color: #ac4d2f; color: #ffffff;">
            Plan
          </th>
          <th align="center" style="background-color: #ac4d2f; color: #ffffff;">
            Period
          </th>
          <th align="center" style="background-color: #ac4d2f; color: #ffffff;">
            Amount
          </th>
        </tr>
        <tr>
          <td
            style="background-color: #f7a084; color: #933f24; text-align: center;"
          >
            Silver Plan
          </td>
          <td
            style="background-color: #f7a084; color: #933f24; text-align: center;"
          >
            August
          </td>
          <td
            style="background-color: #f7a084; color: #933f24; text-align: center;"
          >
            $50.00
          </td>
        </tr>
      </dys-table>
    </dys-column>
  </dys-row>
  <dys-row background-color="#F5774E" padding="10px 0">
    <dys-column width="90%">
      <dys-text
        align="left"
        color="#933f24"
        editable="content"
        font-family="'Droid Sans', 'Helvetica Neue', Arial, sans-serif"
      >
        The amount of $500.00 USD has been charged on the credit card ending with
        0123.
      </dys-text>
    </dys-column>
  </dys-row>
  <dys-row background-color="#F5774E" padding="0 30px">
    <dys-column padding="0px 0px 1px 0px" background-color="#933f24" width="90%">
    </dys-column>
  </dys-row>
  <dys-row background-color="#F5774E" padding="10px 0">
    <dys-column width="90%">
      <dys-text
        align="left"
        color="#933f24"
        font-family="'Droid Sans', 'Helvetica Neue', Arial, sans-serif"
        editable="thanks"
      >
        Thank you for your business. Please
        <a
          style="text-decoration: none; color: #FFFFFF"
          href="mailto:example@example.com"
          >contact us</a
        >
        with any questions regarding this invoice
        <br /><br />
        Awesome Inc.
      </dys-text>
    </dys-column>
  </dys-row>
</dys-block>
`;

export default Receipt;
