import {
  getDefaultMantraBlocks,
  getDefaultOxygenBlocks,
  getDefaultLifestyleBlocks,
  getDefaultNeopolitanBlocks,
} from './DefaultBlockLibrary';

// Builds the initial data set to power the app
export const getInitialData = (themeType, templateType) => {
  // ######################
  // Block Library Contents
  // ######################

  const getBlockLibraryContents = (themeType) => {
    switch (themeType) {
      case 'oxygen':
        return {
          'block-library': {
            id: 'block-library',
            title: 'Block Library',
            blockIds: getDefaultOxygenBlocks(),
          },
        };
      case 'neopolitan':
        return {
          'block-library': {
            id: 'block-library',
            title: 'Block Library',
            blockIds: getDefaultNeopolitanBlocks(),
          },
        };
      case 'mantra':
        return {
          'block-library': {
            id: 'block-library',
            title: 'Block Library',
            blockIds: getDefaultMantraBlocks(),
          },
        };
      case 'lifestyle':
      default:
        return {
          'block-library': {
            id: 'block-library',
            title: 'Block Library',
            blockIds: getDefaultLifestyleBlocks(),
          },
        };
    }
  };

  // ######################
  // Visual Editor Contents
  // ######################

  const getVisualEditorContents = (templateType) => {
    switch (templateType) {
      case 'new':
      default:
        return {
          'visual-editor': {
            id: 'visual-editor',
            title: 'Visual Editor',
            blockIds: [],
          },
        };
    }
  };

  const blockLibraryContent = getBlockLibraryContents(themeType);
  const visualEditorContent = getVisualEditorContents(templateType);
  const columnsContent = Object.assign({}, blockLibraryContent, visualEditorContent);

  const initialData = {
    columns: columnsContent,
    columnOrder: ['block-library', 'visual-editor', 'edit-mode'],
  };

  return initialData;
};
