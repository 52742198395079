const Survey = `
<dys-block>
<dys-row background-color="#F5774E" padding="20px 0">
  <dys-column width="90%">
    <dys-text
      color="#933f24"
      font-size="18px"
      editable="content"
      font-family="'Droid Sans', 'Helvetica Neue', Arial, sans-serif"
    >
      Click a star to rate our services
    </dys-text>
    <dys-table padding-bottom="5px" table-layout="fixed">
      <tr>
        <td
          style="'Droid Sans', 'Helvetica Neue', Arial, sans-serif; font-size: 14px; color: #933f24; text-align: center; line-height: 21px;"
        >
          <a href="https://example.com"
            ><img
              width="50px"
              height="50px"
              src="https://swu-cs-assets.s3.amazonaws.com/OSET/neopolitan/star.png"
              alt="1"
          /></a>
          <br />
          <strong>Unsatisfied</strong>
        </td>
        <td
          style="'Droid Sans', 'Helvetica Neue', Arial, sans-serif; font-size: 14px; color: #933f24; text-align: center; line-height: 21px;"
        >
          <a href="https://example.com"
            ><img
              width="50px"
              height="50px"
              src="https://swu-cs-assets.s3.amazonaws.com/OSET/neopolitan/star.png"
              alt="2"
          /></a>
          <br />
          &nbsp;
        </td>
        <td
          style="'Droid Sans', 'Helvetica Neue', Arial, sans-serif; font-size: 14px; color: #933f24; text-align: center; line-height: 21px;"
        >
          <a href="https://example.com"
            ><img
              width="50px"
              height="50px"
              src="https://swu-cs-assets.s3.amazonaws.com/OSET/neopolitan/star.png"
              alt="3"
          /></a>
          <br />
          <strong>Neutral</strong>
        </td>
        <td
          style="'Droid Sans', 'Helvetica Neue', Arial, sans-serif; font-size: 14px; color: #933f24; text-align: center; line-height: 21px;"
        >
          <a href="https://example.com"
            ><img
              width="50px"
              height="50px"
              src="https://swu-cs-assets.s3.amazonaws.com/OSET/neopolitan/star.png"
              alt="4"
          /></a>
          <br />
          &nbsp;
        </td>
        <td
          style="'Droid Sans', 'Helvetica Neue', Arial, sans-serif; font-size: 14px; color: #933f24; text-align: center; line-height: 21px;"
        >
          <a href="https://example.com"
            ><img
              width="50px"
              height="50px"
              src="https://swu-cs-assets.s3.amazonaws.com/OSET/neopolitan/star.png"
              alt="5"
          /></a>
          <br />
          <strong>Satisfied</strong>
        </td>
      </tr>
    </dys-table>
  </dys-column>
</dys-row>
</dys-block>
`;

export default Survey;
