const Survey = `
<dys-block>
  <dys-row full-width='full-width' background-color='#f7f7f7'>
    <dys-column padding='30px 75px' width='90%' background-color='#fff' border='1px solid #ccc'>
      <dys-text color='#4d4d4d' line-height='21px' font-weight='400' font-size='18px' align='center' font-family='Oxygen, Helvetica neue, sans-serif'>
        {{{ oxygen-rating-title-text-group1 }}}
      </dys-text> 
      <dys-table padding-bottom='5px'>
        <tr>
          <td style='text-align: center;'>
            <a href='{{ oxygen-rating-button-one-buttonurl-group3 }}'><img src='{{ oxygen-rating-button-one-imgurl-group3 }}' alt='{{ oxygen-rating-button-one-alt-group3 }}' /></a>
          </td>
          <td style='text-align: center;'>
            <a href='{{ oxygen-rating-button-two-buttonurl-group3 }}'><img src='{{ oxygen-rating-button-two-imgurl-group3 }}' alt='{{ oxygen-rating-button-two-alt-group3 }}' /></a>
          </td>
          <td style='text-align: center;'>
            <a href='{{ oxygen-rating-button-three-buttonurl-group3 }}'><img src='{{ oxygen-rating-button-three-imgurl-group3 }}' alt='{{ oxygen-rating-button-three-alt-group3 }}' /></a>
          </td>
          <td style='text-align: center;'>
            <a href='{{ oxygen-rating-button-four-buttonurl-group3 }}'><img src='{{ oxygen-rating-button-four-imgurl-group3 }}' alt='{{ oxygen-rating-button-four-alt-group3 }}' /></a>
          </td>
          <td style='text-align: center;'>
            <a href='{{ oxygen-rating-button-five-buttonurl-group3 }}'><img src='{{ oxygen-rating-button-five-imgurl-group3 }}' alt='{{ oxygen-rating-button-five-alt-group3 }}' /></a>
          </td>
        </tr>      
      </dys-table>
      <dys-table padding-top='0px'>
        <tr style='font-family: Helvetica, Arial, sans-serif; font-size: 14px; color: #777777; text-align: center; line-height: 21px;'>
          <td style='text-align: left; font-weight:bold;'>
            {{{ oxygen-rating-title-one-text-group1 }}}
          </td>
          <td style='text-align: left; font-weight:bold;'>
            {{{ oxygen-rating-title-two-text-group1 }}}
          </td>
          <td style='text-align: right; font-weight:bold;'>
            {{{ oxygen-rating-title-three-text-group1 }}}
          </td>
        </tr>      
      </dys-table>
    </dys-column>
  </dys-row>
</dys-block>
`;

export default Survey;
