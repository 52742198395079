import React, { PureComponent, useState } from 'react';
import styled from 'styled-components';
import { Provider, Subscribe } from 'unstated';
import RichTextEditor from './RichTextEditor';
import XIcon from '../../../assets/images/XIcon';
import TrashCanIcon from '../../../assets/images/TrashCanIcon';
import DMLPreview from '../../DMLPreview';
import AppDataStore from '../../../store/StateStore';

const BlockLibraryEditingOverlay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  resize: none;
  background-color: #ffffff;
  grid-row-start: 1;
  grid-column-start: 1;
  box-shadow: rgba(18, 18, 18, 0.1) 6px 0px 4px -2px;
  transform: ${(props) =>
    props.isEditing ? 'translateX(0px)' : 'translateX(-564px)'};
  transition: transform 0.5s;
  height: 95vh;
  padding-bottom: 90px;
  z-index: 10;
  position: ${(props) => (props.isEditing ? 'relative' : 'absolute')};
`;

const HeaderContainer = styled.div`
  width: 100%;
  background-color: rgb(248, 248, 248);
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  padding: 24px 24px 24px 32px;
  margin-bottom: 0px;
  position: sticky;
  top: 0;
`;

const TitleContainer = styled.div`
  align-self: auto;
  min-width: auto;
  flex: 1 1 0%;
  padding: 0px;
  margin: 0px;
`;

const Title = styled.h3`
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: 30px;
  color: rgb(70, 70, 70);
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
`;

const EscapeContainer = styled.div`
  color: rgb(120, 120, 120);
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
`;

const EditableContainer = styled.div`
  padding: 32px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-content: flex-start;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  border-bottom: 1px solid rgb(237, 237, 237);
`;

const DeleteButton = styled.div`
  color: rgb(255, 66, 66);
  display: flex;
  width: 175px;
  cursor: pointer;
  text-decoration: none;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
`;

const RemoveFromTemplateText = styled.span`
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.4px;
  margin-left: 8px;
`;

const EditableTitle = styled.div`
  font-weight: 500;
  font-size: 13px;
  font-family: Roboto, sans-serif;
  text-transform: uppercase;
  color: rgb(120, 120, 120);
  letter-spacing: 0.4px;
  line-height: 20px;
  text-align: left;
`;

const PlainTextInput = styled.input`
  width: 100%;
  height: 28px;
  line-height: 28px;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.8px;
  color: rgb(70, 70, 70);
  border-style: none none solid;
  border-image: initial;
  border-bottom: 1px solid rgb(70, 70, 70);
  padding: 0px;
  margin: 0px 0px 32px;
  :focus {
    outline-offset: -2px;
    outline: -webkit-focus-ring-color auto 5px;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 32px;
  border-width: 1px 1px 0px;
  border-style: solid solid solid;
  border-color: rgb(224, 224, 224) rgb(224, 224, 224) rgb(224, 224, 224);
  border-image: initial;
  border-bottom: 0px;
`;

const NavContainer = styled.div`
  display: flex;
  width: 100%;
  position: sticky;
  top: 0;
  height: 32px;
  z-index: 15;
  background: #FAFAFA;
  padding-bottom: 4px;
  padding-left: 31px;
  border-bottom: 1px solid #DDD;
`;

const NavButton = styled.button`
  min-width: 118px;
  max-width: 138px;
  height: 32px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-top-width: initial;
  border-right-width: initial;
  border-left-width: initial;
  border-top-color: initial;
  border-right-color: initial;
  border-left-color: initial;
  font-size: 12px;
  letter-spacing: 0.4px;
  border-style: none none solid;
  border-image: initial;
  background: transparent;
  padding: 0px;
  flex: 1 1 0%;
  outline: none;
  cursor: ${(props) => (props.isAllowed ? 'pointer' : 'not-allowed')};
  line-height: ${(props) => (props.isActive ? '1.5' : '20px')};
  border: ${(props) =>
    props.isActive
      ? '1px solid #DDDDDD'
      : 'initial'};
  background: ${(props) => (props.isActive ? '#FFF' : '#FAFAFA')};
  border-radius: 4px 4px 0px 0px;
  border-bottom: ${(props) => (props.isActive ? '1px solid #FFF;' : '1px solid #DDD; ')};
  color: ${(props) => (props.isActive ? '#464646' : '#787878')};
`;

const Editor = (options) => {
  const { heading, isRichTextMode, value, onChange } = options;

  const [textValue, setValue] = useState(value);

  const onChangeValue = (e) => {
    const inputValue = e.currentTarget.value;
    setValue(inputValue);
    onChange(inputValue);
  };

  return (
    <div>
      <EditableTitle>{heading}</EditableTitle>
      {isRichTextMode ? (
        <RichTextEditor
          richTextMode={isRichTextMode}
          value={value}
          onChange={onChange}
        />
      ) : (
        <PlainTextInput value={textValue} onChange={onChangeValue} />
      )}
    </div>
  );
};

const getBlockEditableName = (key) => {
  return key.includes('-alt')
    ? 'IMAGE - ALT TEXT'
    : key.includes('-imgurl')
      ? 'IMAGE - SOURCE URL'
      : key.includes('-text')
        ? 'TEXT'
        : key.includes('-buttontext')
          ? 'LINK - TEXT'
          : key.includes('-buttonurl')
            ? 'LINK - URL'
            : '';
};

const ReturnEditables = (props) => {
  const ds = props.appData.state[props.appData.state.blockId].dataset;

  const setupDyspatchItems = Object.keys(ds).filter((item) =>
    item.includes('setup-dyspatch-to-use')
  );
  const textItems = Object.keys(ds).filter((item) => item.includes('-group1'));
  const buttonItems = Object.keys(ds).filter((item) => item.includes('-group2'));
  const imageItems = Object.keys(ds).filter((item) => item.includes('-group3'));

  const grouped = (array) => {
    const editable = array.map((key, index) => {
      const blockName = getBlockEditableName(key);

      return (
        <Editor
          heading={blockName}
          isRichTextMode={blockName === 'TEXT'}
          value={props.appData.state[props.appData.state.blockId].dataset[key]}
          onChange={(value) => {
            props.appData.handleBlockChange(value, key, props.appData.state.blockId);
          }}
        />
      );
    });

    return editable;
  };

  const SplashTitle = styled.h3`
    color: rgb(30, 30, 30);
  `;

  const SplashText = styled.p`
    line-height: 22px;
    padding: 20px;
    color: rgb(120, 120, 120);
  `;

  const setupDyspatchScreen = (setupDyspatchItems) => {
    if (setupDyspatchItems.length > 0) {
      return (
        <div>
          <SplashTitle>Dynamic Block</SplashTitle>
          <SplashText>
            This is a <strong>Dynamic Block</strong> which uses data from your system
            in order to supply its content.
            <br /> <br />
            You must add your Email Service Provider's templating language to the
            exported HTML in order for it to be dynamic.
            <br />
            <br />
            <Divider />
            <strong>Dyspatch</strong> allows you to write your templating logic in{' '}
            <a
              href="https://docs.dyspatch.io/dml/#components"
              rel="noopener noreferrer"
              target="_blank"
              style={{
                color: '#1F76D3',
              }}
            >
              DML
            </a>{' '}
            which will automatically export to any of our{' '}
            <a
              href="https://docs.dyspatch.io/dml/#export-languages"
              rel="noopener noreferrer"
              target="_blank"
              style={{
                color: '#1F76D3',
              }}
            >
              supported languages
            </a>
            .<br />
            <br />
            <a
              href="https://app.dyspatch.io/signup/?utm_ref=playground"
              rel="noopener noreferrer"
              target="_blank"
              style={{
                color: '#1F76D3',
              }}
            >
              Start your Free Trial account
            </a>{' '}
            to modify the DML blocks.
          </SplashText>
        </div>
      );
    }
    return;
  };

  return (
    <div>
      {grouped(textItems)}
      {grouped(buttonItems)}
      {grouped(imageItems)}
      {setupDyspatchScreen(setupDyspatchItems)}
    </div>
  );
};

export default class BlockLibraryEditable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      displayTab: props.displayTab || 'editable',
    };
  }

  handleNavClick = (navType) => {
    this.setState({ displayTab: navType });
  };

  render() {
    return (
      <Provider>
        <Subscribe to={[AppDataStore]}>
          {(appData) => (
            <BlockLibraryEditingOverlay isEditing={appData.state.editing}>
              <HeaderContainer>
                <TitleContainer>
                  <Title>{appData.state.name}</Title>
                  <DeleteButton
                    onClick={() =>
                      window.confirm('Are you sure you want to remove this block?') &&
                      appData.deleteBlock(appData.state.blockId)
                    }
                  >
                    <TrashCanIcon />
                    <RemoveFromTemplateText>
                      Remove from Template
                  </RemoveFromTemplateText>
                  </DeleteButton>
                </TitleContainer>
                <EscapeContainer onClick={appData.stoppedEditing}>
                  <XIcon />
                </EscapeContainer>
              </HeaderContainer>
              <NavContainer>
                <NavButton
                  isActive={this.state.displayTab === 'editable'}
                  onClick={() => this.handleNavClick('editable')}
                  isAllowed={true}
                >
                  Editable Content
                  </NavButton>
                <NavButton
                  isActive={this.state.displayTab === 'dml'}
                  onClick={() => this.handleNavClick('dml')}
                  isAllowed={true}
                >
                  Dyspatch DML Code
                  </NavButton>
              </NavContainer>
              
              {this.state.displayTab === 'editable' ? (
                <EditableContainer>
                  {appData.state.editing ? <ReturnEditables appData={appData} /> : ''}
                </EditableContainer>
              ) : (
                <DMLPreview id={appData.state.blockId} blocks={appData.state.initialData.columns[
                  'visual-editor'
                ].blockIds.map((item, _index) => item)} />
              )}

            </BlockLibraryEditingOverlay>
          )}
        </Subscribe>
      </Provider>
    );
  }
}
