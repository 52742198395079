const ButtonOnWhite = `
<dys-block>
  <dys-row>
    <dys-column>
      <dys-button href='#' line-height='21px' font-family='Oxygen, Helvetica neue, sans-serif' font-weight='400' border-radius='5px' font-size='14px' background-color='#ff6f6f' editable="button-white">
        Button Text
      </dys-button>
    </dys-column>
  </dys-row>
</dys-block>
`;

export default ButtonOnWhite;
