import React, { PureComponent } from 'react';
import styled from 'styled-components';

import BlockLibraryBlockList from './BlockList';
import { ButtonText } from '../Export/Styles';

import LocalizationsSplashImg from '../../assets/images/localizations_splash.png';
import CommentsSplashImg from '../../assets/images/approval_splash.png';

import { CenterContainer } from './../../styles/Loading/LoadingStyles';

const BlockLibraryList = styled.div`
  transition: background-color 0.2s ease;
  background-color: ${(props) => (props.isDraggingOver ? 'lightgrey' : 'white')};
  max-height: calc(99vh - 170px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const NavContainer = styled.div`
  display: flex;
  width: 100%;
  position: sticky;
  top: 0;
  height: 48px;
  z-index: 15;
  background: white;
  padding-bottom: 4px;
`;

const BlockContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 564px;
  max-height: calc(99vh - 170px);
  flex-wrap: wrap;
  overflow: auto;
  padding: 16px 8px 0px 8px;
`;

const NavButton = styled.button`
  min-width: 128px;
  max-width: 360px;
  height: 48px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-top-width: initial;
  border-right-width: initial;
  border-left-width: initial;
  border-top-color: initial;
  border-right-color: initial;
  border-left-color: initial;
  cursor: ${(props) => (props.isAllowed ? 'pointer' : 'not-allowed')};
  font-size: 14px;
  letter-spacing: 0.4px;
  color: rgb(120, 120, 120);
  border-style: none none solid;
  border-image: initial;
  background: transparent;
  padding: 0px;
  flex: 1 1 0%;
  outline: none;
  font-weight: ${(props) => (props.isActive ? '600' : '0')};
  line-height: ${(props) => (props.isActive ? '1.5' : '20px')};
  border-bottom: ${(props) =>
    props.isActive
      ? '2px solid rgb(120, 120, 120)'
      : '2px solid rgb(224, 224, 224)'};
  :focus {
    outline: none;
  }
`;

const Splash = styled.div`
  text-align: center;
  max-width: 400px;
`;

const SplashTitle = styled.h1`
  color: rgb(30, 30, 30);
`;

const SplashText = styled.p`
  line-height: 22px;
  padding: 20px;
  color: rgb(120, 120, 120);
`;

const LocalizationsSplash = () => (
  <CenterContainer>
    <Splash>
      <img src={LocalizationsSplashImg} alt="Dyspatch localization management" />
      <SplashTitle>Want to manage email localizations at scale?</SplashTitle>
      <SplashText>
        Dyspatch supports localization workflows so your customers get the right
        email in the right language. Manage localizations directly within the UI or
        via POT/PO files.
      </SplashText>
      <ButtonText target="_blank" href="https://app.dyspatch.io/signup/?utm_ref=playground ">
        Sign up for Dyspatch
      </ButtonText>
    </Splash>
  </CenterContainer>
);

const CommentsSplash = () => (
  <CenterContainer>
    <Splash>
      <img src={CommentsSplashImg} alt="Dyspatch workflows" />
      <SplashTitle>Does this email require a second look?</SplashTitle>
      <SplashText>
        Invite your colleagues to join Dyspatch so they can leave feedback and make
        suggestions on in-progress work.
      </SplashText>
      <ButtonText target="_blank" href="https://app.dyspatch.io/signup/?utm_ref=playground ">
        Sign up for Dyspatch
      </ButtonText>
    </Splash>
  </CenterContainer>
);

export default class BlockLibrary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      displayTab: props.displayTab || 'block',
    };
  }

  handleNavClick = (navType) => {
    this.setState({ displayTab: navType });
  };

  handleDisplay = (caseType) => {
    switch (caseType) {
      case 'locale':
        return <LocalizationsSplash />;
      case 'comments':
        return <CommentsSplash />;
      default:
        // Should always default to block list
        return (
          <BlockLibraryBlockList blocks={this.props.blocks} area={this.props.area} />
        );
    }
  };

  render() {
    return (
      <BlockLibraryList
        ref={this.props.provided.innerRef}
        isDraggingOver={this.props.snapshot.isDraggingOver}
      >
        <NavContainer>
          <NavButton
            isActive={this.state.displayTab === 'block'}
            onClick={() => this.handleNavClick('block')}
            isAllowed={true}
          >
            Blocks
          </NavButton>
          <NavButton
            isActive={this.state.displayTab === 'locale'}
            onClick={() => this.handleNavClick('locale')}
            isAllowed={true}
          >
            Localizations
          </NavButton>
          <NavButton
            isActive={this.state.displayTab === 'comments'}
            onClick={() => this.handleNavClick('comments')}
            isAllowed={true}
          >
            Comments
          </NavButton>
        </NavContainer>

        <BlockContainer>
          {this.handleDisplay(this.state.displayTab)}
          {this.props.provided.placeholder}
        </BlockContainer>
      </BlockLibraryList>
    );
  }
}
