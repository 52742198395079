import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Editor } from 'slate-react';
import Html from 'slate-html-serializer';
import SoftBreak from 'slate-soft-break';

import { wrapLink, unwrapLink, isOnlyLink, DetectOutsideClick } from './Helpers';
import { MaxLength, MarkHotKey } from './Plugins';
import { Toolbar, LinkPreview, LinkEditor } from './Toolbars';

const EditorWrapper = styled.div`
  display: block;
  position: relative;
  padding: 0;
  resize: none;
  margin-bottom: 16px;
`;

/* FocusHighlighter is absolute positioned and last in dom order
   `:focus` of the editor enables focus style with a sibling selector
   Allows for the editor to independently scroll of toolbar
   and for the FocusHighlighter to "wrap" both the editor and toolbar
   Avoids managing state onfocus/blur which causes issues with how Slate takes focus

   <Wrapper>
    <Editor>
    {if toolbar &&
      <Toolbar>
    }
    <FocusHighlighter/>
  </Wrapper>

  <Wrapper>
    <Editor> <!-- :focus pseudo-class -->
    {if toolbar &&
      <Toolbar>
    }
    <FocusHighlighter /> <!-- now has focus style -->
  </Wrapper>

*/

const FocusHighlighter = styled.div`
  display: block;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.8px;
  color: #787878;
  border: solid 1px #cacaca;
  position: absolute;
  min-height: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 0;
  resize: none;
  border-radius: 4px;
  z-index: 1;
  pointer-events: none;
  .RichTextEditor.isOpen ~ &,
  .RichTextEditor:focus ~ & {
    outline: none;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4),
      0 0 2px 2px rgba(69, 143, 253, 0.6);
  }
`;

const EditableTextArea = styled(Editor)`
  overflow: auto;
  max-height: 216px;
  padding: 8px;
  outline: none;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: #787878;
  z-index: 5;
  a,
  a:visited {
    color: #1F76D3;
  }
`;

const RichTextBtn = styled.button`
  position: absolute;
  bottom: 12px;
  right: 12px;
  z-index: 6;
  pointer-events: none;
  opacity: 0;
  transform: translateY(12px);
  transition: opacity 0.2s ease, transform 0.2s ease;
  .RichTextEditor.isOpen ~ &,
  .RichTextEditor:focus ~ & {
    transform: translateY(0);
    pointer-events: all;
    opacity: 1;
    &[disabled] {
      opacity: 0.5;
    }
  }
`;

const BLOCK_TAGS = {
  div: 'paragraph', // 'paragraph' block that is a div for now
};

const INLINE_TAGS = {
  a: 'link',
};

const MARK_TAGS = {
  em: 'italic',
  strong: 'bold',
  u: 'underline',
};

const CustomRules = [
  // BLOCK TAGS
  {
    // ignore required since these functions can potentially return nothing
    deserialize(el, next) {
      const type = BLOCK_TAGS[el.tagName.toLowerCase()];
      if (type) {
        return {
          object: 'block',
          type,
          data: {
            className: el.getAttribute('class'),
          },
          nodes: next(el.childNodes),
        };
      }
    },
    /* eslint-disable */
    serialize(obj, children) {
      if (obj.object === 'block') {
        switch (obj.type) {
          case 'paragraph': // output children without any wrapping elements. new lines are translated into <br> tags
            return <React.Fragment>{children}</React.Fragment>;
        }
      }
    },
    /* eslint-enable */
  },
  // INLINE TAGS
  {
    /* eslint-disable */
    deserialize(el, next) {
      const type = INLINE_TAGS[el.tagName.toLowerCase()];
      if (type) {
        return {
          object: 'inline',
          type,
          data: {
            href: el.getAttribute('href'),
          },
          nodes: next(el.childNodes),
        };
      }
    },
    /* eslint-enable */
    /* eslint-disable */
    serialize(obj, children) {
      if (obj.object === 'inline') {
        switch (obj.type) {
          case 'link':
            return <a href={obj.data.get('href')}>{children}</a>;
        }
      }
    },
    /* eslint-enable */
  },
  // MARK TAGS
  {
    /* eslint-disable */
    deserialize(el, next) {
      const type = MARK_TAGS[el.tagName.toLowerCase()];
      if (type) {
        return {
          object: 'mark',
          type,
          nodes: next(el.childNodes),
        };
      }
    },
    /* eslint-enable */
    /* eslint-disable */
    serialize(obj, children) {
      if (obj.object === 'mark') {
        switch (obj.type) {
          case 'bold':
            return <strong>{children}</strong>;
          case 'italic':
            return <em>{children}</em>;
          case 'underline':
            return <u>{children}</u>;
        }
      }
    },
    /* eslint-enable */
  },
];

// Create a new serializer instance
const html = new Html({ rules: CustomRules });

export default class RichTextEditor extends React.Component {
  editorRef = React.createRef();
  linkModal = React.createRef();

  plugins = [
    SoftBreak(),
    MarkHotKey({ key: 'b', type: 'bold' }),
    MarkHotKey({ key: 'i', type: 'italic' }),
    MarkHotKey({ key: 'u', type: 'underline' }),
    MaxLength({ maxLength: this.props.maxLength }),
  ];

  state = {
    value: html.deserialize(this.props.value),
    linkHref: '',
    linkText: '',
    modifyLink: false,
    previewLink: false,
    previewTop: 0,
    previewLeft: 0,
    arrowDir: 'top',
  };

  // Toolbar Click Functions
  onClickMarkBtn = (event, mark) => {
    event.preventDefault(); // required to keep editor in focus
    if (this.editorRef !== undefined && this.editorRef.current !== undefined) {
      this.editorRef.current.toggleMark(mark);
    }
  };

  onClickLinkBtn = (event) => {
    event.preventDefault();
    const { value } = this.state;

    if (value.selection.isExpanded) {
      const text = value.fragment.text; // returns the selected fragment
      this.setState({
        modifyLink: true,
        linkText: text,
      });
    }
    this.updateTooltipPosition();
  };

  onLinkTextChange = (value) => {
    this.setState({
      linkText: value,
    });
  };

  onLinkHrefChange = (value) => {
    this.setState({
      linkHref: value,
    });
  };

  applyLinkChanges = () => {
    if (this.editorRef && this.editorRef.current) {
      this.editorRef.current
        .moveAnchorToStartOfInline()
        .moveFocusToEndOfInline()
        .insertText(this.state.linkText.trim())
        .moveFocusBackward(this.state.linkText.trim().length)
        .command(unwrapLink)
        .command(wrapLink, this.state.linkHref.trim());
    }

    /// hide the modal
    window.setTimeout(() => {
      this.setState({ modifyLink: false });
    }, 0);
  };

  resetLinkChanges = () => {
    this.setState({
      modifyLink: false,
      previewLink: false,
      linkHref: '',
      linkText: '',
    });
  };

  removeLink = () => {
    if (this.editorRef !== undefined && this.editorRef.current !== undefined) {
      this.editorRef.current.command(unwrapLink);
      window.setTimeout(() => {
        this.resetLinkChanges();
      }, 0);
    }
  };

  // check for links in current selection
  hasLinks = () => {
    const { value } = this.state;
    return value.inlines.some((inline) => !!inline && inline.type === 'link');
  };

  // checks for bold, underline, italic
  hasMark = (type) => {
    const { value } = this.state;
    return value.activeMarks.some((mark) => mark.type === type);
  };

  // Tooltip link positioning
  updateTooltipPosition = () => {
    window.setTimeout(() => {
      const native = window.getSelection();
      if (
        !native ||
        !this.linkModal ||
        !this.linkModal.current ||
        !native.anchorNode
      ) {
        return;
      }
      const range = native.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      const winHeight = window.innerHeight;
      const winWidth = window.innerWidth;
      const buffer = 14;

      // get tooltip dimensions
      const tooltip = this.linkModal.current;
      const tooltipRect = tooltip.getBoundingClientRect();
      const toolHeight = tooltipRect.height;

      const ratio = winHeight / (toolHeight + rect.height);

      const fixedTooltipWidth = 360;

      let top;
      let left;
      let arrowDir;
      const isOffRight = rect.left + fixedTooltipWidth > winWidth;
      const leftOffset = isOffRight
        ? winWidth - fixedTooltipWidth - buffer
        : rect.left;

      if (ratio < 2) {
        top = winHeight - toolHeight - buffer; // stick it at the bottom of the window if it can't fit above
        left = isOffRight
          ? winWidth - fixedTooltipWidth - buffer
          : rect.left + rect.width + buffer; // offset it to see the text
        arrowDir = ''; // hide the tooltip arrow
      } else if (winHeight - (rect.top + rect.height) < toolHeight) {
        // place the tooltip above text if selection near bottom of window
        top = rect.top - toolHeight - buffer;
        left = leftOffset;
        arrowDir = isOffRight ? '' : 'bottom';
      } else {
        // align it with the text selection
        top = rect.top + rect.height + buffer;
        left = leftOffset;
        arrowDir = isOffRight ? '' : 'top';
      }

      this.setState({
        previewTop: top,
        previewLeft: left,
        arrowDir,
      });
    }, 0);
  };

  showLinkPreview = (editor) => {
    // ensure we don't show link preview if other nodes are part of selection
    const isLink = isOnlyLink(editor.value);

    if (isLink) {
      const href = editor.value.anchorInline.data.get('href');
      const text = editor.value.anchorInline.text;

      window.setTimeout(() => {
        editor.moveAnchorToStartOfInline().moveFocusToStartOfInline();
      }, 0);

      // show link preview and actions
      this.setState({
        linkHref: href,
        linkText: text,
        modifyLink: false,
        previewLink: true,
      });
    } else {
      this.setState({
        modifyLink: false,
        previewLink: false,
        linkHref: '',
        linkText: '',
      });
    }
  };

  onClickInlineBtn = (e) => {
    e.preventDefault();
    if (this.props.btnOnClick) {
      this.props.btnOnClick();
    }
    // clear the editor on submit and refocus
    window.setTimeout(() => {
      if (this.editorRef && this.editorRef.current) {
        this.editorRef.current
          .moveToRangeOfDocument()
          .focus()
          .insertText('');
      }
    }, 0);
  };

  onClick = (event, editor, next) => {
    this.showLinkPreview(editor);
    this.updateTooltipPosition();
    return next();
  };

  onChange = ({ value }) => {
    // When the document changes, save the serialized HTML
    if (value.document !== this.state.value.document) {
      const valueString = html.serialize(value);

      // converts quote entities
      const newValue = valueString.replace(/&#x27;|&quot;/g, (match) => {
        if (match === '&#x27;') {
          return "'";
        }
        if (match === '&quot;') {
          return '"';
        }
        return '';
      });
      this.props.onChange(newValue);
    }
    this.setState({ value });
  };

  render() {
    const top = this.state.previewTop;
    const left = this.state.previewLeft;
    const hasContent = !!this.state.value.document.text.trim().length;

    const transform = `translateX(${left}px) translateY(${top}px)`;
    return (
      <React.Fragment>
        <EditorWrapper>
          <EditableTextArea
            className={`RichTextEditor ${
              this.props.showToolbar || (this.props.showBtn && hasContent)
                ? 'isOpen'
                : null
            }`}
            ref={this.editorRef}
            value={this.state.value}
            onChange={this.onChange}
            onClick={this.onClick}
            // Add the ability to render our nodes and marks
            renderInline={this.renderInline}
            renderMark={this.renderMark}
            plugins={this.plugins}
            placeholder={this.props.placeholder}
          />
          {!!this.props.showBtn && (
            <RichTextBtn
              disabled={!this.state.value.document.text.trim().length}
              onClick={this.onClickInlineBtn}
              size="small"
            >
              {this.props.btnText || 'Submit'}
            </RichTextBtn>
          )}
          {!!this.props.richTextMode && (
            <Toolbar
              onClickMark={this.onClickMarkBtn}
              onClickLink={this.onClickLinkBtn}
              hasMark={this.hasMark}
            />
          )}
          <FocusHighlighter />
        </EditorWrapper>

        {ReactDOM.createPortal(
          <div
            style={{
              position: 'absolute',
              zIndex: 15,
              top: 0,
              left: 0,
              transition: 'transform 0.2s ease',
              transform,
            }}
            ref={this.linkModal}
          >
            {this.state.modifyLink && (
              <DetectOutsideClick onOutsideClick={this.resetLinkChanges}>
                <LinkEditor
                  onLinkTextChange={this.onLinkTextChange}
                  onLinkHrefChange={this.onLinkHrefChange}
                  onApply={this.applyLinkChanges}
                  onCancel={this.resetLinkChanges}
                  text={this.state.linkText}
                  link={this.state.linkHref}
                  arrowDir={this.state.arrowDir}
                />
              </DetectOutsideClick>
            )}

            {this.state.previewLink && (
              <DetectOutsideClick onOutsideClick={this.resetLinkChanges}>
                <LinkPreview
                  link={this.state.linkHref}
                  onRemove={this.removeLink}
                  onModify={(e) => {
                    e.preventDefault();
                    // wrap the current link
                    if (
                      this.editorRef !== undefined &&
                      this.editorRef.current !== undefined
                    ) {
                      this.editorRef.current
                        .moveAnchorToStartOfInline()
                        .moveFocusToEndOfInline();
                    }
                    this.setState({
                      previewLink: false,
                      modifyLink: true,
                    });
                  }}
                />
              </DetectOutsideClick>
            )}
          </div>,
          document.querySelector('#root')
        )}
      </React.Fragment>
    );
  }

  renderInline = (props, editor, next) => {
    const { attributes, children, node } = props;

    switch (node.type) {
      case 'paragraph': // we use a div here to break new lines in the editor context itself, it needs an element to track where nodes are
        return (
          <div {...attributes} className={node.data.get('className')}>
            {children}
          </div>
        );
      case 'link': {
        const { data } = node;
        const href = data.get('href');
        return (
          <a {...attributes} href={href}>
            {children}
          </a>
        );
      }
      default:
        return next();
    }
  };

  renderMark = (props, editor, next) => {
    const { mark, attributes, children } = props;
    switch (mark.type) {
      case 'bold':
        return <strong {...attributes}>{children}</strong>;
      case 'italic':
        return <em {...attributes}>{children}</em>;
      case 'underline':
        return <u {...attributes}>{children}</u>;
      default:
        return next();
    }
  };
}
