import LifestyleButton from './Button';
import LifestyleDivider from './Divider';
import LifestyleFooter from './Footer';
import LifestyleHeader from './Header';
import LifestyleLogo from './Logo';
import LifestyleOneColumnImage from './OneColumnImage';
import LifestyleSubhead from './Subhead';
import LifestyleText from './Text';
import LifestyleTwoImageColumn from './TwoImageColumn';

import { getHandlebarsFriendlyTemplates } from './../../utils/MakeDmlHandlebars';

const AllLifestyleBlocks = [
  {
    contentId: 'logo',
    name: 'Logo',
    dml: getHandlebarsFriendlyTemplates(LifestyleLogo, 'lifestyle'),
    type: 'Lifestyle',
    default: {
      dataset: getHandlebarsFriendlyTemplates(LifestyleLogo, 'lifestyle', true),
    },
  },
  {
    contentId: 'header',
    name: 'Header',
    dml: LifestyleHeader,
    type: 'Lifestyle',
    default: {
      dataset: {
        'lifestyle-header-image-imgurl-group3':
          'https://assets.opensourceemails.com/imgs/lifestyle/binu6fnar8unlra4bqse_osetu-product-header.jpg',
        'lifestyle-header-subtitle-text-group1': 'Small Headline',
        'lifestyle-header-title-text-group1': 'Large Headline',
        'lifestyle-header-buttonurl-group2': '#',
        'lifestyle-header-buttontext-group2': 'Click Here',
      },
    },
  },
  {
    contentId: 'button',
    name: 'Button',
    dml: getHandlebarsFriendlyTemplates(LifestyleButton, 'lifestyle'),
    type: 'Lifestyle',
    default: {
      dataset: getHandlebarsFriendlyTemplates(LifestyleButton, 'lifestyle', true),
    },
  },
  {
    contentId: 'divider',
    name: 'Divider',
    dml: getHandlebarsFriendlyTemplates(LifestyleDivider, 'lifestyle'),
    type: 'Lifestyle',
    default: {
      dataset: getHandlebarsFriendlyTemplates(LifestyleDivider, 'lifestyle', true),
    },
  },
  {
    contentId: 'one-column-image',
    name: 'One Column Image',
    dml: getHandlebarsFriendlyTemplates(LifestyleOneColumnImage, 'lifestyle'),
    type: 'Lifestyle',
    default: {
      dataset: getHandlebarsFriendlyTemplates(
        LifestyleOneColumnImage,
        'lifestyle',
        true
      ),
    },
  },
  {
    contentId: 'subhead',
    name: 'Subheader',
    dml: getHandlebarsFriendlyTemplates(LifestyleSubhead, 'lifestyle'),
    type: 'Lifestyle',
    default: {
      dataset: getHandlebarsFriendlyTemplates(LifestyleSubhead, 'lifestyle', true),
    },
  },
  {
    contentId: 'text',
    name: 'Text',
    dml: getHandlebarsFriendlyTemplates(LifestyleText, 'lifestyle'),
    type: 'Lifestyle',
    default: {
      dataset: getHandlebarsFriendlyTemplates(LifestyleText, 'lifestyle', true),
    },
  },
  {
    contentId: 'two-image-column',
    name: 'Two Column Image',
    dml: getHandlebarsFriendlyTemplates(LifestyleTwoImageColumn, 'lifestyle'),
    type: 'Lifestyle',
    default: {
      dataset: getHandlebarsFriendlyTemplates(
        LifestyleTwoImageColumn,
        'lifestyle',
        true
      ),
    },
  },
  {
    contentId: 'footer',
    name: 'Footer',
    dml: LifestyleFooter,
    type: 'Lifestyle',
    default: {
      dataset: {
        'lifestyle-footer-title-text-group1': '© Copyright 2019 Company Name ',
        'lifestyle-footer-one-buttonurl-group2': '#',
        'lifestyle-footer-one-buttontext-group2': 'Privacy Policy',
        'lifestyle-footer-two-buttonurl-group2': '#',
        'lifestyle-footer-two-buttontext-group2': 'Terms of Use',
        'lifestyle-footer-three-buttonurl-group2': '#',
        'lifestyle-footer-three-buttontext-group2': 'Unsubscribe',
        'lifestyle-footer-fb-imgurl-group3':
          'https://assets.opensourceemails.com/imgs/lifestyle/Fct0c2xMRUKPHBdMCcnf_icon-facebook.png',
        'lifestyle-footer-fb-alt-group3': 'Facebook',
        'lifestyle-footer-ln-imgurl-group3':
          'https://assets.opensourceemails.com/imgs/lifestyle/BHraIlyShi7koHdeMEbD_icon-linkedin.png',
        'lifestyle-footer-ln-alt-group3': 'Linked In',
        'lifestyle-footer-tw-imgurl-group3':
          'https://assets.opensourceemails.com/imgs/lifestyle/Rc7jq7J2ToyH0IGSptTY_icon-twitter.png',
        'lifestyle-footer-tw-alt-group3': 'Twitter',
      },
    },
  },
];

export default AllLifestyleBlocks;
