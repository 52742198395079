export const MarkHotKey = (options) => {
  const { type, key } = options;

  // Return our "plugin" object, containing the `onKeyDown` handler.
  return {
    onKeyDown(event, editor, next) {
      // If it doesn't match our `key`, let other plugins handle it.
      if ((!event.ctrlKey && !event.metaKey) || event.key !== key) {
        return next();
      }

      // Prevent the default characters from being inserted.
      event.preventDefault();

      // Toggle the mark `type`.
      editor.toggleMark(type);
    },
  };
};

export const MaxLength = (options) => {
  const { maxLength } = options;

  const permittedKeys = (event) => {
    // backspace key - keyCode 8 - allow typing if deleting
    if (event.keyCode === 8) {
      return true;
    }

    // Ctrl (or 'special' key on Macs) + 'A' allow select-all
    if (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) {
      return true;
    }

    return false;
  };

  return {
    onKeyDown(event, editor, next) {
      if (permittedKeys(event)) {
        return next();
      }
      if (maxLength && editor.value.document.text.length > maxLength) {
        event.preventDefault();
      }
      return next();
    },
  };
};
