const HeaderBar = `
<dys-block>
    <dys-row background-color='#30373b' padding='2px' full-width='full-width'>
        <dys-column width='60%'>
        <dys-text font-family='Open Sans, Arial, sans-serif' font-size='12px' line-height='18px' color='#848789' text-decoration='none' editable='header-snippet-text'>
            {{{ mantra-header-text-group1 }}}
        </dys-text>
        </dys-column>
        <dys-column width='40%'>
        <dys-text align='right' font-family='Open Sans, Arial, sans-serif' font-size='12px' line-height='18px' color='#848789' text-decoration='none'>
            <a href='{{ mantra-view-online-buttonurl-group2 }}' target='_blank' style='text-decoration:underline; color:#848789;' editable='header-view-online'>{{ mantra-view-online-buttontext-group2 }}</a>
        </dys-text>
        </dys-column>
    </dys-row>
</dys-block>
`;

export default HeaderBar;
