import React, { PureComponent } from 'react';

import Block from '../Block';

export default class BlockLibraryBlockList extends PureComponent {
  render() {
    return this.props.blocks.map((block, index) => (
      <Block
        key={block.id}
        block={block}
        dml={block.dml}
        index={index}
        area={this.props.area}
      />
    ));
  }
}
