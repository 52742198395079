const TitleAndSubtitle = `
<dys-block>
  <dys-row>
    <dys-column>
      <dys-text align='center' font-family='Open Sans, Arial, sans-serif' font-size='18px' line-height='20px' font-weight='bold' color='#feae39' editable='orange-title'>
        {{{ mantra-tas-title-text-group1 }}}
      </dys-text>
      <dys-text align='center' font-family='Open Sans, Arial, sans-serif' font-size='18px' line-height='20px' color='#feae39' editable='orange-subtitle'>
        {{{ mantra-tas-subtitle-text-group1 }}}
      </dys-text>
    </dys-column>
  </dys-row>
</dys-block>
`;

export default TitleAndSubtitle;
