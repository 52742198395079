import React, { PureComponent } from 'react';
import AppDataStore from '../../store/StateStore';
import { Provider, Subscribe } from 'unstated';

import {
  StyledInput,
  Label,
  InlineLabel,
  Container,
  StyledButton,
  Error,
  Message,
} from './Styles';

class Submit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      onClick: '',
    };
  }

  render() {
    return (
      <StyledButton
        value={this.props.value}
        type="submit"
        onClick={this.props.onClick}
      />
    );
  }
}

class Input extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      type: 'text',
      maxLength: 40,
      size: 30,
      value: '',
      onChange: null,
      name: '',
    };
  }

  render() {
    return (
      <StyledInput
        maxLength={this.props.maxLength}
        type={this.props.type}
        size={this.props.size}
        value={this.props.value}
        onChange={this.props.onChange}
        name={this.props.name}
      />
    );
  }
}

class RadioInput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      label: '',
    };
  }

  render() {
    return (
      <Container style={{ display: 'inline' }}>
        <InlineLabel style={{ fontSize: '16px', lineHeight: '32px' }}>
          <StyledInput
            {...this.props}
            type="radio"
            style={{ display: 'inline', width: 'auto', margin: '0 5px 0 0' }}
          />
          {this.props.label}
        </InlineLabel>
      </Container>
    );
  }
}

class TextInput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      label: '',
    };
  }
  render() {
    return (
      <Container>
        <Label>{this.props.label}</Label>
        <Input {...this.props} />
      </Container>
    );
  }
}

class EmailInput extends PureComponent {
  render() {
    return (
      <Container>
        <Label>Email *</Label>
        <Input type="email" {...this.props} />
      </Container>
    );
  }
}

class EmailCapture extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      first_name: props.first_name,
      last_name: props.last_name,
      email: props.email,
      optin: props.optin,
      errors: {
        first_name: '',
        last_name: '',
        email: '',
      },
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    let errors = this.validateInputs(name, value);

    this.setState({ errors, [name]: value });
  }

  validateInputs(name, value) {
    let errors = this.state.errors;

    switch (name) {
      case 'first_name':
        errors.first_name = value === '' ? 'Please put in your first name' : '';
        break;
      case 'last_name':
        errors.last_name = value === '' ? 'Please put in your last name' : '';
        break;
      case 'email':
        errors.email = value === '' ? 'Please put in your email' : '';
        break;
      default:
        break;
    }

    return errors;
  }

  validateForm(state) {
    let errors = state.errors;
    let valid = true;

    // Ensure that inputs are valid
    Object.keys(state).forEach((k) => (errors = this.validateInputs(k, state[k])));

    // Ensure there are no errors
    Object.values(errors).forEach((v) => v !== '' && (valid = false));

    // Make a copy of the current state set that as the new value.
    const errorStateCopy = Object.assign({}, state.errors);

    this.setState({
      errors: errorStateCopy,
    });

    return valid;
  }

  handleOptionChange(event) {
    const option = event.target.value === 'true' ? true : false;
    this.setState({ optin: option });
  }

  render() {
    const { first_name, last_name, email, errors } = this.state;
    return (
      <Provider>
        <div>
          <div style={{ display: 'inline-block', width: '50%' }}>
            <TextInput
              name="first_name"
              label="First Name *"
              value={first_name}
              onChange={this.handleInputChange}
            />
            {errors.first_name !== '' && (
              <Error style={{ float: 'left' }}>{errors.first_name}</Error>
            )}
          </div>
          <div style={{ display: 'inline-block', width: '50%', float: 'right' }}>
            <TextInput
              name="last_name"
              label="Last Name *"
              value={last_name}
              onChange={this.handleInputChange}
            />
            {errors.last_name !== '' && <Error>{errors.last_name}</Error>}
          </div>

          <EmailInput name="email" value={email} onChange={this.handleInputChange} />
          {errors.email !== '' && <Error>{errors.email}</Error>}
          <Message style={{ opacity: '.8' }}>
            Yes, I'd like to receive an email telling me more about how Dyspatch can
            help me create impactful emails without writing code
          </Message>
          <RadioInput
            label="Yes"
            value="true"
            checked={this.state.optin === true}
            onChange={this.handleOptionChange}
          />
          <RadioInput
            label="No"
            value="false"
            checked={this.state.optin === false}
            onChange={this.handleOptionChange}
          />
          <Subscribe to={[AppDataStore]}>
            {(appData) => (
              <Submit
                value="Get HTML Template"
                onClick={() =>
                  this.validateForm(this.state) &&
                  appData.handleExportClick(
                    this.state.email,
                    this.state.first_name,
                    this.state.last_name,
                    this.state.optin,
                  )
                }
              />
            )}
          </Subscribe>
          <Message style={{ fontSize: '10px', lineHeight: '1', opacity: '.5' }}>
            Your privacy is important to us. Your information will remain
            confidential and we will only email you if you choose to opt-in above.
            &nbsp;
            <a href="https://www.dyspatch.io/legal/privacy-policy/">
              Privacy Policy
            </a>
          </Message>
        </div>
      </Provider>
    );
  }
}

export default EmailCapture;
