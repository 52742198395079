const Invite = `
<dys-block>
  <dys-row full-width='full-width' background-color='#f7f7f7'>
    <dys-column width='90%' padding='45px 75px' background-color='#fff' border='1px solid #ccc'>
      <dys-img alt='{{ oxygen-invite-image-alt-group3 }}' width='130px' border-radius='5px' border='1px solid #ccc' src='{{ oxygen-invite-image-imgurl-group3 }}' editable='image' />     
      <dys-text color='#777777' line-height='21px' font-size='14px' align='center' font-family='Oxygen, Helvetica neue, sans-serif'>
	  	<dys-html>
	    	<a href='{{ oxygen-invite-profile-buttonurl-group2 }}' style='display:block; color: #ff6f6f; font-weight: bold; text-decoration: none;' editable='link '>{{{ oxygen-invite-profile-name-buttontext-group2 }}}</a>
	    </dys-html>
	    <span editable='text'>{{{ oxygen-invite-profile-paragraph-text-group1 }}}</span>
      </dys-text>
      <dys-button href='{{ oxygen-invite-button-buttonurl-group2 }}' line-height='21px' font-family='Oxygen, Helvetica neue, sans-serif' font-weight='400' border-radius='5px' font-size='14px' background-color='#ff6f6f' editable='button'>
        {{{ oxygen-invite-button-buttontext-group2 }}}
      </dys-button>      
    </dys-column>
  </dys-row>
</dys-block>
`;

export default Invite;
