const Button = `
<dys-block>
  <dys-row padding="20px">
    <dys-column>
      <dys-button
        align="left"
        font-size="22px"
        border-radius="0px"
        background-color="BLACK"
        editable="header-button"
      >
        Click Here Now</dys-button
      >
    </dys-column>
  </dys-row>
</dys-block>
`;

export default Button;
