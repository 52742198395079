import OxygenButtonOnGrey from './ButtonOnGrey';
import OxygenHeader from './Header';
import OxygenInfo from './Info';
import OxygenReceipt from './Receipt';
import OxygenTitleOnGrey from './TitleOnGrey';
import OxygenButtonOnWhite from './ButtonOnWhite';
import OxygenCoupon from './Coupon';
import OxygenFooter from './Footer';
import OxygenImagesFeed from './ImagesFeed';
import OxygenInvite from './Invite';
import OxygenProductCard from './ProductCard';
import OxygenProgress from './Progress';
import OxygenSectionTitle from './SectionTitle';
import OxygenSurvey from './Survey';
import OxygenTitleOnWhite from './TitleOnWhite';

import { getHandlebarsFriendlyTemplates } from './../../utils/MakeDmlHandlebars';

const AllOxygenBlocks = [
  {
    contentId: 'header',
    name: 'Header',
    dml: OxygenHeader,
    type: 'Oxygen',
    default: {
      dataset: {
        'oxygen-logo-imgurl-group3':
          'https://swu-cs-assets.s3.amazonaws.com/OSET/oxy-logo.png',
        'oxygen-logo-alt-group3': 'Logo',
        'oxygen-logo-buttonurl-group3': '#',
        'oxygen-twitter-imgurl-group3':
          'https://s3.amazonaws.com/swu-cs-assets/OSET/social/Twitter_grey.png',
        'oxygen-twitter-alt-group3': 'Twitter',
        'oxygen-twitter-buttonurl-group3': '#',
        'oxygen-facebook-imgurl-group3':
          'https://swu-cs-assets.s3.amazonaws.com/OSET/social/f_grey.png',
        'oxygen-facebook-alt-group3': 'Facebook',
        'oxygen-facebook-buttonurl-group3': '#',
        'oxygen-insta-imgurl-group3':
          'https://swu-cs-assets.s3.amazonaws.com/OSET/social/instagrey.png',
        'oxygen-insta-alt-group3': 'Instagram',
        'oxygen-insta-buttonurl-group3': '#',
      },
    },
  },
  {
    contentId: 'button-on-grey',
    name: 'Button On Grey',
    dml: getHandlebarsFriendlyTemplates(OxygenButtonOnGrey, 'oxygen'),
    type: 'Oxygen',
    default: {
      dataset: getHandlebarsFriendlyTemplates(OxygenButtonOnGrey, 'oxygen', true),
    },
  },
  {
    contentId: 'button-on-white',
    name: 'Button On White',
    dml: getHandlebarsFriendlyTemplates(OxygenButtonOnWhite, 'oxygen'),
    type: 'Oxygen',
    default: {
      dataset: getHandlebarsFriendlyTemplates(OxygenButtonOnWhite, 'oxygen', true),
    },
  },
  {
    contentId: 'title-on-grey',
    name: 'Title On Grey',
    dml: getHandlebarsFriendlyTemplates(OxygenTitleOnGrey, 'oxygen'),
    type: 'Oxygen',
    default: {
      dataset: getHandlebarsFriendlyTemplates(OxygenTitleOnGrey, 'oxygen', true),
    },
  },
  {
    contentId: 'info',
    name: 'Info',
    dml: getHandlebarsFriendlyTemplates(OxygenInfo, 'oxygen'),
    type: 'Oxygen',
    default: {
      dataset: getHandlebarsFriendlyTemplates(OxygenInfo, 'oxygen', true),
    },
  },
  {
    contentId: 'receipt',
    name: 'Receipt',
    dml: OxygenReceipt,
    type: 'Oxygen',
    default: {
      dataset: {
        'setup-dyspatch-to-use': 'definition',
      },
    },
  },
  {
    contentId: 'coupon',
    name: 'Coupon',
    dml: OxygenCoupon,
    type: 'Oxygen',
    default: {
      dataset: {
        'oxygen-coupon-one-text-group1':
          'As a token of our appreciation, please accept this coupon code for',
        'oxygen-coupon-styled-text-group1': '40% off',
        'oxygen-coupon-two-text-group1': ' your next purchase!',
        'oxygen-coupon-code-text-group1': 'A1w3EG2gK82d2',
        'oxygen-coupon-button-buttontext-group2': 'Shop Now',
        'oxygen-coupon-button-buttonurl-group2': '# Coupon',
      },
    },
  },
  {
    contentId: 'footer',
    name: 'Footer',
    dml: getHandlebarsFriendlyTemplates(OxygenFooter, 'oxygen'),
    type: 'Oxygen',
    default: {
      dataset: getHandlebarsFriendlyTemplates(OxygenFooter, 'oxygen', true),
    },
  },
  {
    contentId: 'images-feed',
    name: 'Image Feed',
    dml: getHandlebarsFriendlyTemplates(OxygenImagesFeed, 'oxygen'),
    type: 'Oxygen',
    default: {
      dataset: getHandlebarsFriendlyTemplates(OxygenImagesFeed, 'oxygen', true),
    },
  },
  {
    contentId: 'invite',
    name: 'Invite',
    dml: OxygenInvite,
    type: 'Oxygen',
    default: {
      dataset: {
        'oxygen-invite-image-imgurl-group3':
          'https://assets.opensourceemails.com/imgs/oxygen/Ei7o4zRgT561k4rLfzTz_profile_pic.jpg',
        'oxygen-invite-image-alt-group3': 'Profile Picture',
        'oxygen-invite-profile-name-buttontext-group2': '@First Name',
        'oxygen-invite-profile-buttonurl-group2': '#',
        'oxygen-invite-profile-paragraph-text-group1':
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sed nulla nibh. Ut hendrerit pellentesque justo, semper accumsan nisl venenatis ut.',
        'oxygen-invite-button-buttontext-group2': 'Button Text',
        'oxygen-invite-button-buttonurl-group2': '# Button URL',
      },
    },
  },
  {
    contentId: 'product-card',
    name: 'Product Card',
    dml: getHandlebarsFriendlyTemplates(OxygenProductCard, 'oxygen'),
    type: 'Oxygen',
    default: {
      dataset: getHandlebarsFriendlyTemplates(OxygenProductCard, 'oxygen', true),
    },
  },
  {
    contentId: 'progress',
    name: 'Progress',
    dml: getHandlebarsFriendlyTemplates(OxygenProgress, 'oxygen'),
    type: 'Oxygen',
    default: {
      dataset: getHandlebarsFriendlyTemplates(OxygenProgress, 'oxygen', true),
    },
  },
  {
    contentId: 'section-title',
    name: 'Section Title',
    dml: getHandlebarsFriendlyTemplates(OxygenSectionTitle, 'oxygen'),
    type: 'Oxygen',
    default: {
      dataset: getHandlebarsFriendlyTemplates(OxygenSectionTitle, 'oxygen', true),
    },
  },
  {
    contentId: 'survey',
    name: 'Survey',
    dml: OxygenSurvey,
    type: 'Oxygen',
    default: {
      dataset: {
        'oxygen-rating-title-text-group1': 'Click to rate our services',
        'oxygen-rating-button-one-imgurl-group3':
          'https://s3.amazonaws.com/swu-filepicker/JJ7YmPzNQJujBPJq6yVi_star_03.jpg',
        'oxygen-rating-button-one-alt-group3': 'Alt 1',
        'oxygen-rating-button-one-buttonurl-group3': '# 1',
        'oxygen-rating-button-two-imgurl-group3':
          'https://s3.amazonaws.com/swu-filepicker/JJ7YmPzNQJujBPJq6yVi_star_03.jpg',
        'oxygen-rating-button-two-alt-group3': 'Alt 2',
        'oxygen-rating-button-two-buttonurl-group3': '# 2',
        'oxygen-rating-button-three-imgurl-group3':
          'https://s3.amazonaws.com/swu-filepicker/JJ7YmPzNQJujBPJq6yVi_star_03.jpg',
        'oxygen-rating-button-three-alt-group3': 'Alt 3',
        'oxygen-rating-button-three-buttonurl-group3': '# 3',
        'oxygen-rating-button-four-imgurl-group3':
          'https://s3.amazonaws.com/swu-filepicker/JJ7YmPzNQJujBPJq6yVi_star_03.jpg',
        'oxygen-rating-button-four-alt-group3': 'Alt 4',
        'oxygen-rating-button-four-buttonurl-group3': '# 4',
        'oxygen-rating-button-five-imgurl-group3':
          'https://s3.amazonaws.com/swu-filepicker/JJ7YmPzNQJujBPJq6yVi_star_03.jpg',
        'oxygen-rating-button-five-alt-group3': 'Alt 5',
        'oxygen-rating-button-five-buttonurl-group3': '# 5',
        'oxygen-rating-title-one-text-group1': 'Unsatisfied',
        'oxygen-rating-title-two-text-group1': 'Neutral',
        'oxygen-rating-title-three-text-group1': 'Satisfied',
      },
    },
  },
  {
    contentId: 'title-on-white',
    name: 'Title On White',
    dml: getHandlebarsFriendlyTemplates(OxygenTitleOnWhite, 'oxygen'),
    type: 'Oxygen',
    default: {
      dataset: getHandlebarsFriendlyTemplates(OxygenTitleOnWhite, 'oxygen', true),
    },
  },
];

export default AllOxygenBlocks;
