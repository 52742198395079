// dys-group not supported dys-table/tr/tr editable not supported

const Confirmation = `
<dys-block>
  <dys-row background-color="#F5774E">
    <dys-column>
      <dys-text
        align="left"
        color="#FFFFFF"
        padding="25px 25px 0 50px"
        editable="white-text"
        font-family="'Droid Sans', 'Helvetica Neue', Arial, sans-serif"
      >
        Bob Erlicious
      </dys-text>
      <dys-text
        align="left"
        color="#933f24"
        padding="0 25px 0 50px"
        editable="address"
        font-family="'Droid Sans', 'Helvetica Neue', Arial, sans-serif"
      >
        123 Flower Drive <br />
        Victoria, BC <br />
        V9P 2E8 <br />
        1(250)222-2232
      </dys-text>
    </dys-column>

    <dys-column>
      <dys-text
        align="right"
        align="right"
        color="#FFFFFF"
        padding="25px 50px 0 25px"
        editable="invoice-number"
        font-family="'Droid Sans', 'Helvetica Neue', Arial, sans-serif"
      >
        Invoice: 00234
      </dys-text>
      <dys-text
        align="right"
        align="right"
        color="#933f24"
        padding="0 50px 0 25px"
        font-family="'Droid Sans', 'Helvetica Neue', Arial, sans-serif"
        editable="date"
      >
        April 3, 2019
      </dys-text>
    </dys-column>
  </dys-row>
  <dys-row background-color="#F5774E">
    <dys-column>
      <dys-table padding="10px 50px" font-size="16px" cellpadding="10">
        <tr align="center">
          <th
            align="center"
            style="font-weight: normal; background-color: #ac4d2f; color: #ffffff;"
          >
            Expected Delivery Date
          </th>
        </tr>
        <tr>
          <td
            style="background-color: #f7a084; color: #933f24; text-align: center;"
          >
            Monday, 13th November 2019
          </td>
        </tr>
      </dys-table>
    </dys-column>
  </dys-row>
  <dys-row background-color="#F5774E" padding="10px 0">
    <dys-column width="90%">
      <dys-text
        align="left"
        color="#933f24"
        editable="content"
        font-family="'Droid Sans', 'Helvetica Neue', Arial, sans-serif"
      >
        Thank you for your business. Please contact us with any questions
        regarding your order.
        <br /><br />
        Awesome Inc
      </dys-text>
    </dys-column>
  </dys-row>
</dys-block>
`;

export default Confirmation;
