import React from 'react';
import styled from 'styled-components';
import { Controlled as CodeMirror } from 'react-codemirror2';
import PadLockIcon from '../assets/images/PadLockIcon';
import './../styles/CodeMirror/codemirror.css';
import './../styles/CodeMirror/codemirrorStyle.css';
import './../styles/CodeMirror/DmlPreviewOverides.css';
import 'codemirror/mode/xml/xml.js';

const PreviewContainer = styled.div`
  width: 564px;
  padding: 0px 31px 15px 31px;
  overflow: auto;
`;

const ReadOnlyTextContainer = styled.div`
  height: 32px;
  background-color:#E0E0E0;
  font-size: 10px;
  line-height: 34px;
  font-weight: 500;
  padding-left: 16px;
  margin-top: 32px;
  letter-spacing: 0.4px;
`;

const ButtonWrapper = styled.div`
  padding-top: 24px;
  float: right;
`;

const DMLCodeMirrorContainer = (({ dml }) => {
  return (
    <CodeMirror
      className="CodeMirrorDMLPreview"
      value={dml}
      options={{
        mode: 'xml',
        theme: 'lesser-dark',
        lineNumbers: true,
        lineWrapping: true,
        readOnly: true,
        gutters: ['CodeMirror-lint-markers'],
      }}
    />)
});

const DMLPreview = ({ id, blocks }) => {
  const [copyButtonText, setCopyButtonText] = React.useState("Copy Code");
  const [copyButtonClassName, setCopyButtonClassName] = React.useState("btn btn-outline-DMLPreview btn-md")
  const [openInPlaygroundText, setOpenInPlaygroundText] = React.useState("Open in DML Playground");

  const copyToClipboard = (str) => {
    try {
      navigator.clipboard.writeText(str).then(() => {
        // Success!
        setCopyButtonText("Copied!");
        setCopyButtonClassName("btn btn-outline-success btn-md");
        resetCopyButton();
      }, () => {
        // Failed!
        setCopyButtonText("Something went wrong...");
        setCopyButtonClassName("btn btn-outline-danger btn-md");
        resetCopyButton();
      });
    } catch (error) {
      // Prevents problems if clipboard access is denied.
      setCopyButtonText("Something went wrong...");
      setCopyButtonClassName("btn btn-outline-danger btn-md");
      resetCopyButton();
    }

  };

  const resetCopyButton = () => {
    // Set Copy Code button back to "Copy Code", and primary style after 2.5s
    setTimeout(() => {
      setCopyButtonText("Copy Code");
      setCopyButtonClassName("btn btn-outline-DMLPreview btn-md");
    }, 2500);
  }

  const openInDMLPlayground = (dmlCode, dmlData) => {
    setOpenInPlaygroundText("Opening in new tab...");
    var xhr = new XMLHttpRequest();
    xhr.addEventListener('load', () => {
      let response = JSON.parse(xhr.responseText);
      if (response.token !== undefined) {
        window.open(`https://dml-playground.dyspatch.io/${response.token}`, '_blank');
        setOpenInPlaygroundText("Open in DML Playground");
      } else {
        setOpenInPlaygroundText("Something went wrong!");
      }
    });
    // Replace {{}} & {{{}}} with @{} for DML Playground rendering to work.
    let processedDML = dmlCode.replaceAll(/\{{2,3}\s*/g, "@{").replaceAll(/\s*\}{2,3}/g, "}");
    xhr.open('POST', 'https://q9ixhcuyuf.execute-api.us-west-2.amazonaws.com/production', true);
    xhr.send(JSON.stringify({
      DMLEditor: processedDML,
      sampleData: dmlData
    }));
  };

  let render = false;

  // Find the selected block in the array of blocks given.
  // The `i` var here is used further down to actually select the block (blocks[i])
  if (id !== undefined && blocks !== undefined && blocks.length > 0) {
    for (var i = 0, len = blocks.length; i < len; i++) {
      if (blocks[i].id === id) {
        render = true;
        break;
      }
    }
  }
  if (render) { // Block found
    return (
      <PreviewContainer key={id}>
        <ReadOnlyTextContainer><PadLockIcon /> READ-ONLY</ReadOnlyTextContainer>
        <DMLCodeMirrorContainer dml={blocks[i].dml} />
        <ButtonWrapper>
          <span
            style={{ marginRight: '16px', padding: '12px 16px', lineHeight: '16px' }}
            className={copyButtonClassName}
            role="button"
            onClick={() => { copyToClipboard(blocks[i].dml.toString()) }}
          >
            {copyButtonText}
          </span>
          <span
            style={{ padding: '12px 16px', lineHeight: '16px' }}
            className="btn btn-outline-DMLPreview btn-md"
            role="button"
            onClick={() => { openInDMLPlayground(blocks[i].dml.toString(), JSON.stringify(blocks[i].default.dataset)) }}
          >
            {openInPlaygroundText}
          </span>
        </ButtonWrapper>
      </PreviewContainer>
    )
  }
  // Block not found
  return (
    // In theory this should never happen, But if it does, 
    // we'll render something to give the user an idea of what should be here.

    // This render can be seen for a split second if you remove a block, 
    // while viewing the DML Preview
    <PreviewContainer key={id}>
      <ReadOnlyTextContainer><PadLockIcon /> READ-ONLY</ReadOnlyTextContainer>
      <DMLCodeMirrorContainer dml="" />
    </PreviewContainer>
  )

}

export default DMLPreview;
