const TitleOnGrey = `
<dys-block>
  <dys-row full-width='full-width' background-color='#f7f7f7'>
    <dys-column>
      <dys-text color='#4d4d4d' line-height='37px' font-weight='700' font-size='32px' align='center' font-family='Oxygen, Helvetica neue, sans-serif' editable='title'>
        Lorem ipsum dolor
      </dys-text>      
      <dys-text color='#777777' line-height='21px' font-size='14px' align='center' font-family='Oxygen, Helvetica neue, sans-serif' editable='subtitle'>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sed nulla nibh. Ut hendrerit pellentesque justo, semper accumsan nisl venenatis ut.
      </dys-text>
    </dys-column>
  </dys-row>
</dys-block>
`;

export default TitleOnGrey;
