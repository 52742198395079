const Receipt = `
<dys-block>
  <dys-row background-color='#FFFFFF'>
    <dys-column>
      <dys-table font-family="'Oxygen', 'Helvetica Neue', helvetica, sans-serif" font-size='14px' color='#777777' line-height='21px'>
        <tr>
          <th width='50%' style='text-align: left; border-bottom: 1px solid #cccccc; color: #4d4d4d; font-weight: 700; padding-bottom: 5px;'>
            Item
          </th>            
          <th width='15%' style='text-align: right; border-bottom: 1px solid #cccccc; color: #4d4d4d; font-weight: 700; padding-bottom: 5px;'>
            Qty
          </th>
          <th width='15%' style='text-align: right; border-bottom: 1px solid #cccccc; color: #4d4d4d; font-weight: 700; padding-bottom: 5px; '>
            Total
          </th>
        </tr>
      </dys-table>
      <dys-table>
        <tr style="font-size:14px; line-height:19px; font-family: 'Oxygen', 'Helvetica Neue', helvetica, sans-serif; color:#777777">
          <td width='50%'>
            <table style="font-size:14px; line-height:19px; font-family: 'Oxygen', 'Helvetica Neue', helvetica, sans-serif;" cellspacing='0' cellpadding='0' width='100%'>
              <tbody>
                <tr>
                  <td width='35%' style='padding-right:5px;'>
                    <dys-img href='#' border=' 1px solid #e6e6e6' border-radius='4px' width='110px' src='https://s3.amazonaws.com/swu-filepicker/RPezUIwPRv8pjatAAH1E_item_images_19.jpg' alt='item1' />
                  </td>
                  <td style="text-align:left; font-size:14px; line-height:19px; font-family: ' oxygen', 'helvetica neue', helvetica, sans-serif; color: #777777;">
                    <span style='color: #4d4d4d; font-weight:bold;'>Golden Earrings</span> <br />
                    City looks
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td width='10%' style='text-align:center; '>
            1
          </td>
          <td width='10%' style='text-align:right; '>
            $3.50
          </td>
        </tr>
        <tr style="font-size:14px; line-height:19px; font-family: 'Oxygen', 'Helvetica Neue', helvetica, sans-serif; color:#777777">
          <td width='50%'>
            <table cellspacing='0' cellpadding='0' width='100%'>
              <tbody>
                <tr>
                  <td width='35%' style='padding-right:5px;'>
                    <dys-img href='#' border=' 1px solid #e6e6e6' border-radius='4px' width='110px' src='https://s3.amazonaws.com/swu-filepicker/9wRy50HQTg2CTyZA5Ozi_item_images_16.jpg' alt='item2' />
                  </td>
                  <td style="text-align:left; font-size:14px; line-height:19px; font-family: ' oxygen', 'helvetica neue', helvetica, sans-serif; color: #777777;">
                    <span style='color: #4d4d4d; font-weight:bold;'>Pink Shoes</span> <br />
                    Newest style
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td width='10%' style='text-align:center; '>
            1
          </td>
          <td width='10%' style='text-align:right; '>
            $10.50
          </td>
        </tr>
</dys-table>        
<dys-table>
        <tr style="font-size:14px; line-height:19px; font-family: 'Oxygen', 'Helvetica Neue', helvetica, sans-serif; color:#777777">
 <td width='50%'></td>
          <td style='text-align:right; padding-right: 10px; border-top: 1px solid #cccccc;'>
            <span style='padding:8px 0px; display: inline-block;'>Subtotal</span> <br />
            <span style='padding-bottom:8px; display: inline-block;'>Tax</span>  <br />
            <span style='padding-bottom:8px; display: inline-block;'>Shipping</span> <br />
            <span style='display: inline-block;font-weight: bold; color: #4d4d4d'>Total</span>
          </td>
          <td style='text-align: right; border-top: 1px solid #cccccc;'>
            <span style='padding:8px 0px; display: inline-block;'>$14.00</span> <br />
            <span style='padding-bottom:8px; display: inline-block;'>$0.75</span>  <br />
            <span style='padding-bottom:8px; display: inline-block;'>$1.00</span>  <br />
            <span style='display: inline-block;font-weight: bold; color: #4d4d4d'>$15.75</span>
          </td>
        </tr>  

      </dys-table>
    </dys-column>
  </dys-row>
</dys-block>
`;

export default Receipt;
