import uuid from 'uuid/v4';

import AllMantraBlocks from './../blocks/Mantra/AllMantraBlocks';
import AllOxygenBlocks from './../blocks/Oxygen/AllOxygenBlocks';
import AllLifestyleBlocks from './../blocks/Lifestyle/AllLifestyleBlocks';
import AllNeopolitanBlocks from './../blocks/Neopolitan/AllNeopolitanBlocks';

const MantraBlocks = AllMantraBlocks.map((block) => ({ id: uuid(), ...block }));
const OxygenBlocks = AllOxygenBlocks.map((block) => ({ id: uuid(), ...block }));
const LifestyleBlocks = AllLifestyleBlocks.map((block) => ({
  id: uuid(),
  ...block,
}));
const NeopolitanBlocks = AllNeopolitanBlocks.map((block) => ({
  id: uuid(),
  ...block,
}));

export const getDefaultMantraBlocks = () => {
  return [
    ...JSON.parse(JSON.stringify(MantraBlocks)), // Spread deep copy of elements
  ];
};

export const getDefaultOxygenBlocks = () => {
  return [
    ...JSON.parse(JSON.stringify(OxygenBlocks)), // Spread deep copy of elements
  ];
};

export const getDefaultLifestyleBlocks = () => {
  return [
    ...JSON.parse(JSON.stringify(LifestyleBlocks)), // Spread deep copy of elements
  ];
};

export const getDefaultNeopolitanBlocks = () => {
  return [
    ...JSON.parse(JSON.stringify(NeopolitanBlocks)), // Spread deep copy of elements
  ];
};
