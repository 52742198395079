const Rating = `
<dys-block>
  <dys-row border='1px solid #feae39'>
    <dys-column width='90%'>

      <dys-text align='center' font-family='Open Sans, Arial, sans-serif' font-size='18px' font-weight='bold' line-height='20px' text-transform='uppercase' color='#feae39' editable='rating-title'>
        <a href='{{ mantra-rating-title-buttonurl-group2 }}' target='_blank' style='color:#feae39; text-decoration:none;' editable='rating-title'>{{{ mantra-rating-title-buttontext-group2 }}}</a> 
      </dys-text>

      <dys-table align='center' padding-bottom='5px' table-layout='fixed' color='#ffffff' font-family='Open Sans, Arial, sans-serif' font-size='17px' font-weight='bold'>
        <tr>
          <td align='center'>
            <dys-button href='{{ mantra-rating-button-one-buttonurl-group2 }}' padding='0' inner-padding='0px' border-radius='0px' target='_blank' text-decoration='none' width='45px' background-color='#feae39' height='45px' font-size='17px' font-weight='bold' color='#ffffff' editable='option-one'>{{{ mantra-rating-button-one-buttontext-group2 }}}
            </dys-button>
          </td>
          <td align='center'>
            <dys-button href='{{ mantra-rating-button-two-buttonurl-group2 }}' padding='0' inner-padding='0px' border-radius='0px' target='_blank' text-decoration='none' width='45px' background-color='#feae39' height='45px' font-size='17px' font-weight='bold' color='#ffffff' editable='option-two'>{{{ mantra-rating-button-two-buttontext-group2 }}}
            </dys-button>
          </td>          
          <td align='center'>
            <dys-button href='{{ mantra-rating-button-three-buttonurl-group2 }}' padding='0' inner-padding='0px' border-radius='0px' target='_blank' text-decoration='none' width='45px' background-color='#feae39' height='45px' font-size='17px' font-weight='bold' color='#ffffff' editable='option-three'>{{{ mantra-rating-button-three-buttontext-group2 }}}
            </dys-button>
          </td>          
          <td align='center'>
            <dys-button href='{{ mantra-rating-button-four-buttonurl-group2 }}' padding='0' inner-padding='0px' border-radius='0px' target='_blank' text-decoration='none' width='45px' background-color='#feae39' height='45px' font-size='17px' font-weight='bold' color='#ffffff' editable='option-four'>{{{ mantra-rating-button-four-buttontext-group2 }}}
            </dys-button>
          </td>          
          <td align='center'>
            <dys-button href='{{ mantra-rating-button-five-buttonurl-group2 }}' padding='0' inner-padding='0px' border-radius='0px' target='_blank' text-decoration='none' width='45px' background-color='#feae39' height='45px' font-size='17px' font-weight='bold' color='#ffffff' editable='option-five'>{{{ mantra-rating-button-five-buttontext-group2 }}}
            </dys-button>
          </td>
        </tr>      
      </dys-table>

    </dys-column>
  </dys-row>
</dys-block>
`;

export default Rating;
