import React from 'react';
import styled from 'styled-components';
import BannerImg from './../assets/images/BannerImg.png';

import { BannerButton } from './../styles/Buttons/ButtonStyles';

const BannerContainer = styled.div`
  position: fixed;
  bottom:0;
  width:100%
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px;
  background: #8DEDE8;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.25);
  marginBottom: 36  
  img {
    align-self: flex-end;
    width: 30%;
    max-width: 200px;
  }
`;

const BannerText = styled.h1`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #0D355E;
  padding: 5px 20px;
`;

const Banner = () => (
  <BannerContainer>
    <img src={BannerImg} alt="The Dyspatch visual editor" />
    <BannerText>
      {'Start a Trial to Experience Custom Themes, Testing & More'}
    </BannerText>
    <BannerButton
      target="_blank"
      href="https://app.dyspatch.io/signup/?utm_ref=playground"
    >
      Start your free account{' '}
    </BannerButton>
  </BannerContainer>
);

export default Banner;
