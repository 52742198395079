const OpeningText = `
<dys-block>
  <dys-row>
    <dys-column>
      <dys-text align='center' font-family='Open Sans, Arial, sans-serif' font-size='15px' line-height='18px' font-weight='bold' color='#30373b' editable='welcome-text'>
            {{{ mantra-opening-text-group1 }}}
      </dys-text>
    </dys-column>
  </dys-row>
</dys-block>
`;

export default OpeningText;
