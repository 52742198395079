const TwoImageColumn = `
<dys-block>
  <dys-row padding="10px 20px">
    <dys-column>
      <dys-img
        src="https://assets.opensourceemails.com/imgs/lifestyle/aijYy0SVQKSSTlLAJfBK_product-watch.jpg"
        editable="pr1img"
      />
      <dys-text
        font-size="18px"
        font-weight="light"
        line-height="28px"
        editable="pr1txt"
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam.
      </dys-text>
      <dys-button
        align="left"
        font-size="18px"
        border-radius="0px"
        background-color="BLACK"
        editable="pr1btn"
      >
        Click Here Now</dys-button
      >
    </dys-column>

    <dys-column>
      <dys-img
        src="https://assets.opensourceemails.com/imgs/lifestyle/HzzPW0TUSwSE64ldofBd_product-laptop.jpg"
        editable="pr2img"
      />
      <dys-text
        font-size="18px"
        font-weight="light"
        line-height="28px"
        editable="pr2txt"
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam.
      </dys-text>
      <dys-button
        align="left"
        font-size="18px"
        border-radius="0px"
        background-color="BLACK"
        editable="pr2btn"
      >
        Click Here Now</dys-button
      >
    </dys-column>
  </dys-row>
</dys-block>
`;

export default TwoImageColumn;
