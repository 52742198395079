const Footer = `
<dys-block>
  <dys-row padding="50px" full-width="full-width" background-color="black">
    <dys-column>
      <dys-table cellpadding="0" cellspacing="0" padding="0px">
        <tr>
          <td align="left">
            <dys-text color="#666" font-size="12px" line-height="28px">
              <p>
                {{{ lifestyle-footer-title-text-group1 }}}
                <br />
                <a href="{{ lifestyle-footer-one-buttonurl-group2 }}" style="color: #666;"
                  >{{ lifestyle-footer-one-buttontext-group2 }}</a
                >
                <br />
                <a href="{{ lifestyle-footer-two-buttonurl-group2 }}" style="color: #666;"
                  >{{ lifestyle-footer-two-buttontext-group2 }}</a
                >
                <br />
                <a href="{{ lifestyle-footer-three-buttonurl-group2 }}" style="color: #666;"
                  >{{ lifestyle-footer-three-buttontext-group2 }}</a
                >
              </p>
            </dys-text>
          </td>
          <td
            align="right"
            style="vertical-align:top; opacity: 0.35;"
            width="34px"
          >
            <dys-img
              src="{{ lifestyle-footer-fb-imgurl-group3 }}"
              alt="{{ lifestyle-footer-fb-alt-group3 }}"
              align="right"
              width="30px"
              editable="social-1"
            />
          </td>
          <td
            align="right"
            width="34px"
            style="vertical-align:top; opacity: 0.35;"
          >
            <dys-img
              src="{{ lifestyle-footer-ln-imgurl-group3 }}"
              alt="{{ lifestyle-footer-ln-alt-group3 }}"
              align="right"
              width="30px"
              editable="social-2"
            />
          </td>
          <td
            align="right"
            width="34px"
            style="vertical-align:top; opacity: 0.35;"
          >
            <dys-img
              src="{{ lifestyle-footer-tw-imgurl-group3 }}"
              alt="{{ lifestyle-footer-tw-alt-group3 }}"
              align="right"
              width="30px"
              editable="social-3"
            />
          </td>
        </tr>
      </dys-table>
    </dys-column>
  </dys-row>
</dys-block>
`;

export default Footer;
