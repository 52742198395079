const Logo = `
<dys-block>
  <dys-row padding="40px">
    <dys-column>
      <dys-table cellpadding="0" cellspacing="0" padding="0px">
        <tr>
          <td align="left">
            <dys-img
              src="https://assets.opensourceemails.com/imgs/lifestyle/cdykUp9mR0aRosKeEEcs_logo%20product%20company.png"
              width="250px"
              align="left"
              editable="logo"
            />
          </td>
          <td align="right" style="vertical-align:bottom;" width="34px">
            <dys-img
              src="https://assets.opensourceemails.com/imgs/lifestyle/Fct0c2xMRUKPHBdMCcnf_icon-facebook.png"
              align="right"
              width="30px"
              editable="social-1"
            />
          </td>
          <td align="right" width="34px" style="vertical-align:bottom;">
            <dys-img
              src="https://assets.opensourceemails.com/imgs/lifestyle/BHraIlyShi7koHdeMEbD_icon-linkedin.png"
              align="right"
              width="30px"
              editable="social-2"
            />
          </td>
          <td align="right" width="34px" style="vertical-align:bottom;">
            <dys-img
              src="https://assets.opensourceemails.com/imgs/lifestyle/Rc7jq7J2ToyH0IGSptTY_icon-twitter.png"
              align="right"
              width="30px"
              editable="social-3"
            />
          </td>
        </tr>
      </dys-table>
    </dys-column>
  </dys-row>
</dys-block>
`;

export default Logo;
