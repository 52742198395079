const Progress = `
<dys-block>
  <!--
>>>>>>>>> CHANGING PROGRESS STEP IMAGES <<<<<<<<<<<<<<<

Pink Step One  - https://assets.opensourceemails.com/imgs/oxygen/iRYXoEbnSQAMzQYRy6hG_v33_03.jpg
White Step One - https://assets.opensourceemails.com/imgs/oxygen/0oYLLViRBKv7a0Lzh6vC_v3_03.jpg


Pink Step Two  - https://assets.opensourceemails.com/imgs/oxygen/YL3H3V5bRyGuy6pess9T_v33_04.jpg
White Step Two - https://assets.opensourceemails.com/imgs/oxygen/piymv8dOQmuAc8nxb4Or_v3_04.jpg


Pink Step Three  - https://assets.opensourceemails.com/imgs/oxygen/caYiXZF9TBS23k4EPXIN_v33_05.jpg
White Step Three - https://assets.opensourceemails.com/imgs/oxygen/YSTlgtgaTSa897tPTUhl_v3_05.jpg
-->  
<!-- Progress Modal Start -->
  <dys-row full-width='full-width' background-color='#f7f7f7'>
    <dys-column padding='75px 0px' width='90%' background-color='#fff' border='1px solid #ccc'>

      <dys-table padding='0px 0px 20px 0px' align='center' width='300px'>
        <tr>

          <!-- STEP ONE -->
          <td width='33%'>
            <dys-img width='100px' src='https://assets.opensourceemails.com/imgs/oxygen/0oYLLViRBKv7a0Lzh6vC_v3_03.jpg' alt='step 1' editable='image1' />
            <p style='text-align:center; font-size: 14px; color: #777777; text-align: center; line-height: 21px;' editable='step1'>Create Account</p>
          </td>

          <!-- STEP TWO -->
          <td width='33%'>
            <dys-img width='100px' src='https://assets.opensourceemails.com/imgs/oxygen/YL3H3V5bRyGuy6pess9T_v33_04.jpg' alt='step 2 highlighted' editable='image2' />
            <p style='text-align:center; font-size: 14px; color: #777777; text-align: center; line-height: 21px;' editable='step2'>Update Info</p>
          </td>

          <!-- STEP THREE -->
          <td width='33%'>
            <dys-img width='100px' src='https://assets.opensourceemails.com/imgs/oxygen/YSTlgtgaTSa897tPTUhl_v3_05.jpg' alt='step 3' editable='image3' />
            <p style='text-align:center; font-size: 14px; color: #777777; text-align: center; line-height: 21px;' editable='step3'>Complete</p>
          </td>

        </tr>

      </dys-table>
      
      <dys-button line-height='21px' font-family='Oxygen, Helvetica neue, sans-serif' font-weight='400' border-radius='5px' font-size='14px' background-color='#ff6f6f' editable='button'>
        Button Text
      </dys-button>    
      
    </dys-column>
  </dys-row>
  <!-- Progress Modal End -->
</dys-block>
`;

export default Progress;
