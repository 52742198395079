const Navigation = `
<dys-block>  
  <dys-row padding='10px' border-top='2px solid #fed69c' border-bottom='2px solid #fed69c'>
    <dys-column padding='0'>
      <dys-text padding='10px 0px' align='center' font-family='Open Sans, Arial, sans-serif' font-size='15px' line-height='18px' color='#30373b' text-transform='uppercase' font-weight='bold'>
        <a href='{{ mantra-nav-title-one-buttonurl-group2 }}' target='_blank' style='text-decoration:none; color:#30373b;' editable='nav-1'>{{{ mantra-nav-title-one-buttontext-group2 }}}</a> 
      </dys-text>
    </dys-column>
    <dys-column padding='0'>
      <dys-text padding='10px 0px' align='center' font-family='Open Sans, Arial, sans-serif' font-size='15px' line-height='18px' color='#30373b' text-transform='uppercase' font-weight='bold'>
        <a href='{{ mantra-nav-title-two-buttonurl-group2 }}' target='_blank' style='text-decoration:none; color:#30373b;' editable='nav-2'>{{{ mantra-nav-title-two-buttontext-group2 }}}</a>
      </dys-text>
    </dys-column>
    <dys-column padding='0'>
      <dys-text padding='10px 0px' align='center' font-family='Open Sans, Arial, sans-serif' font-size='15px' line-height='18px' color='#30373b' text-transform='uppercase' font-weight='bold'>
        <a href='{{ mantra-nav-title-three-buttonurl-group2 }}' target='_blank' style='text-decoration:none; color:#30373b;' editable='nav-3'>{{{ mantra-nav-title-three-buttontext-group2 }}}</a>
      </dys-text>
    </dys-column>
    <dys-column padding='0'>
      <dys-text padding='10px 0px' align='center' font-family='Open Sans, Arial, sans-serif' font-size='15px' line-height='18px' color='#30373b' text-transform='uppercase' font-weight='bold'>
        <a href='{{ mantra-nav-title-four-buttonurl-group2 }}' target='_blank' style='text-decoration:none; color:#30373b;' editable='nav-4'>{{{ mantra-nav-title-four-buttontext-group2 }}}</a>
      </dys-text>
    </dys-column>
  </dys-row>
</dys-block>
`;

export default Navigation;
