import styled from 'styled-components';

export const StyledInput = styled.input`
  border-radius: 3px;
  border: solid 1px #cbcbcb;
  color: #000000;
  font-size: 15px;
  padding: 10px 0;
  text-indent: 15px;
  width: 100%;
  :focus {
    transition: 0.25s box-shadow ease;
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.15);
  }
`;

export const InlineInput = styled.input`
  border-radius: 3px;
  border: solid 1px #cbcbcb;
  color: #000000;
  font-size: 15px;
  padding: 10px 0;
  text-indent: 15px;
  display: inline;
  width: auto;
  :focus {
    transition: 0.25s box-shadow ease;
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.15);
  }
`;

export const Label = styled.label`
  color: #353535;
  font-size 12px !important;
  display: inline-block;
  line-height: 21px;
  margin-bottom: 5px;
  margin-top: 11px;
  padding-left: 0px !important;
  width: 100%;
`;

export const InlineLabel = styled.label`
  background: 0;
  display: inline;
  float: none;
  font-weight: normal;
  line-height: 2em;
  margin-right: 10px;
  padding: 0;
  text-align: left;
  vertical-align: baseline;
`;

export const Container = styled.div`
  padding: 0 10px;
`;

export const StyledButton = styled.input`
  background-color: #1EC2BD;
  border: solid 1px #1EC2BD;
  font-size: 18px;
  color: #ffffff;
  font-weight: bold;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 30px;
  padding-left: 30px;
  border-radius: 50px;
  margin: 15px auto;
  display: block;
  max-width: 100%;
  cursor: pointer;
  transition: 0.25s all ease;
  :hover {
    opacity: 0.8;
    transform: scale(0.95);
    cursor: pointer;
  }
`;

export const Error = styled.span`
  font-size: 14px;
  padding: 5px 10px;
  color: #ee4444;
  display: inline-block;
`;

export const Message = styled.p`
  padding: 10px;
  line-height: 20px;
  width: auto;
`;
