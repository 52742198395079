import React from 'react';

const BackArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10 18"
      width="10"
      height="14"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.05 0a.6.6 0 0 1 .43.18c.1.1.17.26.17.43 0 .18-.06.32-.17.44l-8.02 8 7.9 7.92a.57.57 0 0 1 .2.43.57.57 0 0 1-.2.4.6.6 0 0 1-.42.18.6.6 0 0 1-.43-.17L.2 9.48a.57.57 0 0 1-.2-.43c0-.16.06-.3.18-.42L8.63.18A.6.6 0 0 1 9.05 0z"
      />
    </svg>
  );
};

export default BackArrow;
