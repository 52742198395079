const Text = `
<dys-block>
  <dys-row padding="10px 20px">
    <dys-column>
      <dys-text
        font-size="18px"
        font-weight="light"
        line-height="28px"
        editable="body-text"
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </dys-text>
    </dys-column>
  </dys-row>
</dys-block>
`;

export default Text;
