const UserInvite = `
<dys-block>
<dys-row background-color="#F5774E" padding="10px 0">
  <dys-column width="90%">
    <dys-img
      src="https://assets.opensourceemails.com/imgs/neopolitan/robop_10.png"
      align="center"
      width="100px"
      height="100px"
      padding-bottom="5px"
      editable="img"
    />
    <dys-text
      color="#FFFFFF"
      font-size="12px"
      padding-top="0px"
      editable="user"
      font-family="'Droid Sans', 'Helvetica Neue', Arial, sans-serif"
      align="center"
    >
      User: John Doe
    </dys-text>
    <dys-text
      color="#933f24"
      padding-top="10px"
      font-size="16px"
      editable="content"
      font-family="'Droid Sans', 'Helvetica Neue', Arial, sans-serif"
      align="center"
    >
      I set up an Awesome Inc profile where I can do tons of really awesome
      stuff! I want you to follow me so we can keep in touch.
      <br /><br />
      Because I've invited you, you can now receive
      <strong>20% off</strong> when you sign up!
    </dys-text>
    <dys-button
      background-color="#AC4D2F"
      href="https://example.com"
      editable="button"
      font-family="'Droid Sans', 'Helvetica Neue', Arial, sans-serif"
      font-weight="bold"
      font-size="16px"
      line-height="30px"
      width="200px"
      border-radius="4px"
    >
      Sign Up
    </dys-button>
  </dys-column>
</dys-row>
</dys-block>
`;

export default UserInvite;
