import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Provider, Subscribe } from 'unstated';
import { useParams } from 'react-router-dom';
import { DragDropContext } from 'react-beautiful-dnd';

// Data Store
import AppDataStore from './../../store/StateStore';

import { hasLoaded } from './../../utils/dml/dml';

import './../../App.css';
import Column from '../Column';
import ExportContainer from '../Export/Export';
import { PopupContainer, PopupCard } from './BlockifySuccess';

import {
  CenterContainer,
  Progress,
  ProgressCircle,
} from './../../styles/Loading/LoadingStyles';

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: fixed;
  height: 100%;
`;
const AppContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const VisualEditor = (props) => {
  const [isReady, setIsReady] = useState(hasLoaded());
  const { oset, template } = useParams();
  const [showPopup, setShowPopup] = useState(
    props.appData.state.selectedTemplateName === 'Custom'
  ); // we only want to display the popup in the blockify workflow

  const closePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (hasLoaded()) {
        setIsReady(true);
      }
    }, 1000);

    // Load template blocks based off of URL path
    if (props.history.location.pathname !== '/blockify/visual-editor') {
      props.appData.startRoute(oset, template);
    } else if (props.appData.state.blockifyCount === 0) {
      props.history.push({
        pathname: '/'
      })
    }

    return () => {
      clearInterval(interval);
    };
  }, []);

  return isReady ? (
    <RootContainer>
      {showPopup && (
        <PopupContainer>
          <PopupCard closePopup={closePopup} />
        </PopupContainer>
      )}
      <Provider>
        <Subscribe to={[AppDataStore]}>
          {(appData) => (
            <DragDropContext onDragEnd={appData.onDragEnd}>
              <AppContainer>
                {appData.state.isExporting === true ? (
                  <ExportContainer />
                ) : (
                  <Column
                    key={'block-library'}
                    column={appData.state.initialData.columns['block-library']}
                    blocks={appData.state.initialData.columns[
                      'block-library'
                    ].blockIds.map((item, _index) => item)}
                    area={'block-library'}
                  />
                )}
                {appData.state.isExporting === true ? (
                  ''
                ) : (
                    <Column
                      key={'visual-editor'}
                      column={appData.state.initialData.columns['visual-editor']}
                      blocks={appData.state.initialData.columns[
                        'visual-editor'
                      ].blockIds.map((item, _index) => item)}
                      area={'visual-editor'}
                    />
                )}
              </AppContainer>
              
            </DragDropContext>
            
          )}
        </Subscribe>
      </Provider>
      
    </RootContainer>
  ) : (
    <CenterContainer style={{ width: '100%' }}>
      <Progress>
        <ProgressCircle />
        <ProgressCircle />
        <ProgressCircle />
      </Progress>
    </CenterContainer>
  );
};

export default VisualEditor;
