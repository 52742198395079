import React from 'react';
import styled from 'styled-components';
import { Provider, Subscribe } from 'unstated';
import { useParams } from 'react-router-dom';
import AppDataStore from './../../store/StateStore';
import MantraTemplates from './fixtures/MantraTemplates';
import OxygenTemplates from './fixtures/OxygenTemplates';
import NeopolitanTemplates from './fixtures/NeopolitanTemplates';
import LifestyleTemplates from './fixtures/LifestyleTemplates';

import {
  StartFromScratchContainer,
  Template,
  TemplateImage,
  TemplateName,
  TemplatesContainer,
  TemplateSelectionScreenContainer,
  TemplatesSectionContainer,
} from './../../styles/TemplateSelection/TemplateSelectionStyles';

import { BlueButtonWhiteText } from './../../styles/Buttons/ButtonStyles';

import { StyledLink } from './../../styles/Base/Common';

const TemplateSelectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  resize: none;
`;


function getTemplateFixture(themeName) {
  switch (themeName) {
    case 'mantra':
      return MantraTemplates;
    case 'oxygen':
      return OxygenTemplates;
    case 'neopolitan':
      return NeopolitanTemplates;
    case 'lifestyle':
    default:
      return LifestyleTemplates;
  }
}

const TemplateGroup = () => {
  const { oset } = useParams();
  const templates = getTemplateFixture(oset);
  return (
    <Provider>
      <Subscribe to={[AppDataStore]}>
        {(appData) => (
          <TemplateSelectionContainer>
            <TemplateSelectionScreenContainer>
              <TemplatesSectionContainer>
                {templates.map((template, index) => (
                  <TemplatesContainer key={index}>
                    <TemplateName>{template.name}</TemplateName>
                    <StyledLink
                      to={{
                        pathname: `/${oset}/${template.name}`.toLowerCase(),
                      }}
                      onClick={() => {
                        appData.onClickTemplateSelection(oset);
                      }}
                    >
                      <Template>
                        <TemplateImage
                          src={template.src}
                          alt={template.alt}
                          width="100%"
                        />
                      </Template>
                    </StyledLink>
                  </TemplatesContainer>
                ))}
              </TemplatesSectionContainer>

              <StartFromScratchContainer>
                <BlueButtonWhiteText isAllowed={true}>
                  <StyledLink
                    to={{
                      pathname: `/${oset}/new`.toLowerCase(),
                    }}
                    onClick={() => {
                      appData.onClickTemplateSelection(oset);
                    }}
                    style={{ color: '#ffffff' }}
                  >
                    Create New
                  </StyledLink>
                </BlueButtonWhiteText>
              </StartFromScratchContainer>
            </TemplateSelectionScreenContainer>
          </TemplateSelectionContainer>
        )}
      </Subscribe>
    </Provider>
  );
};

export default TemplateGroup;
