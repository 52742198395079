import { redAlert } from './errors';

export async function loadWasm() {
  try {
    const Go = global.Go;
    const go = new Go();
    const headers = new Headers();
    headers.append('pragma', 'no-cache');
    headers.append('cache-control', 'no-cache');

    const wasmFile = await fetch('/dml.wasm', { method: 'GET', headers });
    const wasmBytes = await wasmFile.arrayBuffer();
    const wasm = await WebAssembly.instantiate(wasmBytes, go.importObject);
    await go.run(wasm.instance);
  } catch (ex) {
    redAlert('Failed to load wasm :(');
    console.error(ex);
    throw ex;
  }
}

export function hasLoaded() {
  return !!window.dml;
}

export function render(blockDml, themeDml) {
  if (hasLoaded()) {
    const renderResult = window.dml.render(blockDml, themeDml);
    return renderResult.html;
  }
  return null;
}

export function renderGroup(blocks) {
  if (hasLoaded()) {
    const renderResult = window.dml.renderGroup(blocks);
    return renderResult.html;
  }
  return null;
}
