const Header = `
<dys-block>
  <dys-row padding='20px 0px 30px 0px' full-width='full-width' background-url='https://s3.amazonaws.com/swu-filepicker/4E687TRe69Ld95IDWyEg_bg_top_02.jpg'>
    <dys-column padding='0px 20px'>
      <dys-table cellpadding='0' cellspacing='0' padding='0px'>
        <tr>
          <td align='left'>
            <a href='{{ oxygen-logo-buttonurl-group3 }}'><img padding='5px' align='left' width='120' height='33' src='{{ oxygen-logo-imgurl-group3 }}' alt='{{ oxygen-logo-alt-group3 }}' /></a>
          </td>
          <td align='right' style='vertical-align:bottom;' width='34px'>
            <a href='{{ oxygen-twitter-buttonurl-group3 }}'><img width='22' height='22' src='{{ oxygen-twitter-imgurl-group3 }}' alt='{{ oxygen-twitter-alt-group3 }}' /></a>
          </td>
          <td align='right' width='34px' style='vertical-align:bottom;'>
            <a href='{{ oxygen-facebook-buttonurl-group3 }}'><img width='22' height='22' src='{{ oxygen-facebook-imgurl-group3 }}' alt='{{ oxygen-facebook-alt-group3 }}' /></a>
          </td>
          <td align='right' width='34px' style='vertical-align:bottom;'>
            <a href='{{ oxygen-insta-buttonurl-group3 }}'><img width='22' height='22' src='{{ oxygen-insta-imgurl-group3 }}' alt='{{ oxygen-insta-alt-group3 }}' /></a>
          </td>
        </tr>
      </dys-table>
    </dys-column>    
  </dys-row>
</dys-block>
`;

export default Header;
