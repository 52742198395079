const Header = `
<dys-block>
  <dys-row
    padding="0px"
    full-width="full-width"
    background-color="#f0f0f0"
    background-url="{{ lifestyle-header-image-imgurl-group3 }}"
    background-size="cover"
    background-repeat="no-repeat"
  >
    <dys-column padding="40px 20px">
      <dys-text
        font-weight="900"
        align="left"
        color="#000000"
        font-size="22px"
        font-family="Martel"
        line-height="72px"
        padding-bottom="0px"
        editable="Subtitle"
      >
        {{{ lifestyle-header-subtitle-text-group1 }}}
      </dys-text>
      <dys-text
        font-weight="900"
        align="left"
        color="#000000"
        font-size="56px"
        font-family="Martel"
        line-height="72px"
        padding-bottom="50px"
        padding-top="00px"
        editable="Title"
      >
        {{{ lifestyle-header-title-text-group1 }}}
      </dys-text>

      <dys-button
        href="{{ lifestyle-header-buttonurl-group2 }}"
        align="left"
        font-size="22px"
        border-radius="0px"
        background-color="BLACK"
        editable="header-button"
      >
        {{ lifestyle-header-buttontext-group2 }}</dys-button
      >
    </dys-column>
  </dys-row>
</dys-block>
`;

export default Header;
