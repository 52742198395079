import React from 'react';
import styled from 'styled-components';
import Hurray from './../../assets/images/VisualEditor/hurray.svg';

const PopupContainer = styled.div`
  position: fixed;
  display: flex;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  align-items: center;
  justify-content: center;
`;

const StyledPopupCard = styled.div`
  height: 400px;
  width: 640px;
  margin: auto;
  padding: 0px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(120, 120, 120, 0.5);
  z-index: 21;
`;
const PopupHeader = styled.h1`
  height: 48px;
  position: auto;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.4px;

  color: #121212;
`;

const StepHeader = styled.span`
  font-weight: 500;
`;

const PopupMessage = styled.p`
  margin: 10px 0px 33px;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.4px;

  color: #464646;
`;
const ButtonStyle = styled.button`
  display: block;
  border: 0px;
  border-radius: 4px;
  height: 40px;
  width: 130px;
  background: #1F76D3;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.4px;

  color: #ffffff;
  :hover {
    background-color: #1B63B1;
    cursor: pointer;
  }
`;

function PopupCard({ closePopup }) {
  return (
    <StyledPopupCard>
      <img
        alt="Hurray"
        src={Hurray}
        style={{ display: 'inline-block', width: '254px', verticalAlign: 'middle' }}
      />
      <div
        style={{
          display: 'inline-block',
          width: '300px',
          margin: '40px',
          verticalAlign: 'middle',
        }}
      >
        <PopupHeader>Hurray — you now have reusable Dyspatch blocks!</PopupHeader>
        <PopupMessage>
          Use our drag-and-drop visual email builder to quickly create a new email in
          seconds.
          <br />
          <br />
          <StepHeader>Step 1:</StepHeader> Drop one of your custom blocks into the
          editor area.
          <br />
          <br />
          <StepHeader>Step 2:</StepHeader> Click on the rendered block to edit the
          content inside!
        </PopupMessage>
        <ButtonStyle onClick={closePopup}>Start Building</ButtonStyle>
      </div>
    </StyledPopupCard>
  );
}

export { PopupContainer, PopupCard };
