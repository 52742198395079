import React from 'react';
import styled from 'styled-components';
import FirstGIF from './../../assets/images/Blockify/paste-html-email-blockify.gif';
import redArrow from './../../assets/images/Blockify/red-arrow-left.png';
import blueArrow from './../../assets/images/Blockify/blue-arrow-right.png';
const ChopLinesVideo = require('./../../assets/images/Blockify/set-chop-lines-blockify_small.mp4');


const InstructionsContainer = styled.div`
  position: fixed;
  display: flex;
  z-index: 5;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  align-items: center;
  justify-content: center;
`

const StyledInstructionsCard = styled.div`
  height: auto;
  width: 688px;
  margin: auto;
  padding: 50px;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(120, 120, 120, 0.5);
  z-index: 6;
`
const HeaderStyle = styled.h1`
  height: 80px;
  position: auto;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.4px;

  color: #464646;
`

const MessageStyle = styled.p`
  margin: 8px 0px 33px;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 1.5px;
  text-transform: uppercase;

  color: #464646;
`
const StepContainer = styled.div`
  display: flex;
  flexDirection: row;
  align-items: center;
  margin-bottom: 30px;
`


const StepHeaderStyle = styled.p`
  margin: auto;
  display:inline-block;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: #464646;
`
const StepMessageStyle = styled.p`
  display:inline-block;
  margin: auto;
  max-width: 300px;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.4px;
  color: #464646;
`

const ButtonStyle = styled.button`
  display: block;
  margin: auto;
  border: 0px;
  border-radius: 4px;
  height: 40px;
  width: 130px;
  
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.4px;
  
  background: #1F76D3;
  color: #FFFFFF;
  :hover {
    background-color: #1B63B1;
    cursor: pointer;
  }
`
interface Props {
  closeInstructions: () => void
}
function InstructionsCard(props: Props) {
  

  return(
    <StyledInstructionsCard>
      <HeaderStyle>Quickly and easily convert your HTML emails into reusable blocks</HeaderStyle>
      <MessageStyle>Two easy steps, two quick minutes</MessageStyle>
        <div>
          <StepContainer>
            <div style={{display:"inline-block", position:"relative", width:"50%", margin:"0px"}}>
              <img src={FirstGIF} style={{width: "250px"}}/>
              <img src={redArrow} style={{position:"absolute", transform: "translate(-415px, 140px)"}}/>
            </div>
            <div style={{display:"inline-block", width:"50%"}}>
              <StepHeaderStyle>Step 1</StepHeaderStyle>
              <StepMessageStyle>Copy and paste HTML email into the code editor panel. This will render a visual preview of the email.</StepMessageStyle>
            </div>
          </StepContainer>
          <StepContainer>
            <div style={{display:"inline-block", width:"50%"}}>
              <StepHeaderStyle>Step 2</StepHeaderStyle>
              <StepMessageStyle>In the visual preview, click to add divider lines. This will define each section as a reusable block.</StepMessageStyle>
            </div>
            <div style={{display:"inline-block", position:"relative", width:"50%", margin:"0px"}}>
              <video autoPlay muted loop>
                <source src={ChopLinesVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <img src={blueArrow} style={{position:"absolute", transform: "translate(250px, -40px)"}}/>
            </div>
          </StepContainer>
          <ButtonStyle onClick={() => {props.closeInstructions()}}>Get Started</ButtonStyle>
        </div>
    </StyledInstructionsCard>
  );
}

export {InstructionsContainer, InstructionsCard};