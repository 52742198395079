const Divider = `
<dys-block>
  <dys-row>
    <dys-column>
      <dys-img
        width="300px"
        align="center"
        src="https://assets.opensourceemails.com/imgs/lifestyle/rQdM7LSiRUqNh29a8Mo8_divider%20product%20lifestyle.png"
      />
    </dys-column>
  </dys-row>
</dys-block>
`;

export default Divider;
