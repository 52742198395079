import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Provider, Subscribe } from 'unstated';

import { Draggable } from 'react-beautiful-dnd';
import ThumbnailRenderer from './BlockLibrary/Thumbnail';

import AppDataStore from './../store/StateStore';

import { Handlebars } from './../utils/HandlebarsHelpers';
import { render } from '../utils/dml/dml';

const VisualBlockItem = styled.div`
  display: flex;
  user-select: none;
  justify-content: center;
  align-items: center;
  align-content: center;
  line-height: 1.5;
  border-radius: 3px;
  background: #fff;
  border: ${(props) => (props.isDragging ? '1px dashed #000' : '')};
  cursor: pointer;
  &:hover {
    position: relative;
    border: 1px dashed #000;
    margin: -1px;
  }
`;

const DraggableBlockItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-contents: center;
  margin: 0 4px;
`;

const LibraryBlockItem = styled.div`
  user-select: none;
  padding: 0rem;
  align-items: center;
  align-content: center;
  line-height: 1.5;
  border-radius: 3px;
  background: #fff;
  border: 1px solid #ddd;
  max-width: ${(props) => (props.draggingOver ? '605px' : '242px')};
  height: ${(props) => (props.draggingOver ? '295px' : '122px')};
  transition: ${(props) => (props.draggingOver ? '0.5s' : '0s')};
  transition: box-shadow 0.5s;
  &:hover {
    box-shadow: rgba(120, 120, 120, 0.5) 0px 6px 32px -2px;
  }
`;

const LibraryBlockItemClone = styled.div`
  user-select: none;
  padding: 0.5rem;
  align-items: center;
  align-content: center;
  line-height: 1.5;
  border-radius: 3px;
  background: #fff;
  border: 1px solid #ddd;
  transition: min-width 0.2s ease;
  width: 240px;
  height: 120px;
  &:hover {
    box-shadow: rgba(120, 120, 120, 0.5) 0px 6px 32px -2px;
  }
`;

const Clone = styled(DraggableBlockItem)`
  + div {
    display: none !important;
  }
`;

const BlockNameText = styled.div`
  font-family: Roboto, sans-serif;
  letter-spacing: 0.4px;
  padding: 10px 0px 20px 0px;
  position: relative;
  line-height: 1.25;
  max-height: 36px;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${(props) => (props.draggingOver ? '#fff' : 'rgb(120, 120, 120);')};
  transition: ${(props) => (props.draggingOver ? '0.5s' : '0s')};
  font-weight: ${(props) => (props.draggingOver ? '300' : '400')};
  font-size: ${(props) => (props.draggingOver ? '20px' : '14px')};
`;

const CloneBlockNameText = styled(BlockNameText)`
  + div {
    display: none !important;
  }
`;

const themeDML = '<dys-attributes></dys-attributes>';

const VisualBlockContents = React.memo(
  (props) => {
    const blockRef = useRef();
    const shadowHolder = useRef();

    const captureLinkClicks = (event) => {
      const target = event.target;
      const isMac = navigator.appVersion.indexOf('Mac') !== -1;
      if (target && target.nodeName === 'A') {
        if (isMac && !event.metaKey) {
          // Mac: Don't follow links unless the apple key is pressed
          event.preventDefault();
        }
        if (!isMac && !event.ctrlKey) {
          // Non-mac: Don't follow links unless ctrl key is pressed
          event.preventDefault();
        }
      }
    };

    useEffect(() => {
      const domNodes = blockRef.current;

      if (domNodes) {
        domNodes.addEventListener('click', captureLinkClicks, true);
      }

      return () => {
        if (domNodes) {
          domNodes.removeEventListener('click', captureLinkClicks, true);
        }
      };
    }, []);

    useEffect(() => {
      if (shadowHolder) {
        const shadowContent = document.createRange().createContextualFragment(`
          <div style='
            width: 100%;
          '>
            ${handlebarsText}
          </div>`);
        let shadow;
        if (shadowHolder.current.shadowRoot === null) {
          shadow = shadowHolder.current.attachShadow({ mode: 'open' });
        } else {
          shadow = shadowHolder.current.shadowRoot;
        }
        shadow.innerHTML = '';
        shadow.appendChild(shadowContent);
      }
    });

    const html = render(props.block.dml, themeDML);
    const dataset = props.appData.getOrCreateDataSet(props.block);
    const handlebarsText = Handlebars.compile(html)(dataset);

    const visualBlockContentsStyling = {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };

    const divWidth = {
      width: '100%',
    };

    return (
      <div
        ref={blockRef}
        id={props.block.id}
        onClick={(e) => props.appData.handleBlockClick(e, props.block)}
        style={visualBlockContentsStyling}
      >
        <div ref={shadowHolder} style={divWidth} />
      </div>
    );
  },
  (prevProps, nextProps) => {
    if (nextProps.editing !== false) {
      return false;
    }
    return true;
  }
);

const getStyle = (provided, snapshot, width) => {
  const style = provided.draggableProps.style;
  if (!snapshot.isDropAnimating) {
    return style;
  }
  const { moveTo } = snapshot.dropAnimation;

  // (screensize[width] - block library width[564] ) / half screen [2]
  // minus "draggable block's" left side[style.left] + width[style.width] - padding&border[18]
  const customX = (width - 564) / 2 - style.left + style.width - 18 + 180;

  // Move droppable position
  let translate;
  if (snapshot.draggingOver === 'visual-editor') {
    // Drag to center
    translate = `translate(${customX}px, ${moveTo.y}px)`;
  } else {
    // Use default
    translate = `translate(${moveTo.x}px, ${moveTo.y}px)`;
  }

  // Patching the existing style
  return {
    ...style,
    transform: `${translate}`,
  };
};

const Block = (props) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);

    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  });

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
  };
  return (
    <Draggable draggableId={props.block.id} index={props.index}>
      {(provided, snapshot) =>
        props.area === 'visual-editor' ? (
          <React.Fragment>
            <Provider>
              <Subscribe to={[AppDataStore]}>
                {(appData) => (
                  <VisualBlockItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    isDragging={snapshot.isDragging}
                    style={provided.draggableProps.style}
                  >
                    <VisualBlockContents
                      appData={appData}
                      block={props.block}
                      editing={appData.state.editing}
                    />
                  </VisualBlockItem>
                )}
              </Subscribe>
            </Provider>
          </React.Fragment>
        ) : (
          // else display block library

          <React.Fragment>
            <DraggableBlockItem
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              isDragging={snapshot.isDragging && !snapshot.isDropAnimating}
              style={getStyle(provided, snapshot, width)}
              draggingOver={snapshot.draggingOver === 'visual-editor'}
            >
              <LibraryBlockItem
                draggingOver={snapshot.draggingOver === 'visual-editor'}
              >
                <ThumbnailRenderer
                  width={600}
                  draggingOver={snapshot.draggingOver === 'visual-editor'}
                  containerWidth={
                    snapshot.draggingOver === 'visual-editor' ? 600 : 600
                  }
                  thumbSize={snapshot.draggingOver === 'visual-editor' ? 600 : 240}
                  block={props.block}
                  theme={themeDML}
                />
              </LibraryBlockItem>

              <BlockNameText
                draggingOver={snapshot.draggingOver === 'visual-editor'}
                isDragging={false}
              >
                {props.block.name}
              </BlockNameText>
            </DraggableBlockItem>

            {snapshot.isDragging && (
              <Clone>
                <LibraryBlockItemClone
                  draggingOver={snapshot.draggingOver === 'visual-editor'}
                >
                  <ThumbnailRenderer
                    containerWidth={600}
                    thumbSize={240}
                    block={props.block}
                    theme={themeDML}
                  />
                </LibraryBlockItemClone>
                <CloneBlockNameText isDragging={false}>
                  {props.block.name}
                </CloneBlockNameText>
              </Clone>
            )}
          </React.Fragment>
        )
      }
    </Draggable>
  );
};

export default Block;
